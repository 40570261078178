import axios from "axios"
import React, { useContext, useEffect, useState } from "react"
import { AuthContext } from "../../contexts/auth-context/auth"
import Header from "../../components/header"
import Loading from "../../components/loading"
import { Slide, ToastContainer } from "react-toastify"
import { Link, useNavigate } from "react-router-dom"
import { formatDateTime } from "../../utils/formatDateTime"
import Logonyc from '../../assets/logoNYC/logo_nycbank_black.svg'
import './style.css'
import Button from "../../components/buttons/primaryButton"

interface ListPersonProps {
	role: string
	person: PersonProps
	personId: number
}

interface PersonProps {
	personId: number
	name: string,
	zipCode: string,
	cpf: string,
	email: string,
	phone: string,
	birthDate: string,
	createdAt: Date,
	destroyedAt: String,
	registerStep: String,
}

const Clients = () => {

	const [person, setPerson] = useState<PersonProps[]>([])
	const { auth, setAuth } = useContext(AuthContext)
	const [loading, setLoading] = useState(true)
	const navigate = useNavigate();
	const cpf = typeof window !== 'undefined' ? sessionStorage.getItem('cpfLogin') : null;

	useEffect(() => {
		const format = cpf?.replaceAll('-', '').replaceAll('.', '')
		axios.get(`https://nycprospectservice.azurewebsites.net/api/v2/ProspectService/PostUsuarios/getbysalesman/${format}`, {
			headers: {
				Authorization: `Bearer ${auth}`
			}
		}).then((res: any) => {
			setPerson(res.data)
			setLoading(false)
		}
		)
	}, [person])


	return (
		<>
			<Header />
			{
				!loading ?
					<>
						<div className="container-title container-correspondents">
							<div>
								<img width={160} src={Logonyc.toString()} />
								<h1>Clients</h1>
							</div>
							<nav>
								<Button className="we" onClick={() => navigate("/clients/register")} textButton='Cadastrar Clientes' />
							</nav>
						</div>
						<div className="users-container-head clients-container-head">
							<div className="header">
								<div>
									<h2>
										Nome
									</h2>
									<h2>
										CPF
									</h2>
									<h2>
										Entrou em
									</h2>
									<h2>
										Status
									</h2>

								</div>
							</div>
						</div>
						<div className="container-card users-container clients-container">

							{
								person.length > 0 ?
									person.map((item, index) => (
										<div className={`card ${index == 0 ? 'first' : index == person.length - 1 ? 'last' : ''}`} key={index}>
											<div>
												<h2 className='name'>{item?.name}</h2>
												<p className="gray">{item?.cpf}</p>
												<p className="gray">{formatDateTime(item?.createdAt)}</p>
												<p className="gray">{item?.registerStep}</p>
											</div>
										</div>
									))
									:
									<p className="aviso">
										Não há contas cadastradas
									</p>
							}
						</div>
						<ToastContainer transition={Slide} />
					</>
					:
					<div className="loading-page-loan">
						<p>Carregando...</p>
						<Loading />
					</div>
			}

		</>
	)
}
export default Clients