import Header from "../../../components/header";
import axios from "axios";
import './style.css'
import Input from "../../../components/inputs/defaultInput";
import Logonyc from '../../../assets/logoNYC/logo_nycbank_black.svg'
import { useContext, useEffect, useState } from "react";
import MaskInput from "../../../components/inputs/maskedInput";
import Select from "../../../components/inputs/selectInput";
import Button from "../../../components/buttons/primaryButton";
import CryptoNyc from '../../../utils/decrypto-nyc'
import { banks } from "../../../mock/banksMock";

import { ToastContainer, toast, Bounce, Slide, Flip, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import React from "react";
import { AuthContext } from "../../../contexts/auth-context/auth";
import { formatDate } from "../../../utils/formatDate";

export default function RegisterCpf() {
	const navigate = useNavigate()
	const { auth, setAuth } = useContext(AuthContext)

	useEffect(() => {

		if (auth === null || auth === 'undefined' || auth === undefined || auth === '') {
			navigate('/signin')
		}
	}, [])

	const [userInfo, setUserInfo] = useState(true)
	const [bankAccountInfo, setBankAccountInfo] = useState(false)
	const [formData, setFormData] = useState({
		name: "",
		cpf: "",
		maritalStatus: "Solteiro",
		gender: "M",
		cep: "",
		residence: "CASA",
		numberAd: "",
		phone: "",
		email: "",
		bankCode: "",
		agency: "",
		account: "",
		ispb: "",
	});

	const handleChange = (fieldName: any, value: any) => {
		setFormData((prevData) => ({
			...prevData,
			[fieldName]: value,
		}));
	};

	const getISPB = (code: string) => {
		const bank = banks.find((item) => item.code == code)
		if (bank) {
			handleChange('ispb', bank?.ispb)
		} else {
			toast.error('Código Inexistente', {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				rtl: false,
				pauseOnFocusLoss: true,
				draggable: true,
				pauseOnHover: true,
				theme: "light",

			});
		}
	}



	const setUserInfoFunc = () => {
		const formatCpf = formData.cpf.replaceAll('.', '').replace('-', '')
		const formatCep = formData.cep.replaceAll('.', '').replace('-', '')
		sessionStorage.setItem('cpf', formatCpf)
		if (!formData.cpf || !formData.email || !formData.phone || !formData.maritalStatus || !formData.cep || !formData.numberAd || formatCpf.length !== 11 || formatCep.length !== 8) {

			toast.error('Preencha todos os dados necessários.', {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				rtl: false,
				pauseOnFocusLoss: true,
				draggable: true,
				pauseOnHover: true,
				theme: "light",

			});
		} else {
			setUserInfo(false)
			setBankAccountInfo(true)
		}
	}

	const back = () => {
		setUserInfo(true)
		setBankAccountInfo(false)
	}

	const registerNew = () => {
		const formatCpf = formData.cpf.replaceAll('.', '').replace('-', '')
		const formatPhone = formData.phone.replace('(', '').replace(')', '').replace('-', '').replace(" ", '')
		const formatCEP = formData.cep.replace('-', '')

		axios.post('https://nycloancartosservice.azurewebsites.net/api/v2/CreateCustomer/customer', {
			"residence": formData.residence,
			"phone": formatPhone,
			"cpf": formatCpf,
			"cep": formatCEP,
			"email": formData.email,
			"bankCode": formData.bankCode,
			"bankISPB": formData.ispb,
			"agency": formData.agency,
			"accountType": "CC",
			"accountNumber": formData.account,
			"addresNumber": formData.numberAd,
			"maritalStatus": formData.maritalStatus,
			"gender": formData.gender
		}, {
			headers: {
				Authorization: `Bearer ${auth}`
			}
		}).then((res) => {
			// console.log(res)
			toast.success('Cadastrado com Sucesso, você será redirecionado para gerar um proposta', {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				rtl: false,
				pauseOnFocusLoss: true,
				draggable: true,
				pauseOnHover: true,
				theme: "light",
			});

			setTimeout(() => {

				navigate('/loans/register-loan')
			}, 5000);
		}

		).catch((e) => {
			// console.log(e.response.data.message)
			if (e.response.data.message == "Cliente já cadastrado na Cartos!"
			) {
				toast.error('Este CPF já está cadastrado', {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					rtl: false,
					pauseOnFocusLoss: true,
					draggable: true,
					pauseOnHover: true,
					theme: "light",
				});
			} else {
				toast.error('Este CPF não possui uma conta NYC', {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					rtl: false,
					pauseOnFocusLoss: true,
					draggable: true,
					pauseOnHover: true,
					theme: "light",
				});
			}
		})
	}


	return (
		<>
			<Header />
			<div className="container">
				<img src={Logonyc.toString()} className="logo" alt="logo da NYC Bank" />

				<form>
					{
						userInfo &&
						<>
							<div className="form-grid">
								<h2>Número de Celular</h2>
								<MaskInput maskChar={null} mask="(99) 99999-9999" type="text" placeholder="(xx) xxxxx-xxxx" value={formData.phone} onChange={(e) => handleChange('phone', e.target.value)} />
								<h2>E-mail</h2>
								<Input type="email" placeholder="email@email.com" value={formData.email} onChange={(e) => handleChange('email', e.target.value)} />
								<h2>CPF</h2>
								<MaskInput mask="999.999.999-99" maskChar={null} placeholder="xxx.xxx.xxx-xx" type="text" value={formData.cpf} onChange={(e) => handleChange('cpf', e.target.value)} />
								<h2>Estado Civil</h2>
								<Select onChange={(e) => handleChange('maritalStatus', e.target.value)} >
									<option value='Solteiro' selected >Solteiro(a)</option>
									<option value='Casado'>Casado(a)</option>
									<option value='Divorciado'>Divorciado(a)</option>
									<option value='Víuvo'>Viúvo(a)</option>
								</Select>

								<h2>Tipo de Residência</h2>
								<Select onChange={(e) => handleChange('residence', e.target.value)}>
									<option value='CASA' selected>Casa</option>
									<option value='APT'>Apartamento</option>
								</Select>

								<h2>Genero</h2>
								<Select onChange={(e) => handleChange('gender', e.target.value)}>
									<option value="M" selected>Masculino</option>
									<option value="F">Feminino</option>
								</Select>

								<h2>CEP</h2>
								<MaskInput maskChar={null} mask="99999-999" placeholder="xxxxx-xxx" type="text" value={formData.cep} onChange={(e) => handleChange('cep', e.target.value)} />

								<h2>Número</h2>
								<Input type="number" placeholder="Número" value={formData.numberAd} onChange={(e) => handleChange('numberAd', e.target.value)} />

							</div>
							<div className="button-box">
								<Button type='button' onClick={setUserInfoFunc} textButton={'Próxima'} />
							</div>
						</>
					}

					{
						bankAccountInfo &&
						<>
							<div className="form-grid">
								<h2>Código Bancário</h2>
								<MaskInput maskChar={null} mask="999" placeholder="Código Bancário" onBlur={(e) => getISPB(e.target.value)} value={formData.bankCode} onChange={(e) => handleChange('bankCode', e.target.value)} />
								<h2>Agência</h2>
								<Input type="text" placeholder="Agência" value={formData.agency} onChange={(e) => handleChange('agency', e.target.value)} />
								<h2>Número da Conta</h2>
								<Input type="text" placeholder="Número da Conta" value={formData.account} onChange={(e) => handleChange('account', e.target.value)} />
							</div>
							<div className="button-box">

								<Button type='button' onClick={back} textButton={'Anterior'} />
								<Button type='button' onClick={registerNew} textButton={'Cadastrar'} />
							</div>
						</>
					}
				</form>
				<ToastContainer transition={Slide} />
			</div >
		</>
	)
}