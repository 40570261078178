import axios from "axios"
import React, { useContext, useEffect, useState } from "react"
import { AuthContext } from "../../contexts/auth-context/auth"
import Header from "../../components/header"
import Loading from "../../components/loading"
import { Slide, ToastContainer } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { formatDateTime } from "../../utils/formatDateTime"
import Logonyc from '../../assets/logoNYC/logo_nycbank_black.svg'
import './style.css'
import Select from "../../components/inputs/selectInput"
import Input from "../../components/inputs/defaultInput"
import Button from "../../components/buttons/primaryButton"
import ButtonIcon from "../../components/buttons/iconButton"
import RightArrow from '../../assets/icons/right-arrow.svg';
import LeftArrow from '../../assets/icons/left-arrow.svg';


interface ListPersonProps {
	role: string
	person: PersonProps
	personId: number
}

interface PersonProps {
	personId: number
	name: string,
	zipCode: string,
	cpf: string,
	email: string,
	phone: string,
	birthDate: string,
	createdAt: Date,
	destroyedAt: String,
}

const Users = () => {

	const [person, setPerson] = useState<ListPersonProps[]>([])
	const [dataFiltered, setDataFiltered] = useState<ListPersonProps[]>([])
	const [currentFilters, setCurrentFilters] = useState<{ [key: string]: string }>({});
	const [slicePagination, setSlicePagination] = useState([0, 10]);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const { auth, setAuth } = useContext(AuthContext)
	const [loading, setLoading] = useState(true)
	const navigate = useNavigate();

	useEffect(() => {
		axios.get('https://nycclientinfoservice.azurewebsites.net/api/v2/getallUsers', {
			headers: {
				Authorization: `Bearer ${auth}`
			}
		}).then((res) => {
			console.log(res.data)
			setPerson(res.data)
			setLoading(false)
		}
		)
	}, [])


	useEffect(() => {
		setDataFiltered(person)
	}, [person])

	const filter = (value: string, filterBy: string) => {
		setCurrentFilters((prevFilters) => ({ ...prevFilters, [filterBy]: value }));
	};

	useEffect(() => {
		// Clona os dados originais para evitar mutação direta no estado
		let filteredData = person;

		// Itera sobre cada filtro no objeto currentFilters
		for (const filterBy in currentFilters) {
			// Extrai o valor do filtro
			let filterValue = currentFilters[filterBy];

			// Verifica se um valor de filtro está presente
			if (filterValue) {
				// Verifica se o filtro está relacionado a campos de data (paymentDate ou payedAt)
				if (filterBy === "createdAt") {
					const dataFiltro = new Date(filterValue);
					const ano = dataFiltro.getFullYear().toString().length;
					// Aplica filtros não relacionados a datas

					// Verifica se o valor do filtro é "default"
					if (ano === 4) {
						// Aplica os filtros de data
						filteredData = filteredData.filter((data) => {
							const itemValue = data.person[filterBy as keyof PersonProps]

							// Ignora valores nulos
							if (itemValue === null) {
								return false;
							}

							// Formata a data do filtro para comparação
							if (filterBy === "createdAt") {
								const dateCompare = new Date(filterValue);
								const formatedDateCompare = dateCompare.toISOString().replace("T00:00:00.000Z", " ").trim();

								// Verifica se a data do item inclui a data do filtro formatada
								return itemValue.toString().includes(formatedDateCompare);
							}
						});
					}
				} else if (filterBy == "role") {
					if (filterValue === "default") {
						// Redefine os dados filtrados para os dados originais da API
						setDataFiltered(person);
					} else {

						filteredData = filteredData.filter((data) =>
							data[filterBy as keyof ListPersonProps]
								.toString()
								.toLowerCase()
								.includes(filterValue.toLowerCase())
						)
					}
				}
				else {
					if (filterValue === "default") {
						// Redefine os dados filtrados para os dados originais da API
						setDataFiltered(person);
					} else {
						filteredData = filteredData.filter((data) =>
							data.person[filterBy as keyof PersonProps]
								.toString()
								.toLowerCase()
								.includes(filterValue.toLowerCase())
						)
					}
				}
			}
		}

		// Atualiza o estado com os dados filtrados
		setDataFiltered(filteredData);
	}, [person, currentFilters]);

	const switchRole = (role: string) => {
		switch (role) {
			case "needuploaddocument":
				return "Envio de documentos";
			case "needcreatekey":
				return "Criação de chave pix";
			case "awaitaccount":
				return "Aguardando aprovação";
			case "usercreated":
				return "Usuário criado";
			case "accountconfirmed":
				return "Conta confirmada";
			case "accountverify":
				return "Verificação de conta";
			case "returnpix":
				return "Retorno pix";
			case "accountcreating":
				return "Criando conta";
			case "passwordconfirmed":
				return "Senha confirmada";
			case "cep":
				return "CEP";
			case "sendemail":
				return "Enviar e-mail";
			case "needconfirmtoken":
				return "Necessita confirmar token";
			case "needcreateemail":
				return "Necessita criar e-mail";
			case "accountverifyerror":
				return "Erro na verificação da conta";
			case "recoverconfirmed":
				return "Recuperação confirmada";
			case "nickname":
				return "Apelido";
			case "sendsms":
				return "Enviar SMS";
			case "password":
				return "Criando senha";
			case "validsms":
				return "Validando SMS";
			case "confirmIdentity":
				return "Confirmar identidade";
			default:
				return "";
		}
	};

	const totalPages = Math.ceil(dataFiltered.length / itemsPerPage);
	const maxButtonsPerPage = 5; // Número máximo de botões de página visíveis de cada vez
	const [currentPage, setCurrentPage] = useState(1);
	const [visibleButtons, setVisibleButtons] = useState([...Array(maxButtonsPerPage).keys()].map(i => i + 1));


	//Set gradual visibility of paginations buttons
	const updatePagination = (page: number) => {

		setCurrentPage(page);
		const start = (page - 1) * itemsPerPage;
		const end = start + itemsPerPage;
		setSlicePagination([start, end]);

		// Atualizar botões visíveis com base na página atual
		const visibleButtonsCount = Math.min(maxButtonsPerPage, totalPages);
		const halfMaxButtons = Math.floor(maxButtonsPerPage / 2);
		let newVisibleButtons = [];

		if (page <= halfMaxButtons + 1) {
			// Página atual é próxima ao início, exiba os primeiros botões
			newVisibleButtons = [...Array(visibleButtonsCount).keys()].map(i => i + 1);
		} else if (page >= totalPages - halfMaxButtons) {
			// Página atual é próxima ao final, exiba os últimos botões
			newVisibleButtons = [...Array(visibleButtonsCount).keys()].map(i => i + totalPages - visibleButtonsCount + 1);
		} else {
			// Página atual está no meio, exiba os botões em torno da página atual
			newVisibleButtons = [...Array(visibleButtonsCount).keys()].map(i => i + page - halfMaxButtons);
		}

		setVisibleButtons(newVisibleButtons);
	};
	const limitedApiData = dataFiltered.slice(slicePagination[0], slicePagination[1]);

	// Pagination buttons generation
	const renderPaginationButtons = () => {
		const buttons = [];
		for (const page of visibleButtons) {
			if (page <= totalPages) {
				buttons.push(
					<Button
						key={page}
						onClick={() => goToPage(page)}
						className={`${currentPage === page ? "currentPage" : ''} small`}
						textButton={page}
					/>
				);
			}
		}
		return buttons;
	};

	// pass pages, next o
	const goToPage = (page: number) => {
		if (page === 1) {
			// Ir para a primeira página
			updatePagination(1);
		} else if (page === totalPages) {
			// Ir para a última página
			updatePagination(totalPages);
		} else {
			// Outras páginas
			setCurrentPage(page);
			const start = (page - 1) * itemsPerPage;
			const end = start + itemsPerPage;
			setSlicePagination([start, end]);

			// Atualizar botões visíveis com base na página atual
			const visibleButtonsCount = Math.min(maxButtonsPerPage, totalPages);
			if (page <= totalPages - visibleButtonsCount + 1) {
				setVisibleButtons([...Array(visibleButtonsCount).keys()].map(i => i + page));
			} else {
				setVisibleButtons([...Array(totalPages - page + 1).keys()].map(i => i + page));
			}
		}
	};



	return (
		<>
			<Header />
			{
				!loading ?
					<div style={{ width: '100%' }}>
						<div className="container-title">
							<img width={160} src={Logonyc.toString()} />
							<h1>Correntistas NycBank</h1>
						</div>
						<div className="container-filters">

							<div className="filters">

								<Input placeholder='Nome' type='text' onChange={(e) => filter(e.target.value, 'name')} />
								<Input placeholder='CPF' type='tel' onChange={(e) => filter(e.target.value, 'cpf')} />
								<Input placeholder='CPF' type='date' onChange={(e) => filter(e.target.value, 'createdAt')} />
								<Select onChange={(e) => {
									filter(e.target.value, 'role');
								}
								}>
									<option value="default">Todos</option>
									<option value="accountconfirmed">Usuário criado</option>
									<option value="awaitaccount">Aguardando aprovação</option>
									<option value="needcreatekey">Criação de chave pix</option>
									<option value="needcreateemail">Necessita criar e-mail</option>
									<option value="needconfirmtoken">Necessita confirmar token</option>
									<option value="usercreated">Usuário criado</option>
									<option value="accountverify">Verificação de conta</option>
									<option value="returnpix">Retorno pix</option>
									<option value="accountcreating">Account Creating</option>
									<option value="passwordconfirmed">Password Confirmed</option>
									<option value="cep">CEP</option>
									<option value="sendemail">Enviar e-mail</option>
									<option value="needuploaddocument">Envio de documentos</option>
									<option value="accountverifyerror">Erro na verificação da conta</option>
									<option value="recoverconfirmed">Recuperação confirmada</option>
									<option value="nickname">Apelido</option>
									<option value="sendsms">Enviar SMS</option>
									<option value="password">Criando senha</option>
									<option value="validsms">Validando SMS</option>
									<option value="confirmIdentity">Confirmar identidade</option>
								</Select>

							</div>
						</div>
						<div className="tableReportContainer" style={{ margin: '0px 8%' }}>
							<table className="table">
								<thead className="tableHeader">
									<tr>
										<th>Nome</th>
										<th>CPF</th>
										<th>Entrou em</th>
										<th>Situação</th>
									</tr>
								</thead>
								<tbody className="tableBody">
									{
										limitedApiData.length > 0 ?
											limitedApiData.map((item, index) => (
												<tr id='linhas' className={index % 2 === 0 ? "lightGreen" : "darkGreen"} key={index}>
													<td onClick={() => { item?.personId && sessionStorage.setItem('idCerberus', item?.personId.toString()); navigate('/report-details') }}>
														{item?.person.name}
													</td>
													<td className="gray">{item?.person.cpf}</td>
													<td className="date">{formatDateTime(item?.person.createdAt)}</td>
													<td className="date">{
														switchRole(item?.role)
													}</td>
												</tr>
											))
											:
											<div className={`card first last`}>
												<div>
													<h2 style={{ width: 600 }}>Nenhum resultado encontrado</h2>
													<p className="gray"></p>
													<p className="date"></p>
													<p className="date"></p>
												</div>
											</div>
									}
								</tbody>
							</table>
						</div>
						<div className="containerButtonsPagination">
							{
								slicePagination[0] == 0 ?
									<ButtonIcon disabled icon={LeftArrow.toString()} alt="Ícone página anterior" onClick={() => goToPage(currentPage - 1)} />
									:
									<ButtonIcon icon={LeftArrow.toString()} alt="Ícone página anterior" onClick={() => goToPage(currentPage - 1)} />
							}

							<Button onClick={() => goToPage(1)} className={`${currentPage === 1 ? "none" : "flex"} small`} textButton={1} />

							{renderPaginationButtons()}

							<Button onClick={() => goToPage(totalPages)} className={`${currentPage === totalPages ? "none" : "flex"} small`}
								textButton={totalPages}
							/>

							<ButtonIcon onClick={() => goToPage(currentPage + 1)} icon={RightArrow.toString()} alt='Ícone próxima página' />
						</div>
						<ToastContainer transition={Slide} />
					</div>
					:
					<div className="loading-page-loan">
						<p>Carregando...</p>
						<Loading />
					</div>
			}

		</>
	)
}
export default Users