import Button from "../../../components/buttons/primaryButton";
import axios from "axios";
import { useEffect, useState } from "react";
import './style.css'
import Header from "../../../components/header";
import jsPDF from "jspdf";
import saveAs from "file-saver";
import logonyc from "../../../assets/logoNYC/logo_nycbank_black.svg";
import React from "react";

interface DataProps {
	id: string;
	disbursement_date: string;
	issue_date: string;
	status: string;

}

export default function AgreementsList() {

	const [data, setData] = useState<DataProps[]>([])

	const id = typeof window !== 'undefined' ? sessionStorage.getItem('id') : null;
	const token = typeof window !== 'undefined' ? sessionStorage.getItem('newToken') : null;
	const name = typeof window !== 'undefined' ? sessionStorage.getItem('fullName') : null;

	useEffect(() => {
		const getData = () => {

			const url = `https://platform.flowfinance.com.br/banking/originator/persons/${id}/applications`

			axios.get(url, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
				.then((response) => {
					// console.log(response.data.content);
					// console.log(response)
					setData(response.data.content)

				})
				.catch((error) => {
					console.log(error);
				});
		}
		getData();
	}, [])


	const downloadPdf = (id: string) => {
		axios.get(`https://platform.flowfinance.com.br/banking/originator/applications/${id}/signed-agreement`,
			{
				headers: {
					"Content-Type": 'application/pdf',
					Authorization: token
				},
				responseType: 'arraybuffer',
			}).then((response) => {

				const blob = new Blob([response.data], { type: 'application/pdf' });
				saveAs(blob, 'downloaded.pdf');
			})
			.catch((error) => {
				console.log(error);
			});
	}

	return (
		<>
			<Header />
			<div className="container">
				<div className="title">
					<img src={logonyc.toString()} alt="logo da NYC Bank" />
					<h1 className="clientName">{name}</h1>
				</div>
				<div className='container-grid'>
					{
						data.map((item, index) => (
							<div className="card" key={index}>
								<p><b>Data da despesa:</b> {item.disbursement_date.replaceAll('-', '/')}</p>
								<p><b>Data de emissão:</b> {item.issue_date.replaceAll('-', '/')}</p>
								<p><b>Situação:</b> {item.status == 'CANCELED' ? 'Cancelado' : 'Emitido'}</p>

								{item.status == 'CANCELED' ? '' : <Button onClick={() => downloadPdf(item.id)} textButton={'Baixar'} />}
							</div>
						))
					}
				</div>
			</div>
		</>
	)
}
