import React, { useContext, useEffect, useState } from "react"
import { AuthContext } from "../../contexts/auth-context/auth"
import axios from "axios"
import Header from "../../components/header"
import Logonyc from '../../assets/logoNYC/logo_nycbank_black.svg'
import './style.css'
import { formatDate } from "../../utils/formatDate"

interface NotaryResponseProps {
	occurrenceDate: string
	amount: number
	officeNumber: string
	city: string
	federalUnity: string
}

interface PefinResponseProps {
	occurrenceDate: string
	amount: number
	creditorName: string
}
interface RefinResponseProps {
	occurrenceDate: string
	amount: number
	creditorName: string
}

interface Address {
	addressLine: string;
	district: string;
	zipCode: string;
	country: string;
	city: string;
	state: string;
}

interface Phone {
	regionCode: number;
	areaCode: number;
	phoneNumber: number;
}

interface ConsumerData {
	documentNumber: string;
	consumerName: string;
	motherName: string;
	birthDate: string;
	statusRegistration: string;
	statusDate: string;
	address: Address;
	phone: Phone;
}
interface Score {
	score: number
}

const ReportAdmin = () => {
	const { auth, setAuth } = useContext(AuthContext)
	const [imgCerb, setImgCerb] = useState('')
	const [imgCNH, setImgCNH] = useState('')
	const [imgOne, setImgOne] = useState('')
	const [imgTwo, setImgTwo] = useState('')
	const [notarys, setNotarys] = useState<NotaryResponseProps[]>([])
	const [pefin, setPefin] = useState<PefinResponseProps[]>([])
	const [refin, setRefin] = useState<RefinResponseProps[]>([])
	const [registration, setRegistration] = useState<ConsumerData>()
	const [score, setScore] = useState<Score>()

	const id = typeof window !== 'undefined' ? sessionStorage.getItem('idCerberus') : null;

	useEffect(() => {
		axios.get(`https://nycprospectservice.azurewebsites.net/api/v2/Azure/GetPicFromAzure/selfie/${id}`, {
			headers: {
				Authorization: `Bearer ${auth}`
			}
		}).then((res: any) => {
			setImgCerb(res.data)
		})
		axios.get(`https://nycprospectservice.azurewebsites.net/api/v2/Azure/GetPicFromAzure/document/${id}`, {
			headers: {
				Authorization: `Bearer ${auth}`
			}
		}).then((res: any) => {
			// console.log(res)
			if (res.data.length == 1) {
				setImgCNH(res.data)
			} else {
				setImgOne(res.data[0])
				setImgTwo(res.data[1])
			}
		})
		axios.get(`https://nycserasaservice.azurewebsites.net/api/v1/consult-serasa/getfullreport/${id}`, {
			headers: {
				Authorization: `Bearer ${auth}`
			}
		}).then((res) => {
			console.log(res.data)
			setNotarys(res.data.report?.reports[0].negativeData.notary.notaryResponse)
			setPefin(res.data.report?.reports[0].negativeData.pefin.pefinResponse)
			setRefin(res.data.report?.reports[0].negativeData.refin.refinResponse)
			setRegistration(res.data.report?.reports[0].registration)
			// console.log(res.data.report?.reports[0].registration)
			setScore(res.data.report?.reports[0].score)
		}).catch((e) => {
			console.log(e)
		})


	}, [])


	const formatStringDate = (date: string) => {
		const split = date.split('-')


		return `${split[2]}/${split[1]}/${split[0]}`
	}

	return (
		<>
			<Header />
			<div className="container-title">
				<img width={160} src={Logonyc.toString()} />
				<h1>Report Serasa e Cerberus</h1>
				<div className="container-reports">
					<div className="container-tickets-report">

						<div className="moscaDemais">
							<b>Cerberus FaceScan</b>
							<div className="cardImg">

								{imgCerb ?
									<img src={`data:image/png;base64,${imgCerb}`} />
									:
									<p className="empty">Não há comprovantes enviados para exibição</p>
								}

							</div>
						</div>
						<div className="moscaDemais">
							<b>Documento Cerberus</b>
							<div className={imgCNH !== '' && imgOne == '' ? 'cardImg' : 'cardImgCom'}>

								{imgCNH != '' ?
									<img src={`data:image/png;base64,${imgCNH}`} />
									:
									imgOne !== '' && imgTwo !== '' && imgTwo !== undefined && imgTwo !== undefined ?
										<>
											<img src={`data:image/png;base64,${imgOne}`} />
											<img src={`data:image/png;base64,${imgTwo}`} />
										</>
										:
										<p className="empty">Não há comprovantes enviados para exibição</p>
								}

							</div>
						</div>
					</div>
					<div className="grid-dados">
						<b className="titleSerasa">Dados no Serasa</b>
						{registration ?
							<>
								<small className="infos"><b>Nome:</b><br />{registration?.consumerName && registration?.consumerName}</small>
								<small className="infos"><b>Data de nascimento:</b><br />{registration?.birthDate && formatStringDate(registration?.birthDate)}</small>
								<small className="infos"><b>CPF:</b><br />{registration?.documentNumber && registration?.documentNumber}</small>
								<small className="infos"><b>Nome da Mãe:</b><br />{registration?.motherName ? registration?.motherName : 'Não incluso no cadastro'}</small>
								<small className="infos"><b>Situação:</b><br />{registration?.statusRegistration}</small>
								<small className="infos"><b>Telefone:</b><br />
									{registration && registration.phone ?
										`+${registration.phone.regionCode} ${`(${registration.phone.areaCode})`} ${registration.phone.phoneNumber}`
										:
										'Não incluso no cadastro'
									}
								</small>
								<small className="infos"><b>Endereço:</b><br />
									{registration && registration.phone ?
										`${registration?.address?.addressLine}, ${registration?.address?.district} - ${registration?.address?.state} - ${registration?.address?.country}`
										:
										'Não incluso no cadastro'
									}
								</small>
								<small className="infos"><b>Serasa Score:</b><br />{score?.score}</small>
							</>
							:
							<small className="infos">Ainda não há dados disponíveis</small>
						}
					</div>

				</div>
				<div className="container-serasa">
					<h2>Protestos</h2>
					<div className="grid-card" style={{ color: 'black' }}>
						{notarys.length > 0 ?
							notarys.map((item, index) => (
								<div className="card" key={index}>
									<p>Data da ocorrência: {formatStringDate(item.occurrenceDate)}</p>
									<p>{item.amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
									<p>{item.city}</p>
									<p>{item.federalUnity}</p>
								</div>
							)
							) :
							<div>
								<p className="warning card" style={{ color: 'black' }}>Não há protestos</p>
							</div>
						}
					</div>
					<h2>Pefin</h2>
					<div className="grid-card" style={{ color: 'black' }}>
						{pefin.length ?
							pefin.map((item, index) => (
								<div className="card" key={index}>
									<p><b>Data da ocorrência:</b><br></br> {formatStringDate(item.occurrenceDate)}</p>
									<p>{item.amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
									<p>{item.creditorName}</p>
								</div>
							))
							:
							<div>
								<p className="warning card" style={{ color: 'black' }}>Não há pendências</p>
							</div>
						}
					</div>
					<h2>Refin</h2>
					<div className="grid-card" style={{ color: 'black' }}>
						{refin.length > 0 ?
							refin.map((item, index) => (
								<div className="card" key={index}>
									<p><b>Data da ocorrência:</b><br></br> {formatStringDate(item.occurrenceDate)}</p>
									<p>{item.creditorName}</p>
									<p>{item.amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
								</div>
							))
							:
							<div>
								<p className="warning card" style={{ color: 'black' }}>Não há pendências</p>
							</div>}
					</div>
				</div>
			</div>

		</>
	)
}

export default ReportAdmin