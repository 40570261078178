'use client'
import React, { ReactNode, createContext, useState } from 'react';

export type AuthContextProps = {
	auth: string
	setAuth: React.Dispatch<React.SetStateAction<string>>
}

type ProviderProps = {
	children: ReactNode
}

export const AuthContext = createContext<AuthContextProps>({
	auth: '',
	setAuth: () => { }
});

const AuthProvider = ({ children }: ProviderProps) => {

	const [auth, setAuth] = useState('')

	return <AuthContext.Provider value={{ auth, setAuth }}>{children}</AuthContext.Provider>
}

export default AuthProvider;