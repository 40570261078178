import React from "react";
import "./style.css";
import SemiCircleGreen from "../../assets/icons/circle-green.svg";
import SemiCirclePurple from "../../assets/icons/circle-purple.svg";

const Loading = () => {
	return (
		<div id="loading" className="loading-content">
			<img className="circle" src={SemiCircleGreen.toString()} alt="green circle" />
			<img
				className="circle reverse"
				src={SemiCirclePurple.toString()}
				alt="purple circle"
			/>
		</div>
	);
};

export default Loading;
