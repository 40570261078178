'use client'
import React, { ReactNode, createContext, useState } from 'react';
import { Flag } from '../../pages/signin';

export type FlagContextProps = {
	flag: Flag | undefined
	setFlag: React.Dispatch<React.SetStateAction<Flag | undefined>>
}

type ProviderProps = {
	children: ReactNode
}

export const FlagContext = createContext<FlagContextProps>({
	flag: {
		isFinancial: false,
		isCommercial: false,
		isAdministrative: false,
		isCorrespondenBbanking: false,
		isAttendant: false
	},
	setFlag: () => { }
});

const FlagProvider = ({ children }: ProviderProps) => {

	const [flag, setFlag] = useState<Flag>()

	return <FlagContext.Provider value={{ flag, setFlag }}>{children}</FlagContext.Provider>
}

export default FlagProvider;