'use client'
import React from "react";
import AuthProvider from "./auth-context/auth";
import FlagProvider from "./flag-context/flag";

export const ContextApp = ({ children }: any) => {
	return (
		<AuthProvider >
			<FlagProvider>
				{children}
			</FlagProvider>
		</AuthProvider>
	)
}