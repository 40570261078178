import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/header";
import axios from "axios";
import { useNavigate } from "react-router";
import { AuthContext } from "../../contexts/auth-context/auth";
import './style.css'
import { Link } from "react-router-dom";
import { ToastContainer, toast, Bounce, Slide, Flip, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import saveAs from "file-saver";
import Loading from "../../components/loading";
import Logonyc from '../../assets/logoNYC/logo_nycbank_black.svg'

interface Prospects {
	id: number,
	personId: number,
	contactTime: string,
	telephoneFix: string,
	haveProperty: Boolean,
	haveVehicle: Boolean,
	isRetiree: Boolean,
	benefit: Number,
	status: string,
	plate: string,
	model: string,
	brand: string,
	year: string,
	state: null,
	city: null,
	neighbourhood: null,
	address: null,
	complement: null,
	number: null,
	bank: string,
	branch: string,
	account: string,
	tpAccount: string,
	pixKey: string,
	grossIncome: string
}

interface PersonProps {
	id: number,
	cpf: string,
	name: string,
	tpPerson: string,
	birthDate: string,
	momName: string,
	gender: string
	zipCode: String
}

export default function Prospect() {
	const navigate = useNavigate();

	const { auth, setAuth } = useContext(AuthContext)

	const [data, setData] = useState<Prospects[]>([])
	const [person, setPerson] = useState<PersonProps[]>([])
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		axios.get('https://nycprospectservice.azurewebsites.net/api/v2/ProspectService/PostUsuarios/Prospects', {
			headers: {
				Authorization: `Bearer ${auth}`
			}
		}).then((res) => {
			setData(res.data)
			// console.log(res.data)
			setLoading(false)
			// Criar um array de promessas para todas as chamadas assíncronas
			const promises = res.data.map((item: any) => (
				axios.get(`https://nycclientinfoservice.azurewebsites.net/api/v2/getpeople/${item.personId}`)
			));

			// Esperar que todas as chamadas assíncronas sejam concluídas usando Promise.all
			Promise.all(promises).then((responses) => {
				const personsData = responses.map((res) => res.data);
				setPerson(personsData);
				// console.log(personsData)
			});
		}).catch((e) => {
			console.log(e)
		})
	}, [])

	return (
		<>
			<Header />
			{
				!loading ?
					<div style={{ width: '100%' }}>
						<div className="container-title">
							<img width={160} src={Logonyc.toString()} />
							<h1>Lista de Prospecção</h1>
						</div>
						<div className="tableReportContainer" style={{ margin: '0px 8%' }}>
							<table className="table">
								<thead className="tableHeader">
									<tr>
										<th>
											Nome
										</th>
										<th>
											CPF
										</th>
										<th>
											CEP
										</th>
										<th>
											Data de Nascimento
										</th>

									</tr>
								</thead>

								<tbody className="tableBody">

									{
										person ?
											person.map((item, index) => (

												item !== null ?
													<tr id='linhas' className={index % 2 === 0 ? "lightGreen" : "darkGreen"} key={index}>

														<td onClick={() => {
															sessionStorage.setItem('idPerson', item?.id.toString());
															sessionStorage.setItem('Name', item?.name);
															sessionStorage.setItem('cpfLoan', item?.cpf);
															navigate('/prospect/prospect-approved')
														}}
															className='name'>{item?.name}</td>
														<td>{item?.cpf}</td>
														<td>{item?.zipCode}</td>
														<td>{item?.birthDate ? item?.birthDate.replaceAll('-', '/') : item?.birthDate}</td>
													</tr>
													:
													''
											))
											:
											<p className="aviso">
												Não há contas cadastradas
											</p>
									}
								</tbody>
							</table>
						</div>

						<ToastContainer transition={Slide} />
					</div>
					:
					<div className="loading-page-loan">
						<p>Carregando...</p>
						<Loading />
					</div>
			}

		</>
	)
}