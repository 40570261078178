import { useContext, useEffect, useRef, useState } from "react";
import "./style.css";
import Input from "../../components/inputs/defaultInput";
import Button from "../../components/buttons/primaryButton";
import logonyc from "../../assets/logoNYC/logo_nycbank_black.svg"
import axios from 'axios';
import CryptoNyc from '../../utils/crypto-nyc'
import MaskInput from "../../components/inputs/maskedInput";
import IconButton from "../../components/buttons/iconButton";
import Hide from '../../assets/icons/hide.svg'
import Show from '../../assets/icons/show.svg'
import { ToastContainer, toast, Bounce, Slide, Flip, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AuthContext } from "../../contexts/auth-context/auth"
import { ContextApp } from "../../contexts";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/header";



export default function SignUpPage() {
	const [cpf, setCPF] = useState("")
	const [userPassword, setUserPassword] = useState("")
	const [confirmUserPassword, setConfirmUserPassword] = useState("")
	const [passwordVisbility, setPasswordVisbility] = useState("password")
	const [passwordConfirmVisbility, setPasswordConfirmVisbility] = useState("password")
	const { auth, setAuth } = useContext(AuthContext)

	const navigate = useNavigate();
	const _cryptoNyc = new CryptoNyc();

	const showOrHide = () => {
		if (passwordVisbility == "tel") {
			setPasswordVisbility("password")
		} else {
			setPasswordVisbility("tel")
		}
	}
	const showOrHideConfirm = () => {
		if (passwordConfirmVisbility == "tel") {
			setPasswordConfirmVisbility("password")
		} else {
			setPasswordConfirmVisbility("tel")
		}
	}

	const SignUp = () => {

		// try {
		if (confirmUserPassword == userPassword) {
			return axios.post('https://nycdashsingupservice.azurewebsites.net/api/dash/DashUser/register',
				{
					cpf: cpf.replaceAll(".", "").replace("-", ""),
					password: userPassword,
					isFinancial: false,
					isCommercial: true,
					isAdministrative: false,
					isCorrespondenBbanking: false,
					isAttendant: false
				}, {
				headers: {
					Authorization: `Bearer ${auth}`
				}
			}
			)
				.then(res => {
					switch (res.status) {
						case 200:
							toast.success('Cadastrado com sucesso!', {
								position: toast.POSITION.TOP_RIGHT,
								autoClose: 5000,
								hideProgressBar: false,
								closeOnClick: true,
								rtl: false,
								pauseOnFocusLoss: true,
								draggable: true,
								pauseOnHover: true,
								theme: "light"
							});

							setTimeout(() => {
								navigate("/signin");
							}, 2000);

							break;

						default:
							toast('Erro inesperado, tente novamente mais tarde')
							break;


					}
				})
				.catch(function (error) {
					if (error.response) {
						switch (error.response.status) {
							case 400:
								toast.error('Por favor preencha os dados necessários e tente novamente', {
									position: toast.POSITION.TOP_RIGHT,
									autoClose: 5000,
									hideProgressBar: false,
									closeOnClick: true,
									rtl: false,
									pauseOnFocusLoss: true,
									draggable: true,
									pauseOnHover: true,
									theme: "light"
								});

								break;

							case 401:
								console.log(error)
								toast.error('Usuário não autorizado para registro neste sistema', {
									position: toast.POSITION.TOP_RIGHT,
									autoClose: 5000,
									hideProgressBar: false,
									closeOnClick: true,
									rtl: false,
									pauseOnFocusLoss: true,
									draggable: true,
									pauseOnHover: true,
									theme: "light"
								});

								break;

							case 409:
								toast.info('Este CPF já pertence a um usuário cadastrado!', {
									position: toast.POSITION.TOP_RIGHT,
									autoClose: 5000,
									hideProgressBar: false,
									closeOnClick: true,
									rtl: false,
									pauseOnFocusLoss: true,
									draggable: true,
									pauseOnHover: true,
									theme: "light"
								});

								break;

							default:
								toast.error('Erro inesperado, tente novamente mais tarde', {
									position: toast.POSITION.TOP_RIGHT,
									autoClose: 5000,
									hideProgressBar: false,
									closeOnClick: true,
									rtl: false,
									pauseOnFocusLoss: true,
									draggable: true,
									pauseOnHover: true,
									theme: "light"
								})
								break;


						}
					} else if (error.request) {
						console.log(error.request);
					} else {
						console.log('Error', error.message);
					}
				})
		} else {
			toast.error('As senhas digitadas não são iguais, tente novamente.', {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				rtl: false,
				pauseOnFocusLoss: true,
				draggable: true,
				pauseOnHover: true,
				theme: "light",

			});

		}
	}

	return (
		<>
			<Header />
			<div className="SignInContainer">

				<img src={logonyc.toString()} alt="logo da NYC Bank" />
				<div className="SignIn">
					<MaskInput mask="999.999.999-99" maskChar={null} placeholder="CPF" type="text" value={cpf} onChange={(e) => setCPF(e.target.value)} />
					<div className="passwordContainer">
						<Input maxLength={6} placeholder="Senha" type={passwordVisbility} value={userPassword} onChange={(e) => setUserPassword(e.target.value)} />
						<IconButton onClick={() => showOrHide()} alt="EsconderSenha" icon={passwordVisbility == "tel" ? Show.toString() : Hide.toString()} />
					</div>
					<div className="passwordContainer">
						<Input maxLength={6} placeholder="Confirmar senha" type={passwordConfirmVisbility} value={confirmUserPassword} onChange={(e) => setConfirmUserPassword(e.target.value)} />
						<IconButton onClick={() => showOrHideConfirm()} alt="EsconderSenha" icon={passwordConfirmVisbility == "tel" ? Show.toString() : Hide.toString()} />
					</div>
					<Button disabled={cpf.length !== 14} className={`${cpf.length !== 14 ? 'disabled' : "able"} Button`} onClick={() => SignUp()} textButton={"Enviar"} />
					{/* <Link className="AccButton" to="/signin">Já tem uma conta?</Link> */}
				</div>
				<ToastContainer transition={Slide} />
			</div>
		</>
	)
}