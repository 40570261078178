export const banks = [
	{
		code: "001",
		bankName: "Banco Do Brasil S.A (BB)",
		ispb: "0"
	},
	{
		code: "237",
		bankName: "Bradesco S.A.",
		ispb: "60746948"
	},
	{
		code: "335",
		bankName: "Banco Digio S.A.",
		ispb: "27098060"
	},
	{
		code: "260",
		bankName: "Nu Pagamentos S.A (Nubank)",
		ispb: "18236120"
	},
	{
		code: "290",
		bankName: "PagSeguro Internet S.A.",
		ispb: "8561701"
	},
	{
		code: "323",
		bankName: "Mercado Pago – Conta Do Mercado Livre",
		ispb: "10573521"
	},
	{
		code: "237",
		bankName: "Next Bank (Mesmo code Do Bradesco)",
		ispb: "60746948"
	},
	{
		code: "637",
		bankName: "Banco Sofisa S.A (Sofisa Direto)",
		ispb: "60889128"
	},
	{
		code: "077",
		bankName: "Banco Inter S.A.",
		ispb: "416968"
	},
	{
		code: "341",
		bankName: "Itaú Unibanco S.A.",
		ispb: "60701190"
	},
	{
		code: "104",
		bankName: "Caixa Econômica Federal (CEF)",
		ispb: "360305"
	},
	{
		code: "033",
		bankName: "Banco Santander Brasil S.A.",
		ispb: "90400888"
	},
	{
		code: "212",
		bankName: "Banco Original S.A.",
		ispb: "92894922"
	},
	{
		code: "756",
		bankName: "Bancoob – Banco Cooperativo Do Brasil S.A.",
		ispb: "2038232"
	},
	{
		code: "655",
		bankName: "Banco Votorantim S.A.",
		ispb: "59588111"
	},
	{
		code: "655",
		bankName: "Neon Pagamentos S.A (Memso code Do Banco Votorantim)",
		ispb: "59588111"
	},
	{
		code: "041",
		bankName: "Banrisul – Banco Do Estado Do Rio Grande Do Sul S.A.",
		ispb: "92702067"
	},
	{
		code: "389",
		bankName: "Banco Mercantil Do Brasil S.A.",
		ispb: "17184037"
	},
	{
		code: "422",
		bankName: "Banco Safra S.A.",
		ispb: "58160789"
	},
	{
		code: "070",
		bankName: "BRB – Banco De Brasília S.A.",
		ispb: "208"
	},
	{
		code: "136",
		bankName: "Unicred Cooperativa LTDA",
		ispb: "315557"
	},
	{
		code: "741",
		bankName: "Banco Ribeirão Preto S.A.",
		ispb: "517645"
	},
	{
		code: "739",
		bankName: "Banco Cetelem S.A.",
		ispb: "558456"
	},
	{
		code: "743",
		bankName: "Banco Semear S.A.",
		ispb: "795423"
	},
	{
		code: "100",
		bankName: "Planner Corretora De Valores S.A.",
		ispb: "806535"
	},
	{
		code: "096",
		bankName: "Banco B3 S.A.",
		ispb: "997185"
	},
	{
		code: "747",
		bankName: "Banco Rabobank Internacional Do Brasil S.A.",
		ispb: "1023570"
	},
	{
		code: "748",
		bankName: "Banco Cooperativa Sicredi S.A.",
		ispb: "1181521"
	},
	{
		code: "752",
		bankName: "Banco BNP Paribas Brasil S.A.",
		ispb: "1522368"
	},
	{
		code: "091",
		bankName: "Unicred Central Rs",
		ispb: "1634601"
	},
	{
		code: "399",
		bankName: "Kirton Bank S.A. – Banco Múltiplo",
		ispb: "1701201"
	},
	{
		code: "108",
		bankName: "Portocred S.A.",
		ispb: "1800019"
	},
	{
		code: "757",
		bankName: "Banco Keb Hana Do Brasil S.A.",
		ispb: "2318507"
	},
	{
		code: "102",
		bankName: "XP Investimentos S.A.",
		ispb: "2332886"
	},
	{
		code: "348",
		bankName: "Banco XP S/A",
		ispb: "33264668"
	},
	{
		code: "340",
		bankName: "Super Pagamentos S/A (Superdital)",
		ispb: "9554480"
	},
	{
		code: "084",
		bankName: "Uniprime Norte Do Paraná",
		ispb: "2398976"
	},
	{
		code: "180",
		bankName: "Cm Capital Markets Cctvm Ltda",
		ispb: "2685483"
	},
	{
		code: "066",
		bankName: "Banco Morgan Stanley S.A.",
		ispb: "2801938"
	},
	{
		code: "015",
		bankName: "UBS Brasil Cctvm S.A.",
		ispb: "2819125"
	},
	{
		code: "143",
		bankName: "Treviso Cc S.A.",
		ispb: "2992317"
	},
	{
		code: "062",
		bankName: "Hipercard Banco Múltiplo S.A.",
		ispb: "3012230"
	},
	{
		code: "074",
		bankName: "Banco J. Safra S.A.",
		ispb: "3017677"
	},
	{
		code: "099",
		bankName: "Uniprime Central Ccc Ltda",
		ispb: "3046391"
	},
	{
		code: "025",
		bankName: "Banco Alfa S.A.",
		ispb: "3323840"
	},
	{
		code: "075",
		bankName: "Bco Abn Amro S.A.",
		ispb: "3532415"
	},
	{
		code: "040",
		bankName: "Banco Cargill S.A.",
		ispb: "3609817"
	},
	{
		code: "190",
		bankName: "Servicoop",
		ispb: "3973814"
	},
	{
		code: "063",
		bankName: "Banco Bradescard",
		ispb: "4184779"
	},
	{
		code: "191",
		bankName: "Nova Futura Ctvm Ltda",
		ispb: "4257795"
	},
	{
		code: "064",
		bankName: "Goldman Sachs Do Brasil Bm S.A.",
		ispb: "4332281"
	},
	{
		code: "097",
		bankName: "Ccc Noroeste Brasileiro Ltda",
		ispb: "4632856"
	},
	{
		code: "016",
		bankName: "Ccm Desp Trâns Sc E Rs",
		ispb: "4715685"
	},
	{
		code: "012",
		bankName: "Banco Inbursa",
		ispb: "4866275"
	},
	{
		code: "003",
		bankName: "Banco Da Amazônia S.A.",
		ispb: "4902979"
	},
	{
		code: "060",
		bankName: "Confidence Cc S.A.",
		ispb: "4913129"
	},
	{
		code: "037",
		bankName: "Banco Do Estado Do Pará S.A.",
		ispb: "4913711"
	},
	{
		code: "159",
		bankName: "Casa do Crédito S.A.",
		ispb: "5442029"
	},
	{
		code: "172",
		bankName: "Albatross Ccv S.A.",
		ispb: "5452073"
	},
	{
		code: "085",
		bankName: "Cooperativa Central de Créditos – Ailos",
		ispb: "5463212"
	},
	{
		code: "114",
		bankName: "Central Cooperativa De Crédito no Estado do Espírito Santo",
		ispb: "5790149"
	},
	{
		code: "036",
		bankName: "Banco Bradesco BBI S.A.",
		ispb: "6271464"
	},
	{
		code: "394",
		bankName: "Banco Bradesco Financiamentos S.A.",
		ispb: "7207996"
	},
	{
		code: "004",
		bankName: "Banco Do Nordeste Do Brasil S.A.",
		ispb: "7237373"
	},
	{
		code: "320",
		bankName: "China Construction Bank – Ccb Brasil S.A.",
		ispb: "7450604"
	},
	{
		code: "189",
		bankName: "Hs Financeira",
		ispb: "7512441"
	},
	{
		code: "105",
		bankName: "Lecca Cfi S.A.",
		ispb: "7652226"
	},
	{
		code: "076",
		bankName: "Banco KDB Brasil S.A.",
		ispb: "7656500"
	},
	{
		code: "082",
		bankName: "Banco Topázio S.A.",
		ispb: "7679404"
	},
	{
		code: "286",
		bankName: "Cooperativa de Crédito Rural de De Ouro",
		ispb: "7853842"
	},
	{
		code: "093",
		bankName: "PóloCred Scmepp Ltda",
		ispb: "7945233"
	},
	{
		code: "273",
		bankName: "Ccr De São Miguel Do Oeste",
		ispb: "8253539"
	},
	{
		code: "157",
		bankName: "Icap Do Brasil Ctvm Ltda",
		ispb: "9105360"
	},
	{
		code: "183",
		bankName: "Socred S.A.",
		ispb: "9210106"
	},
	{
		code: "014",
		bankName: "Natixis Brasil S.A.",
		ispb: "9274232"
	},
	{
		code: "130",
		bankName: "Caruana Scfi",
		ispb: "9313766"
	},
	{
		code: "127",
		bankName: "Codepe Cvc S.A.",
		ispb: "9512542"
	},
	{
		code: "079",
		bankName: "Banco Original Do Agronegócio S.A.",
		ispb: "9516419"
	},
	{
		code: "081",
		bankName: "Bbn Banco Brasileiro De Negocios S.A.",
		ispb: "10264663"
	},
	{
		code: "118",
		bankName: "Standard Chartered Bi S.A.",
		ispb: "11932017"
	},
	{
		code: "133",
		bankName: "Cresol Confederação",
		ispb: "10398952"
	},
	{
		code: "121",
		bankName: "Banco Agibank S.A.",
		ispb: "10664513"
	},
	{
		code: "083",
		bankName: "Banco Da China Brasil S.A.",
		ispb: "10690848"
	},
	{
		code: "138",
		bankName: "Get Money Cc Ltda",
		ispb: "10853017"
	},
	{
		code: "024",
		bankName: "Banco Bandepe S.A.",
		ispb: "10866788"
	},
	{
		code: "095",
		bankName: "Banco Confidence De Câmbio S.A.",
		ispb: "11703662"
	},
	{
		code: "094",
		bankName: "Banco Finaxis",
		ispb: "11758741"
	},
	{
		code: "276",
		bankName: "Senff S.A.",
		ispb: "11970623"
	},
	{
		code: "137",
		bankName: "Multimoney Cc Ltda",
		ispb: "12586596"
	},
	{
		code: "092",
		bankName: "BRK S.A.",
		ispb: "12865507"
	},
	{
		code: "047",
		bankName: "Banco do Estado de Sergipe S.A.",
		ispb: "13009717"
	},
	{
		code: "144",
		bankName: "Bexs Banco De Cambio S.A.",
		ispb: "13059145"
	},
	{
		code: "126",
		bankName: "BR Partners Banco de Investimento S.A.",
		ispb: "13220493"
	},
	{
		code: "301",
		bankName: "BPP Instituição De Pagamentos S.A.",
		ispb: "13370835"
	},
	{
		code: "173",
		bankName: "BRL Trust Dtvm Sa",
		ispb: "13486793"
	},
	{
		code: "119",
		bankName: "Banco Western Union do Brasil S.A.",
		ispb: "13720915"
	},
	{
		code: "254",
		bankName: "Paraná Banco S.A.",
		ispb: "14388334"
	},
	{
		code: "268",
		bankName: "Barigui Companhia Hipotecária",
		ispb: "14511781"
	},
	{
		code: "107",
		bankName: "Banco Bocom BBM S.A.",
		ispb: "15114366"
	},
	{
		code: "412",
		bankName: "Banco Capital S.A.",
		ispb: "15173776"
	},
	{
		code: "124",
		bankName: "Banco Woori Bank Do Brasil S.A.",
		ispb: "15357060"
	},
	{
		code: "149",
		bankName: "Facta S.A. Cfi",
		ispb: "15581638"
	},
	{
		code: "197",
		bankName: "Stone Pagamentos S.A.",
		ispb: "16501555"
	},
	{
		code: "142",
		bankName: "Broker Brasil Cc Ltda",
		ispb: "16944141"
	},
	{
		code: "389",
		bankName: "Banco Mercantil Do Brasil S.A.",
		ispb: "17184037"
	},
	{
		code: "184",
		bankName: "Banco Itaú BBA S.A.",
		ispb: "17298092"
	},
	{
		code: "634",
		bankName: "Banco Triangulo S.A (Banco Triângulo)",
		ispb: "17351180"
	},
	{
		code: "545",
		bankName: "Senso Ccvm S.A.",
		ispb: "17352220"
	},
	{
		code: "132",
		bankName: "ICBC do Brasil Bm S.A.",
		ispb: "17453575"
	},
	{
		code: "298",
		bankName: "Vip’s Cc Ltda",
		ispb: "17772370"
	},
	{
		code: "129",
		bankName: "UBS Brasil Bi S.A.",
		ispb: "18520834"
	},
	{
		code: "128",
		bankName: "Ms Bank S.A Banco De Câmbio",
		ispb: "19307785"
	},
	{
		code: "194",
		bankName: "Parmetal Dtvm Ltda",
		ispb: "20155248"
	},
	{
		code: "310",
		bankName: "VORTX Dtvm Ltda",
		ispb: "22610500"
	},
	{
		code: "163",
		bankName: "Commerzbank Brasil S.A Banco Múltiplo",
		ispb: "23522214"
	},
	{
		code: "280",
		bankName: "Avista S.A.",
		ispb: "23862762"
	},
	{
		code: "146",
		bankName: "Guitta Cc Ltda",
		ispb: "24074692"
	},
	{
		code: "279",
		bankName: "Ccr De Primavera Do Leste",
		ispb: "26563270"
	},
	{
		code: "182",
		bankName: "Dacasa Financeira S/A",
		ispb: "27406222"
	},
	{
		code: "278",
		bankName: "Genial Investimentos Cvm S.A.",
		ispb: "27652684"
	},
	{
		code: "271",
		bankName: "Ib Cctvm Ltda",
		ispb: "27842177"
	},
	{
		code: "021",
		bankName: "Banco Banestes S.A.",
		ispb: "28127603"
	},
	{
		code: "246",
		bankName: "Banco Abc Brasil S.A.",
		ispb: "28195667"
	},
	{
		code: "751",
		bankName: "Scotiabank Brasil",
		ispb: "29030467"
	},
	{
		code: "208",
		bankName: "Banco BTG Pactual S.A.",
		ispb: "30306294"
	},
	{
		code: "746",
		bankName: "Banco Modal S.A.",
		ispb: "30723886"
	},
	{
		code: "241",
		bankName: "Banco Classico S.A.",
		ispb: "31597552"
	},
	{
		code: "612",
		bankName: "Banco Guanabara S.A.",
		ispb: "31880826"
	},
	{
		code: "604",
		bankName: "Banco Industrial Do Brasil S.A.",
		ispb: "31895683"
	},
	{
		code: "505",
		bankName: "Banco Credit Suisse (Brl) S.A.",
		ispb: "32062580"
	},
	{
		code: "196",
		bankName: "Banco Fair Cc S.A.",
		ispb: "32648370"
	},
	{
		code: "300",
		bankName: "Banco La Nacion Argentina",
		ispb: "33042151"
	},
	{
		code: "477",
		bankName: "Citibank N.A.",
		ispb: "33042953"
	},
	{
		code: "266",
		bankName: "Banco Cedula S.A.",
		ispb: "33132044"
	},
	{
		code: "122",
		bankName: "Banco Bradesco BERJ S.A.",
		ispb: "33147315"
	},
	{
		code: "376",
		bankName: "Banco J.P. Morgan S.A.",
		ispb: "33172537"
	},
	{
		code: "473",
		bankName: "Banco Caixa Geral Brasil S.A.",
		ispb: "33466988"
	},
	{
		code: "745",
		bankName: "Banco Citibank S.A.",
		ispb: "33479023"
	},
	{
		code: "120",
		bankName: "Banco Rodobens S.A.",
		ispb: "33603457"
	},
	{
		code: "265",
		bankName: "Banco Fator S.A.",
		ispb: "33644196"
	},
	{
		code: "007",
		bankName: "BNDES (Banco Nacional Do Desenvolvimento Social)",
		ispb: "33657248"
	},
	{
		code: "188",
		bankName: "Ativa S.A Investimentos",
		ispb: "33775974"
	},
	{
		code: "134",
		bankName: "BGC Liquidez Dtvm Ltda",
		ispb: "33862244"
	},
	{
		code: "641",
		bankName: "Banco Alvorada S.A.",
		ispb: "33870163"
	},
	{
		code: "029",
		bankName: "Banco Itaú Consignado S.A.",
		ispb: "33885724"
	},
	{
		code: "243",
		bankName: "Banco Máxima S.A.",
		ispb: "33923798"
	},
	{
		code: "078",
		bankName: "Haitong Bi Do Brasil S.A.",
		ispb: "34111187"
	},
	{
		code: "111",
		bankName: "Banco Oliveira Trust Dtvm S.A.",
		ispb: "36113876"
	},
	{
		code: "017",
		bankName: "Bny Mellon Banco S.A.",
		ispb: "42272526"
	},
	{
		code: "174",
		bankName: "Pernambucanas Financ S.A.",
		ispb: "43180355"
	},
	{
		code: "495",
		bankName: "La Provincia Buenos Aires Banco",
		ispb: "44189447"
	},
	{
		code: "125",
		bankName: "Brasil Plural S.A Banco",
		ispb: "45246410"
	},
	{
		code: "488",
		bankName: "Jpmorgan Chase Bank",
		ispb: "46518205"
	},
	{
		code: "065",
		bankName: "Banco Andbank S.A.",
		ispb: "48795256"
	},
	{
		code: "492",
		bankName: "Ing Bank N.V.",
		ispb: "49336860"
	},
	{
		code: "250",
		bankName: "Banco Bcv",
		ispb: "50585090"
	},
	{
		code: "145",
		bankName: "Levycam Ccv Ltda",
		ispb: "50579044"
	},
	{
		code: "494",
		bankName: "Banco Rep Oriental Uruguay",
		ispb: "51938876"
	},
	{
		code: "253",
		bankName: "Bexs Cc S.A.",
		ispb: "52937216"
	},
	{
		code: "269",
		bankName: "Hsbc Banco De Investimento",
		ispb: "53518684"
	},
	{
		code: "213",
		bankName: "Bco Arbi S.A.",
		ispb: "54403563"
	},
	{
		code: "139",
		bankName: "Intesa Sanpaolo Brasil S.A.",
		ispb: "55230916"
	},
	{
		code: "018",
		bankName: "Banco Tricury S.A.",
		ispb: "57839805"
	},
	{
		code: "630",
		bankName: "Banco Intercap S.A.",
		ispb: "58497702"
	},
	{
		code: "224",
		bankName: "Banco Fibra S.A.",
		ispb: "58616418"
	},
	{
		code: "600",
		bankName: "Banco Luso Brasileiro S.A.",
		ispb: "59118133"
	},
	{
		code: "623",
		bankName: "Banco Pan S.A.",
		ispb: "59285411"
	},
	{
		code: "204",
		bankName: "Banco Bradesco Cartoes S.A.",
		ispb: "59438325"
	},
	{
		code: "479",
		bankName: "Banco ItauBank S.A.",
		ispb: "60394079"
	},
	{
		code: "456",
		bankName: "Banco MUFG Brasil S.A.",
		ispb: "60498557"
	},
	{
		code: "464",
		bankName: "Banco Sumitomo Mitsui Brasil S.A.",
		ispb: "60518222"
	},
	{
		code: "613",
		bankName: "Omni Banco S.A.",
		ispb: "60850229"
	},
	{
		code: "652",
		bankName: "Itaú Unibanco Holding Bm S.A.",
		ispb: "60872504"
	},
	{
		code: "653",
		bankName: "Banco Indusval S.A.",
		ispb: "61024352"
	},
	{
		code: "069",
		bankName: "Banco Crefisa S.A.",
		ispb: "61033106"
	},
	{
		code: "370",
		bankName: "Banco Mizuho S.A.",
		ispb: "61088183"
	},
	{
		code: "249",
		bankName: "Banco Investcred Unibanco S.A.",
		ispb: "61182408"
	},
	{
		code: "318",
		bankName: "Banco BMG S.A.",
		ispb: "61186680"
	},
	{
		code: "626",
		bankName: "Banco Ficsa S.A.",
		ispb: "61348538"
	},
	{
		code: "270",
		bankName: "Sagitur Cc Ltda",
		ispb: "61444949"
	},
	{
		code: "366",
		bankName: "Banco Societe Generale Brasil",
		ispb: "61533584"
	},
	{
		code: "113",
		bankName: "Magliano S.A.",
		ispb: "61723847"
	},
	{
		code: "131",
		bankName: "Tullett Prebon Brasil Cvc Ltda",
		ispb: "61747085"
	},
	{
		code: "011",
		bankName: "C.Suisse Hedging-Griffo Cv S.A (Credit Suisse)",
		ispb: "61809182"
	},
	{
		code: "611",
		bankName: "Banco Paulista",
		ispb: "61820817"
	},
	{
		code: "755",
		bankName: "Bofa Merrill Lynch Bm S.A.",
		ispb: "62073200"
	},
	{
		code: "089",
		bankName: "Ccr Reg Mogiana",
		ispb: "62109566"
	},
	{
		code: "643",
		bankName: "Banco Pine S.A.",
		ispb: "62144175"
	},
	{
		code: "140",
		bankName: "Easynvest – Título Cv S.A.",
		ispb: "62169875"
	},
	{
		code: "707",
		bankName: "Banco Daycoval S.A.",
		ispb: "62232889"
	},
	{
		code: "288",
		bankName: "Carol Dtvm Ltda",
		ispb: "62237649"
	},
	{
		code: "101",
		bankName: "Renascença Dtvm Ltda",
		ispb: "62287735"
	},
	{
		code: "487",
		bankName: "Deutsche Bank S.A (Banco Alemão)",
		ispb: "62331228"
	},
	{
		code: "233",
		bankName: "Banco Cifra S.A.",
		ispb: "62421979"
	},
	{
		code: "177",
		bankName: "Guide Investimentos S.A. Corretora de Valores",
		ispb: "65913436"
	},
	{
		code: "633",
		bankName: "Banco Rendimento S.A.",
		ispb: "68900810"
	},
	{
		code: "218",
		bankName: "Banco Bs2 S.A.",
		ispb: "71027866"
	},
	{
		code: "292",
		bankName: "BS2 Distribuidora De Títulos E Investimentos",
		ispb: "28650236"
	},
	{
		code: "169",
		bankName: "Banco Olé Bonsucesso Consignado S.A.",
		ispb: "71371686"
	},
	{
		code: "293",
		bankName: "Lastro Rdv Dtvm Ltda",
		ispb: "71590442"
	},
	{
		code: "285",
		bankName: "Frente Cc Ltda",
		ispb: "71677850"
	},
	{
		code: "080",
		bankName: "B&T Cc Ltda",
		ispb: "73622748"
	},
	{
		code: "753",
		bankName: "Novo Banco Continental S.A Bm",
		ispb: "74828799"
	},
	{
		code: "222",
		bankName: "Banco Crédit Agricole Br S.A.",
		ispb: "75647891"
	},
	{
		code: "754",
		bankName: "Banco Sistema S.A.",
		ispb: "76543115"
	},
	{
		code: "098",
		bankName: "Credialiança Ccr",
		ispb: "78157146"
	},
	{
		code: "610",
		bankName: "Banco VR S.A.",
		ispb: "78626983"
	},
	{
		code: "712",
		bankName: "Banco Ourinvest S.A.",
		ispb: "78632767"
	},
	{
		code: "010",
		bankName: "CREDICOAMO CRÉDITO RURAL COOPERATIVA",
		ispb: "81723108"
	},
	{
		code: "283",
		bankName: "RB Capital Investimentos Dtvm Ltda",
		ispb: "89960090"
	},
	{
		code: "217",
		bankName: "Banco John Deere S.A.",
		ispb: "91884981"
	},
	{
		code: "117",
		bankName: "Advanced Cc Ltda",
		ispb: "92856905"
	},
	{
		code: "336",
		bankName: "Banco C6 S.A – C6 Bank",
		ispb: "28326000"
	},
	{
		code: "654",
		bankName: "Banco A.J. Renner S.A.",
		ispb: "92874270"
	},
	{
		code: "n/a",
		bankName: "Banco Central do Brasil – Selic",
		ispb: "38121"
	},
	{
		code: "n/a",
		bankName: "Banco Central do Brasil",
		ispb: "38166"
	},
	{
		code: "272",
		bankName: "AGK Corretora de Câmbio S.A.",
		ispb: "250699"
	},
	{
		code: "n/a",
		bankName: "Secretaria do Tesouro Nacional – STN",
		ispb: "394460"
	},
	{
		code: "330",
		bankName: "Banco Bari de Investimentos e Financiamentos S.A.",
		ispb: "556603"
	},
	{
		code: "362",
		bankName: "CIELO S.A.",
		ispb: "1027058"
	},
	{
		code: "322",
		bankName: "Cooperativa de Crédito Rural de Abelardo Luz – Sulcredi/Crediluz",
		ispb: "1073966"
	},
	{
		code: "350",
		bankName: "Cooperativa De Crédito Rural De Pequenos Agricultores E Da Reforma Agrária Do Ce",
		ispb: "1330387"
	},
	{
		code: "091",
		bankName: "Central De Cooperativas De Economia E Crédito Mútuo Do Estado Do Rio Grande Do S",
		ispb: "1634601"
	},
	{
		code: "379",
		bankName: "COOPERFORTE – Cooperativa De Economia E Crédito Mútuo Dos Funcionários De Instit",
		ispb: "1658426"
	},
	{
		code: "378",
		bankName: "BBC LEASING S.A. – Arrendamento Mercantil",
		ispb: "1852137"
	},
	{
		code: "360",
		bankName: "TRINUS Capital Distribuidora De Títulos E Valores Mobiliários S.A.",
		ispb: "2276653"
	},
	{
		code: "084",
		bankName: "UNIPRIME NORTE DO PARANÁ – COOPERATIVA DE CRÉDITO LTDA",
		ispb: "2398976"
	},
	{
		code: "n/a",
		bankName: "Câmara Interbancária de Pagamentos – CIP – LDL",
		ispb: "2992335"
	},
	{
		code: "387",
		bankName: "Banco Toyota do Brasil S.A.",
		ispb: "3215790"
	},
	{
		code: "326",
		bankName: "PARATI – CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.",
		ispb: "3311443"
	},
	{
		code: "315",
		bankName: "PI Distribuidora de Títulos e Valores Mobiliários S.A.",
		ispb: "3502968"
	},
	{
		code: "307",
		bankName: "Terra Investimentos Distribuidora de Títulos e Valores Mobiliários Ltda.",
		ispb: "3751794"
	},
	{
		code: "296",
		bankName: "VISION S.A. CORRETORA DE CAMBIO",
		ispb: "4062902"
	},
	{
		code: "382",
		bankName: "FIDÚCIA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE L",
		ispb: "4307598"
	},
	{
		code: "097",
		bankName: "Credisis – Central de Cooperativas de Crédito Ltda.",
		ispb: "4632856"
	},
	{
		code: "016",
		bankName: "COOPERATIVA DE CRÉDITO MÚTUO DOS DESPACHANTES DE TRÂNSITO DE SANTA CATARINA E RI",
		ispb: "4715685"
	},
	{
		code: "299",
		bankName: "SOROCRED   CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.",
		ispb: "4814563"
	},
	{
		code: "359",
		bankName: "ZEMA CRÉDITO, FINANCIAMENTO E INVESTIMENTO S/A",
		ispb: "5351887"
	},
	{
		code: "391",
		bankName: "COOPERATIVA DE CRÉDITO RURAL DE IBIAM – SULCREDI/IBIAM",
		ispb: "8240446"
	},
	{
		code: "368",
		bankName: "Banco CSF S.A.",
		ispb: "8357240"
	},
	{
		code: "259",
		bankName: "MONEYCORP BANCO DE CÂMBIO S.A.",
		ispb: "8609934"
	},
	{
		code: "364",
		bankName: "GERENCIANET S.A.",
		ispb: "9089356"
	},
	{
		code: "014",
		bankName: "STATE STREET BRASIL S.A. – BANCO COMERCIAL",
		ispb: "9274232"
	},
	{
		code: "081",
		bankName: "Banco Seguro S.A.",
		ispb: "10264663"
	},
	{
		code: "384",
		bankName: "GLOBAL FINANÇAS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO",
		ispb: "11165756"
	},
	{
		code: "088",
		bankName: "BANCO RANDON S.A.",
		ispb: "11476673"
	},
	{
		code: "319",
		bankName: "OM DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
		ispb: "11495073"
	},
	{
		code: "274",
		bankName: "MONEY PLUS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORT",
		ispb: "11581339"
	},
	{
		code: "095",
		bankName: "Travelex Banco de Câmbio S.A.",
		ispb: "11703662"
	},
	{
		code: "332",
		bankName: "Acesso Soluções de Pagamento S.A.",
		ispb: "13140088"
	},
	{
		code: "325",
		bankName: "Órama Distribuidora de Títulos e Valores Mobiliários S.A.",
		ispb: "13293225"
	},
	{
		code: "331",
		bankName: "Fram Capital Distribuidora de Títulos e Valores Mobiliários S.A.",
		ispb: "13673855"
	},
	{
		code: "396",
		bankName: "HUB PAGAMENTOS S.A.",
		ispb: "13884775"
	},
	{
		code: "309",
		bankName: "CAMBIONET CORRETORA DE CÂMBIO LTDA.",
		ispb: "14190547"
	},
	{
		code: "313",
		bankName: "AMAZÔNIA CORRETORA DE CÂMBIO LTDA.",
		ispb: "16927221"
	},
	{
		code: "377",
		bankName: "MS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA",
		ispb: "17826860"
	},
	{
		code: "321",
		bankName: "CREFAZ SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORTE LT",
		ispb: "18188384"
	},
	{
		code: "383",
		bankName: "BOLETOBANCÁRIO.COM TECNOLOGIA DE PAGAMENTOS LTDA.",
		ispb: "21018182"
	},
	{
		code: "324",
		bankName: "CARTOS SOCIEDADE DE CRÉDITO DIRETO S.A.",
		ispb: "21332862"
	},
	{
		code: "380",
		bankName: "PICPAY SERVICOS S.A.",
		ispb: "22896431"
	},
	{
		code: "343",
		bankName: "FFA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA.",
		ispb: "24537861"
	},
	{
		code: "349",
		bankName: "AL5 S.A. CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
		ispb: "27214112"
	},
	{
		code: "374",
		bankName: "REALIZE CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.",
		ispb: "27351731"
	},
	{
		code: "n/a",
		bankName: "B3 SA – Brasil, Bolsa, Balcão – Segmento Cetip UTVM",
		ispb: "28719664"
	},
	{
		code: "n/a",
		bankName: "Câmara Interbancária de Pagamentos – CIP C3",
		ispb: "29011780"
	},
	{
		code: "352",
		bankName: "TORO CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
		ispb: "29162769"
	},
	{
		code: "329",
		bankName: "QI Sociedade de Crédito Direto S.A.",
		ispb: "32402502"
	},
	{
		code: "342",
		bankName: "Creditas Sociedade de Crédito Direto S.A.",
		ispb: "32997490"
	},
	{
		code: "397",
		bankName: "LISTO SOCIEDADE DE CRÉDITO DIRETO S.A.",
		ispb: "34088029"
	},
	{
		code: "355",
		bankName: "ÓTIMO SOCIEDADE DE CRÉDITO DIRETO S.A.",
		ispb: "34335592"
	},
	{
		code: "367",
		bankName: "VITREO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
		ispb: "34711571"
	},
	{
		code: "373",
		bankName: "UP.P SOCIEDADE DE EMPRÉSTIMO ENTRE PESSOAS S.A.",
		ispb: "35977097"
	},
	{
		code: "408",
		bankName: "BÔNUSCRED SOCIEDADE DE CRÉDITO DIRETO S.A.",
		ispb: "36586946"
	},
	{
		code: "404",
		bankName: "SUMUP SOCIEDADE DE CRÉDITO DIRETO S.A.",
		ispb: "37241230"
	},
	{
		code: "403",
		bankName: "CORA SOCIEDADE DE CRÉDITO DIRETO S.A.",
		ispb: "37880206"
	},
	{
		code: "306",
		bankName: "PORTOPAR DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.",
		ispb: "40303299"
	},
	{
		code: "174",
		bankName: "PEFISA S.A. – CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
		ispb: "43180355"
	},
	{
		code: "354",
		bankName: "NECTON INVESTIMENTOS S.A. CORRETORA DE VALORES MOBILIÁRIOS E COMMODITIES",
		ispb: "52904364"
	},
	{
		code: "n/a",
		bankName: "BMF Bovespa S.A. – Bolsa de Valores, Mercadorias e Futuros – Camara BMFBOVESPA",
		ispb: "54641030"
	},
	{
		code: "630",
		bankName: "Banco Smartbank S.A.",
		ispb: "58497702"
	},
	{
		code: "393",
		bankName: "Banco Volkswagen S.A.",
		ispb: "59109165"
	},
	{
		code: "390",
		bankName: "BANCO GM S.A.",
		ispb: "59274605"
	},
	{
		code: "381",
		bankName: "BANCO MERCEDES-BENZ DO BRASIL S.A.",
		ispb: "60814191"
	},
	{
		code: "626",
		bankName: "BANCO C6 CONSIGNADO S.A.",
		ispb: "61348538"
	},
	{
		code: "755",
		bankName: "Bank of America Merrill Lynch Banco Múltiplo S.A.",
		ispb: "62073200"
	},
	{
		code: "089",
		bankName: "CREDISAN COOPERATIVA DE CRÉDITO",
		ispb: "62109566"
	},
	{
		code: "363",
		bankName: "SOCOPA SOCIEDADE CORRETORA PAULISTA S.A.",
		ispb: "62285390"
	},
	{
		code: "365",
		bankName: "SOLIDUS S.A. CORRETORA DE CAMBIO E VALORES MOBILIARIOS",
		ispb: "68757681"
	},
	{
		code: "281",
		bankName: "Cooperativa de Crédito Rural Coopavel",
		ispb: "76461557"
	},
	{
		code: "654",
		bankName: "BANCO DIGIMAIS S.A.",
		ispb: "92874270"
	},
	{
		code: "371",
		bankName: "WARREN CORRETORA DE VALORES MOBILIÁRIOS E CÂMBIO LTDA.",
		ispb: "92875780"
	},
	{
		code: "289",
		bankName: "DECYSEO CORRETORA DE CAMBIO LTDA.",
		ispb: "94968518"
	}
]