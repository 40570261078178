import axios from "axios"
import React, { useContext, useEffect, useState } from "react"
import { formatDate } from "../../utils/formatDate"
import { formatDateTime } from "../../utils/formatDateTime"
import './style.css'
import Header from "../../components/header"
import Logonyc from '../../assets/logoNYC/logo_nycbank_black.svg'
import { AuthContext } from "../../contexts/auth-context/auth"
import { useNavigate } from "react-router"
import Loading from "../../components/loading"
import Input from "../../components/inputs/defaultInput"
import Select from "../../components/inputs/selectInput"
import Button from "../../components/buttons/primaryButton"
import ButtonIcon from "../../components/buttons/iconButton"
import RightArrow from '../../assets/icons/right-arrow.svg';
import LeftArrow from '../../assets/icons/left-arrow.svg';
import { Slide, ToastContainer, toast } from "react-toastify"


interface ExtractProps {
	value: number
	type: string
	balance: number
	reasonDescription: string
	performedIn: Date
	payerName: string
}



const ExtractNyc = () => {

	const [data, setData] = useState<ExtractProps[]>([])
	const [dataFiltered, setDataFiltered] = useState<ExtractProps[]>([])
	const [currentFilters, setCurrentFilters] = useState<{ [key: string]: string }>({});
	const [loading, setLoading] = useState(true);
	const [slicePagination, setSlicePagination] = useState([0, 10]);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [visibleList, setVisibleList] = useState(false)
	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState('')

	const navigate = useNavigate();

	const { auth, setAuth } = useContext(AuthContext)
	useEffect(() => {

		if (auth === null || auth === 'undefined' || auth === undefined || auth === '') {
			navigate('/signin')
		}
	}, [])

	useEffect(() => {
		axios.get('https://nyctbanksservice.azurewebsites.net/api/v2/TBanks/Account/GetMovements').then((res) => {
			setData(res.data.data)
			setLoading(false)

		})
	}, [])



	const filter = (value: string, filterBy: string) => {
		setCurrentFilters((prevFilters) => ({ ...prevFilters, [filterBy]: value }));
	};


	useEffect(() => {
		// Clona os dados originais para evitar mutação direta no estado
		let filteredData = data;

		// Itera sobre cada filtro no objeto currentFilters
		for (const filterBy in currentFilters) {
			// Extrai o valor do filtro
			let filterValue = currentFilters[filterBy];

			// Verifica se um valor de filtro está presente
			if (filterValue) {
				// Verifica se o filtro está relacionado a campos de data (paymentDate ou payedAt)
				if (filterBy === "performedIn") {
					// Analisa o valor do filtro como uma data
					const dataFiltro = new Date(filterValue);
					const ano = dataFiltro.getFullYear().toString().length;

					// Verifica se a data analisada possui um ano válido (4 dígitos)
					if (ano === 4) {
						// Aplica os filtros de data
						filteredData = filteredData.filter((data) => {
							const itemValue = data[filterBy as keyof ExtractProps];

							// Ignora valores nulos
							if (itemValue === null) {
								return false;
							}

							// Formata a data do filtro para comparação
							if (filterBy === "performedIn") {
								const dateCompare = new Date(filterValue);
								const formatedDateCompare = dateCompare.toISOString().replace("T00:00:00.000Z", " ").trim();

								// Verifica se a data do item inclui a data do filtro formatada
								return itemValue.toString().includes(formatedDateCompare);
							}
						});
					}
				} else {
					// Aplica filtros não relacionados a datas

					// Verifica se o valor do filtro é "default"
					if (filterValue === "default") {
						// Redefine os dados filtrados para os dados originais da API
						setDataFiltered(data);
					} else {



						if (filterBy == 'amountParcel') {
							if (filterValue.endsWith('00')) {
								const newValue = filterValue.replace('R$ ', '').replaceAll('.', '').replace(',', '.').split('.')
								let x = newValue[1] == '00' ? newValue[0] : `${newValue[0]},${newValue[1]}`

								filteredData = filteredData.filter((data) =>
									data[filterBy as keyof ExtractProps]
										.toString()
										.toLowerCase()
										.includes(x.toLowerCase())
								)

							} else if (filterValue.endsWith('0')) {
								const newValue = filterValue.replace('R$ ', '').replaceAll('.', '').replace(',', '.').split('')
								newValue.pop()

								filterValue = newValue.join("")

								filteredData = filteredData.filter((data) =>
									data[filterBy as keyof ExtractProps]
										.toString()
										.toLowerCase()
										.includes(filterValue.toLowerCase())
								)
							}
						} else {
							filteredData = filteredData.filter((data) =>
								data[filterBy as keyof ExtractProps]
									.toString()
									.toLowerCase()
									.includes(filterValue.toLowerCase())
							)
						}
					}
				}
			}
		}

		// Atualiza o estado com os dados filtrados
		setDataFiltered(filteredData);
	}, [data, currentFilters]);

	const extractPerPeriod = () => {
		setLoading(true)
		if (new Date(startDate) < new Date(endDate)) {
			axios.post(`https://nyctbanksservice.azurewebsites.net/api/v2/TBanks/Account/GetMovements-period`, {
				startDate: new Date(startDate).toISOString(),
				endDate: new Date(endDate).toISOString()
			}).then((res) => {
				setDataFiltered(res.data.data)
				setVisibleList(true)
				setLoading(false)
			})
		} else {
			toast.error('A data de íncio não pode ser maior que a data final', {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				rtl: false,
				pauseOnFocusLoss: true,
				draggable: true,
				pauseOnHover: true,
				theme: "light"
			});
		}
	}

	const totalPages = Math.ceil(dataFiltered.length / itemsPerPage);
	const maxButtonsPerPage = 5; // Número máximo de botões de página visíveis de cada vez
	const [currentPage, setCurrentPage] = useState(1);
	const [visibleButtons, setVisibleButtons] = useState([...Array(maxButtonsPerPage).keys()].map(i => i + 1));


	//Set gradual visibility of paginations buttons
	const updatePagination = (page: number) => {

		setCurrentPage(page);
		const start = (page - 1) * itemsPerPage;
		const end = start + itemsPerPage;
		setSlicePagination([start, end]);

		// Atualizar botões visíveis com base na página atual
		const visibleButtonsCount = Math.min(maxButtonsPerPage, totalPages);
		const halfMaxButtons = Math.floor(maxButtonsPerPage / 2);
		let newVisibleButtons = [];

		if (page <= halfMaxButtons + 1) {
			// Página atual é próxima ao início, exiba os primeiros botões
			newVisibleButtons = [...Array(visibleButtonsCount).keys()].map(i => i + 1);
		} else if (page >= totalPages - halfMaxButtons) {
			// Página atual é próxima ao final, exiba os últimos botões
			newVisibleButtons = [...Array(visibleButtonsCount).keys()].map(i => i + totalPages - visibleButtonsCount + 1);
		} else {
			// Página atual está no meio, exiba os botões em torno da página atual
			newVisibleButtons = [...Array(visibleButtonsCount).keys()].map(i => i + page - halfMaxButtons);
		}

		setVisibleButtons(newVisibleButtons);
	};


	let limitedApiData = dataFiltered.slice(slicePagination[0], slicePagination[1]);

	// Pagination buttons generation
	const renderPaginationButtons = () => {
		const buttons = [];
		for (const page of visibleButtons) {
			if (page <= totalPages) {
				buttons.push(
					<Button
						key={page}
						onClick={() => goToPage(page)}
						className={`${currentPage === page ? "currentPage" : ''} small`}
						textButton={page}
					/>
				);
			}
		}
		return buttons;
	};

	// pass pages, next o
	const goToPage = (page: number) => {
		if (page === 1) {
			// Ir para a primeira página
			updatePagination(1);
		} else if (page === totalPages) {
			// Ir para a última página
			updatePagination(totalPages);
		} else {
			// Outras páginas
			setCurrentPage(page);
			const start = (page - 1) * itemsPerPage;
			const end = start + itemsPerPage;
			setSlicePagination([start, end]);

			// Atualizar botões visíveis com base na página atual
			const visibleButtonsCount = Math.min(maxButtonsPerPage, totalPages);
			if (page <= totalPages - visibleButtonsCount + 1) {
				setVisibleButtons([...Array(visibleButtonsCount).keys()].map(i => i + page));
			} else {
				setVisibleButtons([...Array(totalPages - page + 1).keys()].map(i => i + page));
			}
		}
	};

	return (
		<>
			<Header />
			{!loading ?
				<div style={{ width: '100%' }}>
					<div className="container-title">
						{/* <img width={160} src={Logonyc.toString()} /> */}
						<h1>Extrato NYC Bank</h1>
						<span className="actual-value">Saldo atual: {data[0] ? data[0].balance.toLocaleString(
							"pt-BR",
							{
								style: "currency",
								currency: "BRL",
							}) : ''}
						</span>
					</div>

					<div className="container-filters">
						{visibleList ?
							<>
								<div className="filters">
									<Input placeholder='Data Pagamento' type='date' onChange={(e) => setStartDate(e.target.value)} />
									<Input placeholder='Data Pagamento' type='date' onChange={(e) => setEndDate(e.target.value)} />
									<Button textButton={'Pesquisar'} onClick={() => extractPerPeriod()} />
								</div>
								<div className="filters">

									<Select className="selectFilter" onChange={(e) => filter(e.target.value, 'type')}>
										<option value="default">Todos</option>
										<option value="DEBIT">Débito</option>
										<option value="CREDIT">Crédito</option>
									</Select>

									<Input placeholder='Tipo' type='text' onChange={(e) => filter(e.target.value, 'reasonDescription')} />
									<Input placeholder='Nome' type='text' onChange={(e) => filter(e.target.value, 'payerName')} />
									{/* <Input type='text' onChange={(e) => filter(e.target.value, 'payerName')} /> */}
									<Input placeholder='Data Pagamento' type='date' onChange={(e) => filter(e.target.value, 'performedIn')} />

								</div>
							</>

							:
							<div className="filters">
								<Input placeholder='Data Pagamento' type='date' onChange={(e) => setStartDate(e.target.value)} />
								<Input placeholder='Data Pagamento' type='date' onChange={(e) => setEndDate(e.target.value)} />
								<Button textButton={'Pesquisar'} onClick={() => extractPerPeriod()} />
							</div>
						}
					</div>

					{visibleList &&
						<>
							<div className="container-card">

								{
									limitedApiData.map((item, index) => (
										<div className={`card ${item.type == 'DEBIT' ? 'debit' : 'credit'} ${index == 0 ? 'first' : index == limitedApiData.length - 1 ? 'last' : ''}`} key={index}>
											<div>
												<h2 className={`${item.type == 'DEBIT' ? 'red' : 'green'}`}>{item.type == 'DEBIT' ? 'Débito' : 'Crédito'}</h2>
												<p className="gray">{item.reasonDescription}</p>

												<p className="valor">{item.value.toLocaleString(
													"pt-BR",
													{
														style: "currency",
														currency: "BRL",
													})}</p>
												<p className="date">{item.payerName}</p>
												<p className="date">{formatDateTime(item.performedIn)}</p>
											</div>
										</div>
									))
								}
							</div>

							<div className="containerButtonsPagination">
								{
									slicePagination[0] == 0 ?
										<ButtonIcon disabled icon={LeftArrow.toString()} alt="Ícone página anterior" onClick={() => goToPage(currentPage - 1)} />
										:
										<ButtonIcon icon={LeftArrow.toString()} alt="Ícone página anterior" onClick={() => goToPage(currentPage - 1)} />
								}

								<Button onClick={() => goToPage(1)} className={`${currentPage === 1 ? "none" : "flex"} small`} textButton={1} />

								{renderPaginationButtons()}

								<Button onClick={() => goToPage(totalPages)} className={`${currentPage === totalPages ? "none" : "flex"} small`}
									textButton={totalPages}
								/>

								<ButtonIcon onClick={() => goToPage(currentPage + 1)} icon={RightArrow.toString()} alt='Ícone próxima página' />
							</div>
							<ToastContainer transition={Slide} />
						</>
					}
				</div>
				:
				<div className="loading-page-loan">
					<p>Carregando...</p>
					<Loading />
				</div>
			}
		</>
	)
}

export default ExtractNyc