import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/header";
import axios from "axios";
import { useNavigate } from "react-router";
import { AuthContext } from "../../contexts/auth-context/auth";
import './style.css'
import { Link } from "react-router-dom";
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from "../../components/loading";
import Button from "../../components/buttons/primaryButton";
import ButtonIcon from "../../components/buttons/iconButton";
import barCode from "../../assets/icons/bar-code.svg";
import doc from "../../assets/icons/document-svgrepo-com-nyc.svg";
import { checkLoans } from "../../utils/check";

interface Customer {
	name: string;
	cep: string;
	cellphone: string;
	email: string;
	cpf: string;
	customerProposal: CustomerProposal;
	isSigned: boolean;
	link: string | null;
	id: number
}

interface CustomerProposal {
	customer: any;
	customerID: number;
	controlId: string;
	proposalId: string | null;
	situation: string | null;
	id: number;
	createdAt: string;
	updatedAt: string | null;
	deletedAt: string | null;
}

export default function RegisteredPerson() {
	const navigate = useNavigate();

	const { auth, setAuth } = useContext(AuthContext);
	useEffect(() => {

		if (auth === null || auth === 'undefined' || auth === undefined || auth === '') {
			navigate('/signin')
		}
	})

	const [data, setData] = useState<Customer[]>([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		axios.get('https://nycloancartosservice.azurewebsites.net/api/v2/GetCustomerInfo/getAllCustomersInfo', {
			headers: {
				Authorization: `Bearer ${auth}`
			}
		}).then((res) => {
			// console.log(res.data.customerInfo);
			setData(res.data.customerInfo);
			setLoading(false);
		});
	}, []);

	const copyLink = (link: string) => {
		if (link != null) {

			navigator.clipboard.writeText(link)

			toast.info('Link copiado para a área de transferência', {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				rtl: false,
				pauseOnFocusLoss: true,
				draggable: true,
				pauseOnHover: true,
				theme: "light"
			});
		} else {
			toast.error('Proposta Expirada', {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				rtl: false,
				pauseOnFocusLoss: true,
				draggable: true,
				pauseOnHover: true,
				theme: "light"
			});
		}
	};

	const downloadAgreement = (cpf: string) => {
		axios.get(`https://nycloancartosservice.azurewebsites.net/api/v2/GetCustomerInfo/getContract/?cpf=${cpf}`, {
			headers: {
				Authorization: `Bearer ${auth}`
			}
		}).then((res) => {
			if (res.data.customerProposal.response.downloads != null) {

				window.open(res.data.customerProposal.response.downloads[0].url)
				window.open(res.data.customerProposal.response.downloads[1].url)
			} else {
				toast.error('Necessita de assinatura', {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 5000,
					hideProgressBar: true,
					closeOnClick: true,
					rtl: false,
					pauseOnFocusLoss: true,
					draggable: true,
					pauseOnHover: true,
					theme: "light"
				});
			}
		}).catch((e) => {
			toast.error('Cliente não possui proposta', {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				rtl: false,
				pauseOnFocusLoss: true,
				draggable: true,
				pauseOnHover: true,
				theme: "light"
			});
		})
	};

	const setCheckCpf = async (cpf: string) => {

		const req = await checkLoans(cpf)
		sessionStorage.setItem('cpf', cpf)
		switch (req?.status) {
			case 200:
				sessionStorage.setItem('cpf', cpf)
				navigate('/loans/qrcode-list')
				break;
			case 404:
				toast.error('CPF não possui empréstimos', {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					rtl: false,
					pauseOnFocusLoss: true,
					draggable: true,
					pauseOnHover: true,
					theme: "light"
				});
				break;
			default:
				toast.error('Aconteceu um erro inesperado, tente novamente mais tarde', {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					rtl: false,
					pauseOnFocusLoss: true,
					draggable: true,
					pauseOnHover: true,
					theme: "light"
				});
				break;

		}
	}

	return (
		<>
			<Header />
			{!loading ?
				<div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
					<nav className="navContainer">
						{/* <Link to="/loans/register-cpf">Cadastrar Cliente</Link> */}
						<Button onClick={() => navigate("/loans/register-loan")} textButton="Gerar Nova Proposta" />
						<Button onClick={() => navigate("/loans/check-cpf")} textButton="Consultar CPF" />
						<Button onClick={() => navigate("/loans/register-cpf")} textButton="Cadastrar CPF" />
					</nav>
					<div className="tableContainer">
						<table className="table">
							<thead className="tableHeader">
								<tr>
									<th>Nome</th>
									<th>CPF</th>
									<th>CEP</th>
									<th>Contato</th>
									<th>E-mail</th>
									<th>Status de Proposta</th>
									<th>Ações</th>
								</tr>
							</thead>
							<tbody className="tableBody">
								{data.map((item, index) => (
									!item.isSigned && item.customerProposal == null && item.link == null ?
										''
										:
										<tr id='linhas' className={item.customerProposal.id % 2 === 0 ? `lightGreen ${index}` : `darkGreen ${index}`} key={index}>
											<td>{item.name}</td>
											<td>{item.cpf}</td>
											<td>{item.cep}</td>
											<td>{item.cellphone}</td>
											<td>{item.email}</td>

											{item.isSigned ? <td>Assinada</td> : !item.isSigned && item.customerProposal == null && item.link == null ? <td>Não possui</td> : <td className="link" onClick={() => copyLink(item.link || '')}>Pendente de Assinatura</td>}


											<td className="link" style={{ display: 'flex' }}>
												<ButtonIcon icon={barCode.toString()} alt="consulta boleto" onClick={() => setCheckCpf(item.cpf)} />
												&nbsp;
												<ButtonIcon icon={doc.toString()} alt="contrato" onClick={() => downloadAgreement(item.cpf)} />
											</td>
										</tr>

								))}
							</tbody>
						</table>
					</div>
					<ToastContainer transition={Slide} />
				</div>
				:
				<div className="loading-page-loan">
					<p>Carregando...</p>
					<Loading />
				</div>
			}
		</>
	);
}
