import * as XLSX from 'xlsx';
import { formatDate } from './formatDate';
import { formatDateTime } from './formatDateTime';

interface ApiDataItem {
	name: string;
	product: string;
	amountParcel: string;
	isPayed: Boolean;
	parcel: string;
	paymentDate: Date;
	payedAt: Date;
}
export const exportExcel = (data: ApiDataItem[]) => {

	const formatedData = data.map((item, index) => {
		return {
			name: item.name,
			product: item.product,
			amountParcel: `R$ ${item.amountParcel}`,
			isPayed: item.isPayed ? 'Pago' : 'Pendente',
			parcel: item.parcel,
			paymentDate: formatDate(item.paymentDate),
			payedAt: formatDateTime(item.payedAt)
		}
	})

	const ws = XLSX.utils.json_to_sheet(formatedData);
	const wb = XLSX.utils.book_new();

	XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
	const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
	const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
	const url = URL.createObjectURL(blob);
	const a = document.createElement('a');
	a.href = url;
	a.download = 'Reports.xlsx';
	a.click();
	URL.revokeObjectURL(url);
}