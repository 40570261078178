'use client'
import './style.css'
import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
// import VMasker from 'vanilla-masker';

//components
import Button from '../../components/buttons/primaryButton';
import ButtonIcon from '../../components/buttons/iconButton';
import Select from '../../components/inputs/selectInput';
import Input from '../../components/inputs/defaultInput';

//icons
import RightArrow from '../../assets/icons/right-arrow.svg';
import LeftArrow from '../../assets/icons/left-arrow.svg';
import barCode from "../../assets/icons/bar-code.svg";
//utils
import { formatDate } from '../../utils/formatDate';
import { formatDateTime } from '../../utils/formatDateTime';
import { exportExcel } from '../../utils/ExportExcel'
import Header from '../../components/header';
import axios from 'axios';

import { ToastContainer, toast, Bounce, Slide, Flip, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MaskInput from '../../components/inputs/maskedInput';
import React from 'react';
import { AuthContext } from '../../contexts/auth-context/auth';
import Loading from '../../components/loading';



//inteface
interface ApiDataItem {
	id: number;
	name: string;
	product: string;
	amountParcel: string;
	isPayed: Boolean;
	parcel: string;
	paymentDate: Date;
	payedAt: Date;
}



export default function Report() {
	const navigate = useNavigate()
	const { auth, setAuth } = useContext(AuthContext)
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		// console.log(auth)
		if (auth === null || auth === 'undefined' || auth === undefined || auth === '') {
			navigate('/signin')
		}
	}, [auth])

	//States
	const [apiData, setApiData] = useState<ApiDataItem[]>([]);
	const [apiDataFiltered, setApiDataFiltered] = useState<ApiDataItem[]>([]);
	const [currentFilters, setCurrentFilters] = useState<{ [key: string]: string }>({});
	const [slicePagination, setSlicePagination] = useState([0, 10]);
	const [itemsPerPage, setItemsPerPage] = useState(12);
	const [totalAmountParcel, setTotalAmountParcel] = useState<number>(0);
	const [totalPendentParcel, settotalPendentParcel] = useState<number>(0);
	const [totalPayedParcel, settotalPayedParcel] = useState<number>(0);
	const [maskedValue, setMaskedValue] = useState('');

	const currentDate = new Date();
	useEffect(() => {
		// Calculate o montante quando apiDataFiltered é alterada
		const sumAmountParcel = apiDataFiltered.reduce((total, item) => {
			//Converte e formata o montante quando apiDataFiltered é alterada
			return total + parseFloat(item.amountParcel.toString().replace('R$ ', '').replace(',', ''));
		}, 0);

		// Atualiza o state com o mnontante das parcelas 
		setTotalAmountParcel(sumAmountParcel);
	}, [apiDataFiltered]);


	useEffect(() => {
		// Calcula os resultados sempre que apiDatFiltered for alterada no caso, filtrado
		const { paid, pending, late } = apiDataFiltered.reduce(
			(acc, item) => {
				if (item.isPayed) {
					acc.paid += 1;
				} else {
					acc.pending += 1;
				}


				if (item.paymentDate.toString() < currentDate.toISOString() && !item.isPayed) {
					acc.late += 1
				}

				return acc;
			},
			{ paid: 0, pending: 0, late: 0 }
		);

		settotalPendentParcel(pending);
		settotalPayedParcel(paid);

	}, [apiDataFiltered]);

	// Função do botão de exibir mais resultados
	const showMoreItems = (qnt: number) => {
		setSlicePagination([0, qnt]);

		setItemsPerPage(qnt);
	}

	const totalPages = Math.ceil(apiDataFiltered.length / itemsPerPage);
	const maxButtonsPerPage = 5; // Número máximo de botões de página visíveis de cada vez
	const [currentPage, setCurrentPage] = useState(1);
	const [visibleButtons, setVisibleButtons] = useState([...Array(maxButtonsPerPage).keys()].map(i => i + 1));
	// let x = window.location.href.split('http');
	// window.history.pushState(null, '', x[x.length - 1].split('/')[2]);
	//Set gradual visibility of paginations buttons
	const updatePagination = (page: number) => {

		setCurrentPage(page);
		const start = (page - 1) * itemsPerPage;
		const end = start + itemsPerPage;
		setSlicePagination([start, end]);

		// Atualizar botões visíveis com base na página atual
		const visibleButtonsCount = Math.min(maxButtonsPerPage, totalPages);
		const halfMaxButtons = Math.floor(maxButtonsPerPage / 2);
		let newVisibleButtons = [];

		if (page <= halfMaxButtons + 1) {
			// Página atual é próxima ao início, exiba os primeiros botões
			newVisibleButtons = [...Array(visibleButtonsCount).keys()].map(i => i + 1);
		} else if (page >= totalPages - halfMaxButtons) {
			// Página atual é próxima ao final, exiba os últimos botões
			newVisibleButtons = [...Array(visibleButtonsCount).keys()].map(i => i + totalPages - visibleButtonsCount + 1);
		} else {
			// Página atual está no meio, exiba os botões em torno da página atual
			newVisibleButtons = [...Array(visibleButtonsCount).keys()].map(i => i + page - halfMaxButtons);
		}

		setVisibleButtons(newVisibleButtons);
	};

	// Pagination buttons generation
	const renderPaginationButtons = () => {
		const buttons = [];
		for (const page of visibleButtons) {
			if (page <= totalPages) {
				buttons.push(
					<Button
						key={page}
						onClick={() => goToPage(page)}
						className={`${currentPage === page ? "currentPage" : ''} small`}
						textButton={page}
					/>
				);
			}
		}
		return buttons;
	};

	// pass pages, next o
	const goToPage = (page: number) => {
		if (page === 1) {
			// Ir para a primeira página
			updatePagination(1);
		} else if (page === totalPages) {
			// Ir para a última página
			updatePagination(totalPages);
		} else {
			// Outras páginas
			setCurrentPage(page);
			const start = (page - 1) * itemsPerPage;
			const end = start + itemsPerPage;
			setSlicePagination([start, end]);

			// Atualizar botões visíveis com base na página atual
			const visibleButtonsCount = Math.min(maxButtonsPerPage, totalPages);
			if (page <= totalPages - visibleButtonsCount + 1) {
				setVisibleButtons([...Array(visibleButtonsCount).keys()].map(i => i + page));
			} else {
				setVisibleButtons([...Array(totalPages - page + 1).keys()].map(i => i + page));
			}
		}
	};

	useEffect(() => {
		const url = 'https://nycreportservice.azurewebsites.net/api/dash/QrCodeLoan/getall';

		axios.get(url, {
			headers: {
				Authorization: `Bearer ${auth}`
			}
		})
			.then((res) => {
				if (!res.status) {
					throw new Error(`A solicitação falhou com status: ${res.status}`);
				}

				return res.data;
			})
			.catch((e) => {
				console.log(e)
				// if (e.response.status == 401) {
				// 	navigate('/signin')
				// }
			})
			.then((data) => {
				// console.log(data)
				setApiData(data.qrCode);
				setLoading(false)
				setAuth(data.token)
			})
	}, []);

	//Armazena 15 resultados, de acordo com o state de slipaginationo para gerar paginação
	const limitedApiData = apiDataFiltered.slice(slicePagination[0], slicePagination[1]);


	// Aplica os filtros
	const filter = (value: string, filterBy: string) => {
		setCurrentFilters((prevFilters) => ({ ...prevFilters, [filterBy]: value }));
	};

	useEffect(() => {
		// Clona os dados originais para evitar mutação direta no estado
		let filteredData = apiData;

		// Itera sobre cada filtro no objeto currentFilters
		for (const filterBy in currentFilters) {
			// Extrai o valor do filtro
			let filterValue = currentFilters[filterBy];

			// Verifica se um valor de filtro está presente
			if (filterValue) {
				// Verifica se o filtro está relacionado a campos de data (paymentDate ou payedAt)
				if (filterBy === "paymentDate" || filterBy === "payedAt") {
					// Analisa o valor do filtro como uma data
					const dataFiltro = new Date(filterValue);
					const ano = dataFiltro.getFullYear().toString().length;

					// Verifica se a data analisada possui um ano válido (4 dígitos)
					if (ano === 4) {
						// Aplica os filtros de data
						filteredData = filteredData.filter((data) => {
							const itemValue = data[filterBy as keyof ApiDataItem];

							// Ignora valores nulos
							if (itemValue === null) {
								return false;
							}

							// Formata a data do filtro para comparação
							if (filterBy === "paymentDate") {
								const dateCompare = new Date(filterValue);
								const formatedDateCompare = dateCompare.toISOString().replace(".000Z", " ").trim();

								// Verifica se a data do item inclui a data do filtro formatada
								return itemValue.toString().includes(formatedDateCompare);
							} else if (filterBy === "payedAt") {
								const dateCompare = new Date(filterValue);
								const formatedDateCompare = dateCompare.toISOString().replace("T00:00:00.000Z", " ").trim();

								// Verifica se a data do item inclui a data do filtro formatada
								return itemValue.toString().includes(formatedDateCompare);
							}
						});
					}
				} else {
					// Aplica filtros não relacionados a datas

					// Verifica se o valor do filtro é "default"
					if (filterValue === "default") {
						// Redefine os dados filtrados para os dados originais da API
						setApiDataFiltered(apiData);
					} else {



						if (filterBy == 'amountParcel') {
							if (filterValue.endsWith('00')) {
								const newValue = filterValue.replace('R$ ', '').replaceAll('.', '').replace(',', '.').split('.')
								let x = newValue[1] == '00' ? newValue[0] : `${newValue[0]},${newValue[1]}`

								filteredData = filteredData.filter((data) =>
									data[filterBy as keyof ApiDataItem]
										.toString()
										.toLowerCase()
										.includes(x.toLowerCase())
								)

							} else if (filterValue.endsWith('0')) {
								const newValue = filterValue.replace('R$ ', '').replaceAll('.', '').replace(',', '.').split('')
								newValue.pop()

								filterValue = newValue.join("")

								filteredData = filteredData.filter((data) =>
									data[filterBy as keyof ApiDataItem]
										.toString()
										.toLowerCase()
										.includes(filterValue.toLowerCase())
								)
							}
						} else {
							filteredData = filteredData.filter((data) =>
								data[filterBy as keyof ApiDataItem]
									.toString()
									.toLowerCase()
									.includes(filterValue.toLowerCase())
							)
						}
					}
				}
			}
		}

		// Atualiza o estado com os dados filtrados
		setApiDataFiltered(filteredData);
	}, [apiData, currentFilters]);

	const showTotal = (id: number) => {
		// console.log(id)

		axios.get(`https://nycreportservice.azurewebsites.net/api/dash/QrCodeLoan/gettotalaamount/${id}`,
			{
				headers: {
					Authorization: `Bearer ${auth}`
				}
			}).then((res) => {


				if (res.status == 200) {
					const valorPago = res.data.totalAmount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
					toast.info(`Valor Pago: ${valorPago}`, {
						position: toast.POSITION.TOP_CENTER,
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						rtl: false,
						pauseOnFocusLoss: true,
						draggable: true,
						pauseOnHover: true,
						theme: "light"
					})
				}
			})
			.catch((e) => {
				const valorNulo = e.response.data.totalAmount
				toast.info(`Valor Pago: R$${valorNulo}`, {
					position: toast.POSITION.TOP_CENTER,
					autoClose: false,
					hideProgressBar: false,
					closeOnClick: true,
					rtl: false,
					pauseOnFocusLoss: true,
					draggable: true,
					pauseOnHover: true,
					theme: "light"
				})
			})

	}

	return (
		<>
			<Header />
			{!loading ?

				<main className="main">
					<div className="containerFunctions">
						<div className='containerSelect'>
							<p>Exibir</p>
							<Select className="selectShow" onChange={((e) => showMoreItems(parseInt(e.target.value)))}>
								<option value="10">10</option>
								<option value="25">25</option>
								<option value="50">50</option>
								<option value="100">100</option>
								<option value={apiData.length}>Todos os registros</option>
							</Select>
							<p>resultados por página</p>
						</div>
						<p><b>Pendentes:</b> {totalPendentParcel}</p>
						<p><b>Pagos:</b> {totalPayedParcel}</p>
						<p><b>Total a receber:</b> R$ {totalAmountParcel.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
						< Button onClick={() => exportExcel(apiDataFiltered)} className='buttonGenerate' textButton="Gerar planilha" />
					</div>
					<table className="table">
						<tfoot>
							<tr className="filtersReport">
								<th className='name'>
									<Input placeholder='Nome' type='text' onChange={(e) => filter(e.target.value, 'name')} />
								</th>
								<th className='product'>
									<Input placeholder='Produto' type='text' onChange={(e) => filter(e.target.value, 'product')} />
								</th>
								<th className='value'>
									<Input placeholder='Valor' type='text' value={`R$ ${maskedValue}`} onChange={(e) => {
										const value = e.target.value // Remove caracteres não numéricos
										const formattedValue = '10.02';
										setMaskedValue(formattedValue);
										filter(formattedValue, 'amountParcel');
									}} />
								</th>
								<th className='situation'>
									<Select className="selectFilter" onChange={(e) => filter(e.target.value, 'isPayed')}>
										<option value="default">Situação</option>
										<option value="true">Pago</option>
										<option value="false">Pendente</option>
									</Select>
								</th>
								<th className='parcel'>
									<Input placeholder='Nº da Parcela' type='text' onChange={(e) => filter(e.target.value, 'parcel')} />
								</th>
								<th>
									<Input placeholder='Data Vencimento' type='date' onChange={(e) => filter(e.target.value, 'paymentDate')} />
								</th>
								<th>
									<Input placeholder='Data Pagamento' type='date' onChange={(e) => filter(e.target.value, 'payedAt')} />
								</th>
								<th>
								</th>
							</tr>
						</tfoot>
					</table>
					<div className="tableReportContainer">
						<table className="table">
							<thead className="tableHeader">
								<tr>
									<th>Nome</th>
									<th>Produto</th>
									<th>Valor</th>
									<th>Situação</th>
									<th>Nº da Parcela</th>
									<th>Data Vencimento</th>
									<th>Data Pagamento</th>
									<th></th>
								</tr>
							</thead>
							<tbody className="tableBody">
								{limitedApiData.map((item, index) => (
									<tr id='linhas' className={index % 2 === 0 ? "lightGreen" : "darkGreen"} key={index}>
										<td className='nameFunc' onClick={() => showTotal(item.id)} >{item.name}</td>
										<td>{item.product}</td>
										<td>{parseFloat(item.amountParcel).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
										<td>{item.isPayed ? 'Pago' : 'Pendente'}</td>
										<td>{item.parcel}</td>
										<td>{formatDate(item.paymentDate)}</td>
										<td>{formatDateTime(item.payedAt)}</td>
										{!item.isPayed ?
											<td style={{ width: '50px' }}><ButtonIcon icon={barCode.toString()} alt="consulta boleto" onClick={() => { sessionStorage.setItem('otherDb', 'true'); navigate(`/loans/pdf-generator/?parcel_ID=${item.id}`) }} /></td>
											:
											<td></td>
										}
									</tr>
								))}
							</tbody>
						</table>
					</div>


					<div className="containerButtonsPagination">
						{
							slicePagination[0] == 0 ?
								<ButtonIcon disabled icon={LeftArrow.toString()} alt="Ícone página anterior" onClick={() => goToPage(currentPage - 1)} />
								:
								<ButtonIcon icon={LeftArrow.toString()} alt="Ícone página anterior" onClick={() => goToPage(currentPage - 1)} />
						}

						<Button onClick={() => goToPage(1)} className={`${currentPage === 1 ? "none" : "flex"} small`} textButton={1} />

						{renderPaginationButtons()}

						<Button onClick={() => goToPage(totalPages)} className={`${currentPage === totalPages ? "none" : "flex"} small`}
							textButton={totalPages}
						/>

						<ButtonIcon onClick={() => goToPage(currentPage + 1)} icon={RightArrow.toString()} alt='Ícone próxima página' />
					</div>
					<ToastContainer transition={Slide} />
				</main>
				:
				<div className="loading-page-loan">
					<p>Carregando...</p>
					<Loading />
				</div>
			}
		</>
	)
}
