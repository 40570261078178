import Header from "../../../components/header";
import axios from "axios";
import './style.css'
import Input from "../../../components/inputs/defaultInput";
import Logonyc from '../../../assets/logoNYC/logo_nycbank_black.svg'
import { useContext, useEffect, useState } from "react";
import MaskInput from "../../../components/inputs/maskedInput";
import Select from "../../../components/inputs/selectInput";
import Button from "../../../components/buttons/primaryButton";
import CryptoNyc from '../../../utils/decrypto-nyc'

import { ToastContainer, toast, Bounce, Slide, Flip, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../contexts/auth-context/auth";
import Loading from "../../../components/loading";

export default function RegisterLoan() {
	const [date, setDate] = useState('');
	const [cpf, setCPF] = useState('')
	const [valor, setValor] = useState('')
	const [parcelas, setParcelas] = useState('')
	const [loading, setLoading] = useState(true)

	const cpfPerson = typeof window !== 'undefined' ? sessionStorage.getItem('cpfLoan') : null;

	const currentDate = new Date();
	const formatDate = new Date(date);
	const creditDate = currentDate.toLocaleDateString()

	useEffect(() => {
		if (cpfPerson) {
			setCPF(cpfPerson)
		}
	})

	const navigate = useNavigate()
	const { auth, setAuth } = useContext(AuthContext)


	const generateLoan = () => {
		setLoading(false)
		const newCurrentDate = currentDate.toISOString()
		const newDate = formatDate.toISOString()
		// console.log(creditDate)
		const ano = currentDate.getFullYear();
		const mes = (currentDate.getMonth() + 1).toString().padStart(2, '0');
		const dia = currentDate.getDate().toString().padStart(2, '0');
		const dataFormatada = `${ano}-${mes}-${dia}`;
		const cpfFormat = cpf.replaceAll('-', '').replaceAll('.', '')

		if (newCurrentDate < newDate) {
			const position = date.split('-')
			axios.post('https://nycloancartosservice.azurewebsites.net/api/v2/Proposal/generate-proposal', {
				"creditDate": dataFormatada,
				"firstDueDate": `${position[0]}-${position[1]}-${position[2]}`,
				"cpf": cpfFormat,
				"amount": valor,
				"numberOfInstalments": parcelas
			}, {
				headers: {
					Authorization: `Bearer ${auth}`
				}
			}).then((res) => {
				// console.log(res)
				axios.post('https://nycloancartosservice.azurewebsites.net/api/v2/Proposal/register-proposal',
					{ cpf: cpfFormat }
					, {
						headers: {
							Authorization: `Bearer ${auth}`,
							'Content-Type': 'application/json'
						}
					}).then((res) => {
						sessionStorage.setItem('proposalId', res.data.proposalId
						)

						axios.post('https://nycloancartosservice.azurewebsites.net/api/v2/Proposal/validate-proposal',
							{ cpf: cpfFormat }
							, {
								headers: {
									Authorization: `Bearer ${auth}`,
									'Content-Type': 'application/json'
								}
							}).then((res) => {
								setLoading(true)
								toast.success('Proposta cadastrada com sucesso', {
									position: toast.POSITION.TOP_RIGHT,
									autoClose: 5000,
									hideProgressBar: false,
									closeOnClick: true,
									rtl: false,
									pauseOnFocusLoss: true,
									draggable: true,
									pauseOnHover: true,
									theme: "light",
								});
								setTimeout(() => {
									navigate("/loans")
								}, 5000);
							}).catch((e) => {
								// console.log('erro maldito')
								setLoading(true)
								toast.error('Algo deu errado, tente novamente', {
									position: toast.POSITION.TOP_RIGHT,
									autoClose: 5000,
									hideProgressBar: false,
									closeOnClick: true,
									rtl: false,
									pauseOnFocusLoss: true,
									draggable: true,
									pauseOnHover: true,
									theme: "light",
								});
							})

					}).catch((e) => {
						setLoading(true)
						toast.error('Algo deu errado, tente novamente', {
							position: toast.POSITION.TOP_RIGHT,
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							rtl: false,
							pauseOnFocusLoss: true,
							draggable: true,
							pauseOnHover: true,
							theme: "light",
						});
					})

			}).catch((e) => {
				setLoading(true)
				setTimeout(() => {
					toast.error('Algo deu errado, tente novamente', {
						position: toast.POSITION.TOP_RIGHT,
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						rtl: false,
						pauseOnFocusLoss: true,
						draggable: true,
						pauseOnHover: true,
						theme: "light",
					});
				}, 1000);

			})
		}

	}

	return (
		<>
			<Header />

			{loading ?
				<div className="container">
					<img src={Logonyc.toString()} className="logo" alt="logo da NYC Bank" />

					<form>
						<div className="form-grid">
							<h2>CPF</h2>
							<MaskInput mask="999.999.999-99" maskChar={null} placeholder="xxx.xxx.xxx-xx" type="text" value={cpf} onChange={(e) => setCPF(e.target.value)} />
							<h2>Escolha a data da sua primeira parcela</h2>
							<Input type="date" onChange={(e) => setDate(e.target.value)} placeholder="" />
							<h2>Valor do Emprestimo</h2>
							<Input type="number" onChange={(e) => setValor(e.target.value)} placeholder="Valor"/>
							<h2>Numero de parcelas</h2>
							<Input type="number" onChange={(e) => setParcelas(e.target.value)} placeholder="Parcelas"/>
						</div>
						<div className="button-box">
							<Button type="button" onClick={generateLoan} textButton={'Enviar'} />
						</div>
					</form>
					<ToastContainer transition={Slide} />
				</div >
				:
				<div className="loading-page-loan">
					<p>Carregando...</p>
					<Loading />
				</div>
			}
		</>
	)
}