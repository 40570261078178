import React, { useContext, useState } from "react"
import Header from "../../components/header"
import Logonyc from "../../assets/logoNYC/logo_nycbank_black.svg";
import MaskInput from "../../components/inputs/maskedInput";
import Button from "../../components/buttons/primaryButton";
import { Slide, ToastContainer } from "react-toastify";
import axios from "axios";
import { AuthContext } from "../../contexts/auth-context/auth";
import { useNavigate } from "react-router";
import './style.css'

const ConsultCpf = () => {
	const [cpf, setCpf] = useState('')
	const [name, setName] = useState('Consultar')
	const { auth } = useContext(AuthContext)
	const navigate = useNavigate()

	const consult = () => {
		setName('Aguarde ...');
		const format = cpf.replaceAll(".", "").replaceAll("-", "")
		axios.get(`https://nycserasaservice.azurewebsites.net/api/v1/consult-serasa/GenerateReportByCpf/${format}`, {
			headers: {
				Authorization: `Bearer ${auth}`
			}
		}).then((res) => {
			console.log('Gerou')
			navigate(`/consult-cpf/details/?cpf=${format}`)
		}).catch((e) => {
			if (e.response.status == 400 && e.response.data == 'Já existe uma consulta criada com menos de 30 dias!') {
				navigate(`/consult-cpf/details/?cpf=${format}`)
			}
		})
	}



	return (
		<>
			<Header />
			<div className="SearchContainer">
				<img src={Logonyc.toString()} alt="logo da NYC Bank" />
				<div className="Search">
					<MaskInput mask="999.999.999-99" maskChar={null} placeholder="CPF" type="text" onChange={(e) => setCpf(e.target.value)} />
					<Button className="Button" onClick={() => consult()} textButton={name} />
				</div>

				{/* <Link className="AccButton" to="/loans/register-cpf">Cadastrar novo CPF</Link> */}
			</div>
			<ToastContainer transition={Slide} />
		</>
	)
}

export default ConsultCpf