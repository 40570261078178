import Header from "../../../components/header";
import axios from "axios";
import './style.css'
import Input from "../../../components/inputs/defaultInput";
import Logonyc from '../../../assets/logoNYC/logo_nycbank_black.svg'
import { useContext, useEffect, useState } from "react";
import MaskInput from "../../../components/inputs/maskedInput";
import Select from "../../../components/inputs/selectInput";
import Button from "../../../components/buttons/primaryButton";
import CryptoNyc from '../../../utils/decrypto-nyc'

import { ToastContainer, toast, Bounce, Slide, Flip, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../contexts/auth-context/auth";
import Loading from "../../../components/loading";
import VMasker from "vanilla-masker";

export default function GenerateLoan() {
	const [date, setDate] = useState('');
	const [cpf, setCPF] = useState('')
	const [loading, setLoading] = useState(true)
	const [maskedValue, setMaskedValue] = useState('');

	const navigate = useNavigate()
	const { auth, setAuth } = useContext(AuthContext)


	const generateLoan = () => {
		setLoading(false)
		sessionStorage.setItem('jsonCustomSlip',
			`${JSON.stringify({
				cpfForm: cpf.replaceAll('-', '').replaceAll('.', ''),
				dateForm: new Date(date).toISOString(),
				valueForm: maskedValue.replaceAll('.', '').replaceAll(',', '.'),
				cepForm: '09531190'
			})}
	`
		)
		setLoading(true)
		sessionStorage.setItem('customSlip', 'custom')
		navigate('/loans/pdf-generator')
	}

	return (
		<>
			<Header />

			{loading ?
				<div className="container">
					<img src={Logonyc.toString()} className="logo" alt="logo da NYC Bank" />

					<form>
						<div className="form-grid">
							<h2>CPF</h2>
							<MaskInput mask="999.999.999-99" maskChar={null} placeholder="xxx.xxx.xxx-xx" type="text" value={cpf} onChange={(e) => setCPF(e.target.value)} />
							<h2>Escolha a data do vencimento</h2>
							<Input type="date" onChange={(e) => setDate(e.target.value)} />
							<h2>Escolha o valor total do boleto</h2>
							<Input placeholder='Valor' type='text' value={`R$ ${maskedValue}`} onChange={(e) => {
								const value = e.target.value // Remove caracteres não numéricos
								const formattedValue = VMasker.toMoney(value);
								setMaskedValue(formattedValue);
							}} />
						</div>
						<div className="button-box">
							<Button type="button" onClick={generateLoan} textButton={'Enviar'} />
						</div>
					</form>
					<ToastContainer transition={Slide} />
				</div >
				:
				<div className="loading-page-loan">
					<p>Carregando...</p>
					<Loading />
				</div>
			}
		</>
	)
}