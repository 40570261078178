'use client'
import React, { useContext, useEffect, useState } from 'react';
import { Bar, Doughnut } from 'react-chartjs-2';
import Button from '../../../../components/buttons/primaryButton';
import './style.css'

import {
	Chart as ChartJs,
	BarElement,
	CategoryScale,
	LinearScale,
	Tooltip,
	Legend,
	Title,
	ArcElement,
	ChartOptions
} from 'chart.js';
import Input from '../../../../components/inputs/defaultInput';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../../contexts/auth-context/auth';
import Loading from '../../../../components/loading';


ChartJs.register(
	BarElement,
	CategoryScale,
	LinearScale,
	Tooltip,
	Legend,
	Title,
	ArcElement
)

interface ApiDataItem {
	name: string;
	product: string;
	amountParcel: string;
	isPayed: Boolean;
	parcel: string;
	paymentDate: Date;
	payedAt: Date;
}

interface Delayed {
	titles: number,
	value: number
	percentage: number
}
interface Payed {
	titles: number,
	value: number
	percentage: number
}
interface Received {
	titles: number,
	value: number
	percentage: number
}

const DoughnutPropertyChart = () => {

	const [apiData, setApiData] = useState<ApiDataItem[]>([]);
	const [apiDataFiltered, setApiDataFiltered] = useState<ApiDataItem[]>([]);
	const [currentFilters, setCurrentFilters] = useState<{ [key: string]: string }>({});
	const [delayed, setDelayed] = useState<Delayed>()
	const [payed, setPayed] = useState<Payed>()
	const [received, setReceived] = useState<Received>()
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');

	const navigate = useNavigate()
	const { auth, setAuth } = useContext(AuthContext)
	const [loading, setLoading] = useState(true)

	useEffect(() => {

		if (auth === null || auth === 'undefined' || auth === undefined || auth === '') {
			navigate('/signin')
		}
	}, [])

	const currentDate = new Date();

	const handleStartDate = (value: string) => {
		const starDateInput = new Date(value)
		const yearPrevent = starDateInput.getFullYear().toString()

		if (yearPrevent.length == 4) {
			const formatedData = starDateInput.toISOString().replace(".000Z", " ").trim();
			setStartDate(formatedData)
		}
	}

	const handleEndDate = (value: string) => {
		const starDateInput = new Date(value)
		const yearPrevent = starDateInput.getFullYear().toString()

		if (yearPrevent.length == 4) {
			const formatedData = starDateInput.toISOString().replace(".000Z", " ").trim();
			setEndDate(formatedData)
		}
	}

	const filterPeriod = (sDate: string, eDate: string) => {

		const filteredData = apiData.filter((item) => {

			return new Date(item.paymentDate) >= new Date(sDate) && new Date(item.paymentDate) <= new Date(eDate);
		});

		setApiDataFiltered(filteredData);
		document.querySelector(".filter");
	};


	// useEffect(() => {
	// 	const url = 'https://nycreportservice.azurewebsites.net/api/dash/QrCodeLoan/getall';

	// 	axios.get(url, {
	// 		headers: {
	// 			Authorization: `Bearer ${auth}`
	// 		}
	// 	})
	// 		.then((res) => {
	// 			if (!res.status) {
	// 				throw new Error(`A solicitação falhou com status: ${res.status}`);
	// 			}

	// 			return res.data.qrCode;
	// 		})
	// 		.then((data) => {
	// 			setApiData(data);
	// 			setLoading(false)
	// 		})
	// 		.catch((error) => {
	// 			console.error('Ocorreu um erro:', error);
	// 		});
	// }, []);

	// useEffect(() => {
	// 	setApiDataFiltered(apiData)
	// }, [apiData])
	const right = 'right'

	useEffect(() => {
		// Calculate the sum when apiDataFiltered changes
		axios.get('https://nycreportservice.azurewebsites.net/api/dash/Loan/getdelayed ',
			{
				headers: {
					Authorization: `Bearer ${auth}`
				}
			}).then((res) => {
				setDelayed(res.data.loan)
				// console.log(res)
				setAuth(res.data.token)
			})
		axios.get('https://nycreportservice.azurewebsites.net/api/dash/Loan/gettopayed',
			{
				headers: {
					Authorization: `Bearer ${auth}`
				}
			}).then((res) => {
				setPayed(res.data.loan)
				setAuth(res.data.token)
			})
		axios.get('https://nycreportservice.azurewebsites.net/api/dash/Loan/getreceived',
			{
				headers: {
					Authorization: `Bearer ${auth}`
				}
			}).then((res) => {
				setReceived(res.data.loan)
				setAuth(res.data.token)
			})
		setLoading(false)
	}, []);
	//data

	// const percentagePendent = (totalPendentParcel / apiDataFiltered.length) * 100;
	// const percentagePayed = (totalPayedParcel / apiDataFiltered.length) * 100;
	// const percentageLate = (totalLateParcel / apiDataFiltered.length) * 100;

	const data = {
		labels: [
			`Pagos: ${received?.percentage.toFixed(2)}%`,
			`Para Receber: ${payed?.percentage && delayed?.percentage && (payed?.percentage - delayed?.percentage).toFixed(2)}%`,
			`Em Atraso: ${delayed?.percentage.toFixed(2)}%`
		],
		datasets: [
			{
				label: '',
				data: [received?.value, payed?.value, delayed?.value],
				backgroundColor: ['#07bb02', '#ffaa00', '#b20505'],
				cutout: '60%',
				rotation: -20,
				spacing: 2
			}
		]
	}

	const options: ChartOptions<'doughnut'> = {
		elements: {
			arc: {
				borderColor: ['#007402', '#c99300', '#790505'],
				borderWidth: 4,
				// offset: 24
			},
		},
		plugins: {
			title: {
				display: true,
				text: '',
			},
			legend: {
				display: true,
				position: "right",
			}
		}
	}

	//return
	return (
		<>
			{!loading ?
				<div style={{ justifySelf: 'flex-end' }}>

					<Doughnut data={data} options={options} ></Doughnut>

				</div >
				:
				<div className="loading-page-nyc-ac">
					<p>Carregando...</p>
					<Loading />
				</div>
			}
		</>
	)
}

export default DoughnutPropertyChart



