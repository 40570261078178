import React from "react";
import "./style.css";

type InputRadioProps = {
    title: string;
    name: string;
    checked?: boolean;
    onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
    id: string;
};

const InputRadio = (props: InputRadioProps) => {
    const { checked, name, title, onChange, id } = props;

    return (
        <div id="input-radio">
            <label htmlFor={id}>
                <input
                    type="radio"
                    id={id}
                    name={name}
                    checked={checked}
                    onChange={onChange}
                />
                {title}
            </label>
        </div>
    );
};

export default InputRadio;
