import ReactDOM from 'react-dom/client';
import './style.css';
import reportWebVitals from './reportWebVitals';

import Report from './pages/report';
import SignInPage from './pages/signin';
import SignUpPage from './pages/signup'
import Dashboard from './pages/dashboard';
import React, { useContext, useEffect, useState } from 'react'

import {
	createBrowserRouter,
	RouterProvider,
} from "react-router-dom";
import { ContextApp } from './contexts';
import LoanSearch from './pages/loans/check-cpf';

import QrCodePage from './pages/loans/qrcode-list';
import PdfGenerator from './pages/loans/pdf-generator';
import RegisterCpf from './pages/loans/register-cpf';
import Agreements from './pages/agreements';
import AgreementsList from './pages/agreements/list';
import RegisterLoan from './pages/loans/register-loan';
import FinishLoan from './pages/new-loans/finish-loan';
import RegisteredPerson from './pages/loans';
import RegisteredNewPerson from './pages/new-loans';
import RegisterNewLoan from './pages/new-loans/register-loan';
import GetParcelLoan from './pages/new-loans/get-parcels';
import Prospect from './pages/prospect';
import PersonProspect from './pages/prospect/prospect-approved';
import ExtractNyc from './pages/extract';
import Users from './pages/users';
import ReportAdmin from './pages/admin-report';
import Correspondents from './pages/correspondents';
import RegisterCorrespondent from './pages/correspondents/register'
import Clients from './pages/clients';
import RegisterClients from './pages/clients/register';
import GenerateLoan from './pages/loans/generate-loan';
import Attendants from './pages/attendants';
import RegisterAttendant from './pages/attendants/register';
import { FlagContext } from './contexts/flag-context/flag';
import { HashRouter as Router } from 'react-router-dom';
import UpdateStatus from './pages/update-status';
import CashOut from './pages/cash-out';
import ConsultCpf from './pages/consult-cpf';
import DetailsReport from './pages/consult-cpf/details';
import QrCodePageLoan from './pages/loans/qrcode-list';
import ReportCPF from './pages/new-loans/report-cpf';

const router = createBrowserRouter([
	{
		path: "/",
		element: <SignInPage />,
	},
	{
		path: "/report-details",
		element: <ReportAdmin />,
	},
	{
		path: "/loans/generate-loan",
		element: <GenerateLoan />,
	},
	{
		path: "/attendants",
		element: <Attendants />,
	},
	{
		path: "/attendants/register",
		element: <RegisterAttendant />,
	},
	{
		path: "/correspondents",
		element: <Correspondents />,
	},
	{
		path: "/correspondents/register",
		element: <RegisterCorrespondent />,
	},
	{
		path: "/clients",
		element: <Clients />,
	},
	{
		path: "/clients/register",
		element: <RegisterClients />,
	},
	{
		path: "/attendants",
		element: <Correspondents />,
	},
	{
		path: "/attendants/register",
		element: <RegisterCorrespondent />,
	},
	{
		path: "/Signup",
		element: <SignUpPage />,
	},
	{
		path: '/report',
		element: <Report />
	},
	{
		path: '/signin',
		element: <SignInPage />
	},
	{
		path: '/dashboard',
		element: <Dashboard />
	},
	{
		path: '/loans',
		element: <RegisteredPerson />
	},
	{
		path: '/new-loans',
		element: <RegisteredNewPerson />
	},
	{
		path: '/new-loans/finish-loan',
		element: <FinishLoan />
	},
	{
		path: '/new-loans/get-parcels',
		element: <GetParcelLoan />
	},
	{
		path: "/new-loans/report-cpf",
		element: <ReportCPF />,
	},
	{
		path: '/loans/qrcode-list',
		element: <QrCodePage />
	},
	{
		path: '/new-loans/qrcode-list',
		element: <QrCodePageLoan />
	},
	{
		path: '/loans/pdf-generator',
		element: <PdfGenerator />
	},
	{
		path: '/loans/register-cpf',
		element: <RegisterCpf />
	},
	{
		path: '/loans/register-loan',
		element: <RegisterLoan />
	},
	{
		path: '/new-loans/register-loan',
		element: <RegisterNewLoan />
	},
	{
		path: '/consult-cpf/details',
		element: <DetailsReport />
	},
	{
		path: '/consult-cpf',
		element: <ConsultCpf />
	},
	{
		path: '/update-status',
		element: <UpdateStatus />
	},
	{
		path: '/cash-out',
		element: <CashOut />
	},
	{
		path: '/agreements',
		element: < Agreements />
	},
	{
		path: '/agreements/List',
		element: < AgreementsList />
	},
	{
		path: '/loans/check-cpf',
		element: <LoanSearch />
	},
	{
		path: '/prospect',
		element: <Prospect />
	},
	{
		path: '/prospect/prospect-approved',
		element: <PersonProspect />
	},
	{
		path: '/extract',
		element: <ExtractNyc />
	},
	{
		path: '/users',
		element: <Users />
	},
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<ContextApp>
		<React.StrictMode>
			<RouterProvider router={router} />
		</React.StrictMode>
	</ContextApp>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
