import './style.css'
import Header from "../../components/header"
import BarChartActiveAccounts from "./charts/accountsActive"
import BarChartBalance from "./charts/balanceAccounts"
import BarChartCashFlow from "./charts/cashFlow"
import BarChartDefaultRate from "./charts/defaultRate"
import BarChartQrCode from "./charts/qrCode"
import BarChartTransactions from "./charts/transactions"
import BarChartNycAccount from "./charts/nycAccount"
import { AuthContext } from "../../contexts/auth-context/auth"
import { useContext, useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import React from "react"
import CryptoNyc from '../../utils/crypto-nyc'
import Logonyc from '../../assets/logoNYC/logo_nycbank_black.svg'
import axios from 'axios'
import { formatDateTime } from '../../utils/formatDateTime'
import Loading from '../../components/loading'
import DoughnutPropertyChart from './charts/property'
import PersonalLoan from './charts/personalLoan'
import Antecipations from './charts/antecipations'
import StatsThree from './charts/contratos-xepa'
// import StatsThree from './charts/contratos-xepa'
// import StatsRestricts from './charts/restricoes-xepa'

interface Delayed {
	titles: number,
	value: number
	percentage: number
}
interface Payed {
	titles: number,
	value: number
	percentage: number
}
interface Received {
	titles: number,
	value: number
	percentage: number
}
interface ExtractProps {
	value: number
	type: string
	balance: number
	reasonDescription: string
	performedIn: Date
	payerName: string
}

interface StatsProps {
	aprovados: number,
	naoAprovados: number,
	porcentagemAprovados: number,
	porcentagemNaoAprovados: number,
	totalContracts: number
}

interface TotalProps {
	pagamentosComParcelaPaga: number,
	pagamentosEmDia: number,
	pagamentosSemPagamento: number,
	porcentagemPagamentosComParcelaPaga: number,
	porcentagemPagamentosEmDia: number,
	porcentagemPagamentosSemPagamento: number
}
interface ThirdProps {
	aprovadosComParcelaPaga: number,
	aprovadosEmDia: number,
	aprovadosSemPagamento: number,
	naoAprovadosComParcelaPaga: number,
	naoAprovadosEmDia: number,
	naoAprovadosSemPagamento: number
}

export default function Dashboard() {
	const [delayed, setDelayed] = useState<Delayed>()
	const [payed, setPayed] = useState<Payed>()
	const [received, setReceived] = useState<Received>()
	const [movements, setMovements] = useState<ExtractProps[]>([])
	const [custody, setCustody] = useState<number>(0);
	const [heritage, setHeritage] = useState<number>(0);
	const [totalPersonal, setTotalPersonal] = useState<Received>();
	const [delayedPersonal, setDelayedTotalPersonal] = useState<Received>();
	const [payedPersonal, setReceivedTotalPersonal] = useState<Received>();
	const [receivedPersonal, setPayedTotalPersonal] = useState<Received>();
	const [totalAntecipations, setTotalAntecipations] = useState<Received>();
	const [delayedAntecipations, setDelayedAntecipations] = useState<Received>();
	const [payedAntecipations, setPayedAntecipations] = useState<Received>();
	const [receivedAntecipations, setReceivedAntecipations] = useState<Received>();
	const [stats, setStats] = useState<StatsProps>()
	const [third, setThird] = useState<ThirdProps>()
	const [total, setTotal] = useState<TotalProps>()
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();

	const { auth, setAuth } = useContext(AuthContext)
	const _cryptoNyc = new CryptoNyc();

	useEffect(() => {
		setLoading(true)

		if (auth === null || auth === 'undefined' || auth === undefined || auth === '') {
			navigate('/signin')
		}

		axios.get('https://nycreportservice.azurewebsites.net/api/dash/Loan/ObterEstatisticas',
			{
				headers: {
					Authorization: `Bearer ${auth}`
				}
			}).then((res) => {
				// setDelayed(res.data.loan)
				// console.log(res.data)
				setStats(res.data)
				setLoading(false)
				// setAuth(res.data.token)
			})
		axios.get('https://nycreportservice.azurewebsites.net/api/dash/Loan/GetStatsPaymentAproved',
			{
				headers: {
					Authorization: `Bearer ${auth}`
				}
			}).then((res) => {
				// setDelayed(res.data.loan)
				// console.log(res.data)
				setThird(res.data)
				setLoading(false)
				// setAuth(res.data.token)
			})
		axios.get('https://nycreportservice.azurewebsites.net/api/dash/Loan/GetStatsPayment',
			{
				headers: {
					Authorization: `Bearer ${auth}`
				}
			}).then((res) => {
				// setDelayed(res.data.loan)
				// console.log(res.data)
				setTotal(res.data)
				setLoading(false)
				// setAuth(res.data.token)
			})


		axios.get('https://nycreportservice.azurewebsites.net/api/dash/Loan/getdelayed ',
			{
				headers: {
					Authorization: `Bearer ${auth}`
				}
			}).then((res) => {
				setDelayed(res.data.loan)
				// console.log(res)
				setAuth(res.data.token)
			})
		axios.get('https://nycreportservice.azurewebsites.net/api/dash/Loan/gettopayed',
			{
				headers: {
					Authorization: `Bearer ${auth}`
				}
			}).then((res) => {
				setPayed(res.data.loan)
				setAuth(res.data.token)
			})
		axios.get('https://nycreportservice.azurewebsites.net/api/dash/Loan/getreceived',
			{
				headers: {
					Authorization: `Bearer ${auth}`
				}
			}).then((res) => {
				setReceived(res.data.loan)
				setAuth(res.data.token)
			})

		// ==================================================//==================================================

		axios.get('https://nycreportservice.azurewebsites.net/api/dash/Loan/personal/getallloan ',
			{
				headers: {
					Authorization: `Bearer ${auth}`
				}
			}).then((res: any) => {
				setTotalPersonal(res.data.loan)
			})

		axios.get('https://nycreportservice.azurewebsites.net/api/dash/Loan/personal/getloandelayed',
			{
				headers: {
					Authorization: `Bearer ${auth}`
				}
			}).then((res: any) => {
				setDelayedTotalPersonal(res.data.loan)

			})

		axios.get('https://nycreportservice.azurewebsites.net/api/dash/Loan/personal/getloantopayed',
			{
				headers: {
					Authorization: `Bearer ${auth}`
				}
			}).then((res: any) => {
				setPayedTotalPersonal(res.data.loan)
			})

		axios.get('https://nycreportservice.azurewebsites.net/api/dash/Loan/personal/getloanreceived',
			{
				headers: {
					Authorization: `Bearer ${auth}`
				}
			}).then((res: any) => {
				setReceivedTotalPersonal(res.data.loan)
			})

		// ==================================================//==================================================

		axios.get('https://nycreportservice.azurewebsites.net/api/dash/Loan/antecipations/getall ',
			{
				headers: {
					Authorization: `Bearer ${auth}`
				}
			}).then((res: any) => {
				setTotalAntecipations(res.data.loan)
			})

		axios.get('https://nycreportservice.azurewebsites.net/api/dash/Loan/antecipations/getunpaid',
			{
				headers: {
					Authorization: `Bearer ${auth}`
				}
			}).then((res: any) => {
				setDelayedAntecipations(res.data.loan)

			})

		axios.get('https://nycreportservice.azurewebsites.net/api/dash/Loan/antecipations/getpayed',
			{
				headers: {
					Authorization: `Bearer ${auth}`
				}
			}).then((res: any) => {
				// console.log('pagos', res.data.loan)
				setPayedAntecipations(res.data.loan)
			})

		axios.get('https://nycreportservice.azurewebsites.net/api/dash/Loan/antecipations/getreceived',
			{
				headers: {
					Authorization: `Bearer ${auth}`
				}
			}).then((res: any) => {
				setReceivedAntecipations(res.data.loan)
			})

		// ==================================================//==================================================

		axios.get('https://nycfinancialreportservice.azurewebsites.net/api/dash/BalanceNYC/getheritage',
			{
				headers: {
					Authorization: `Bearer ${auth}`
				}
			}).then((res) => {
				setHeritage(res.data.heritage.heritage)
			})

		axios.get('https://nyctbanksservice.azurewebsites.net/api/v2/TBanks/Account/GetMovements').then((res) => {
			setMovements(res.data.data)
		})

		axios.get('https://nycfinancialreportservice.azurewebsites.net/api/dash/Balance/gettotal', {
			headers: {
				Authorization: `Bearer ${auth}`
			}
		})
			.then((res) => {

				return res.data.balance;
			})
			.then((data) => {
				// console.log(data)
				setCustody(data);
			})
			.catch((error) => {
				console.error('Ocorreu um erro:', error);
			});
	}, [])

	const debits = movements.filter((item) => item.type == "DEBIT")
	const limitedDebits = debits.slice(0, 5)

	const credits = movements.filter((item) => item.type == "CREDIT")
	const limitedCredits = credits.slice(0, 5)


	return (
		<>
			<Header />
			{!loading ?
				<main className='dashboard-main'>
					<div className='dataContainer'>
						<div className='box-chart'>
							<div>

								<div className='box-title' style={{ width: 245 }}>
									<span>Resumo Xepa</span><br />
									<small style={{ color: 'white' }}>
										<b style={{ color: 'white' }}>
											&nbsp;
											Contratos:
										</b>&nbsp;
										{stats?.totalContracts}
									</small>
									<br />
									{/* <small style={{ color: 'white' }}><b style={{ color: 'white' }}>&nbsp;Títulos:</b> {payed?.titles && received?.titles ? payed?.titles + received?.titles : ''}</small> */}

								</div>
								<div className="dash-container" style={{ width: 245 }}>
									<p  >Com restrições: {stats?.aprovados}</p>
									<p >Sem restrições: {stats?.naoAprovados}</p>

								</div>
							</div>
							<StatsThree />
						</div>
						<div className='dataGrid'>
							<div>
								<h3 className='box-title'>Contratos em dia: {total?.pagamentosEmDia}</h3>
								<div className="dash-container">
									<p  >Com restrições: {third?.aprovadosEmDia}</p>
									<p >Sem restrições: {third?.naoAprovadosEmDia}</p>
								</div>
							</div>
							<div>

								<h3 className='box-title'>Contratos com pagamento: {total?.pagamentosComParcelaPaga}</h3>
								<div className="dash-container">
									<p>Com restrições: {third?.aprovadosComParcelaPaga}</p>
									<p >Sem restrições: {third?.naoAprovadosComParcelaPaga}</p>
								</div>
							</div>
							<div>

								<h3 className='box-title'>Contratos sem pagamento: {total?.pagamentosSemPagamento}</h3>
								<div className="dash-container">
									<p>Com restrições: {third?.aprovadosSemPagamento}</p>
									<p >Sem restrições: {third?.naoAprovadosSemPagamento}</p>
								</div>
							</div>
						</div>
						{/* <StatsRestricts /> */}
						<div className='box-chart'>
							<div className='box-title'>
								<span>Patrimônio</span><br />
								<small style={{ color: 'white' }}><b style={{ color: 'white' }}>&nbsp;Valor Total:</b> {heritage.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</small><br />
								<small style={{ color: 'white' }}><b style={{ color: 'white' }}>&nbsp;Títulos:</b> {payed?.titles && received?.titles ? payed?.titles + received?.titles : ''}</small>
							</div>
							<DoughnutPropertyChart />
						</div>

						<div className='dataGrid'>
							<div>
								<h3 className='box-title'>Recebidos</h3>
								<div className="dash-container">

									<p  >Títulos: {received?.titles}</p>
									<p >Valor: {received?.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
									<p >Porcentagem: {received?.percentage}%</p>

								</div>
							</div>
							<div>

								<h3 className='box-title'>Em Atraso</h3>
								<div className="dash-container">
									<p >Títulos: {delayed?.titles}</p>
									<p >Valor: {delayed?.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
									<p >Porcentagem: {delayed?.percentage}%</p>

								</div>
							</div>
							<div>

								<h3 className='box-title'>Para Receber</h3>
								<div className="dash-container">
									<p >Títulos: {payed?.titles && delayed?.titles ? payed?.titles - delayed.titles : ''}</p>
									<p >Valor: {payed?.value && delayed?.value && (payed?.value - delayed?.value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
									<p >Porcentagem: {payed?.percentage && delayed?.percentage ? (payed?.percentage - delayed.percentage).toFixed(2) : ''}%</p>
								</div>
							</div>
						</div>
						<br />
						<div className='box-chart'>
							<div className='box-title'>
								<span>Xepa</span>
								<p className='total-value' style={{ color: 'white', fontSize: 14 }}><b style={{ color: 'white', fontSize: 14 }}>Valor Total:</b> {totalPersonal?.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
								<p className='total-value' style={{ color: 'white', fontSize: 14 }}><b style={{ color: 'white', fontSize: 14 }}>Títulos:</b> {totalPersonal?.titles}</p>
							</div>
							<PersonalLoan />
						</div>
						<div className='dataGrid'>
							<div>
								<h3 className='box-title'>Parcela a Quitadas: {payedPersonal?.percentage}%</h3>
								<div className="dash-container">

									<p >Títulos: {payedPersonal?.titles}</p>
									<p >Valor: {payedPersonal?.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>

								</div>
							</div>
							<div>

								<h3 className='box-title'>Parcelas em Atraso: {delayedPersonal?.percentage}%</h3>
								<div className="dash-container">
									<p>Títulos: {delayedPersonal?.titles}</p>
									<p>Valor: {delayedPersonal?.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>

								</div>
							</div>
							<div>
								<h3 className='box-title' >Parcelas a Receber: {receivedPersonal?.percentage && delayedPersonal?.percentage && (receivedPersonal?.percentage - delayedPersonal?.percentage).toFixed(2)}%</h3>
								<div className="dash-container">

									<p >Títulos: {receivedPersonal?.titles && delayedAntecipations?.titles && receivedPersonal?.titles - delayedAntecipations?.titles}</p>
									<p >Valor: {receivedPersonal?.value && delayedPersonal?.value && (receivedPersonal?.value - delayedPersonal?.value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
									<p ></p>
								</div>

							</div>
						</div>
						<br />
						<div className='box-chart'>
							<div className='box-title'>
								<span>Antecipação de Recebíveis</span>
								<p className='total-value' style={{ color: 'white', fontSize: 14 }}><b style={{ color: 'white', fontSize: 14 }}>Valor Total:</b> {totalAntecipations?.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
								<p className='total-value' style={{ color: 'white', fontSize: 14 }}><b style={{ color: 'white', fontSize: 14 }}>Títulos:</b> {totalAntecipations?.titles}</p>
							</div>
							<Antecipations />
						</div>
						<div className='dataGrid'>
							<div>
								<h3 className='box-title' >Recebidos:</h3>
								<div className="dash-container">
									<p>Títulos: {payedAntecipations?.titles}</p>
									<p>Valor: {payedAntecipations?.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
									<p>Porcentagem: {payedAntecipations?.percentage}%</p>
								</div>
							</div>
							<div>

								<h3 className='box-title' >Em Atraso:</h3>
								<div className="dash-container">
									<p>Títulos: {delayedAntecipations?.titles}</p>
									<p>Valor: {delayedAntecipations?.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
									<p>Porcentagem: {delayedAntecipations?.percentage}%</p>
								</div>
							</div>
							<div>
								<h3 className='box-title'>Para Receber:</h3>
								<div className="dash-container">

									<p>Títulos: {receivedAntecipations?.titles}</p>
									<p>Valor: {receivedAntecipations?.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
									<p>Porcentagem: {receivedAntecipations?.percentage}%</p>
								</div>
							</div>
						</div>
						<br />
						<div className='custody'>
							<h3 style={{ color: 'white', backgroundColor: '#445469', padding: 8 }}>Custódia
								<p className='total-value'>Valor Total: {custody.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p></h3>
							{/* <br /> */}
							{/* <div className='custody-grid'>

								<div className="custody-container-card">
									{
										limitedDebits.map((item, index) => (
											<div className={`card ${item.type == 'DEBIT' ? 'debit' : 'credit'}`} key={index}>
												<div>
													<h2 className={`${item.type == 'DEBIT' ? 'red' : 'green'}`}>{item.type == 'DEBIT' ? 'Débito' : 'Crédito'}</h2>
													<p className="gray">{item.reasonDescription}</p>

													<p className="valor">{item.value.toLocaleString(
														"pt-BR",
														{
															style: "currency",
															currency: "BRL",
														})}</p>
													<p className="date">{item.payerName}</p>
													<p className="date">{formatDateTime(item.performedIn)}</p>
												</div>
											</div>
										))
									}
								</div>
								<div className="custody-container-card">
									{
										limitedCredits.map((item, index) => (
											<div className={`card ${item.type == 'DEBIT' ? 'debit' : 'credit'}`} key={index}>
												<div>
													<h2 className={`${item.type == 'DEBIT' ? 'red' : 'green'}`}>{item.type == 'DEBIT' ? 'Débito' : 'Crédito'}</h2>
													<p className="gray">{item.reasonDescription}</p>

													<p className="valor">{item.value.toLocaleString(
														"pt-BR",
														{
															style: "currency",
															currency: "BRL",
														})}</p>
													<p className="date">{item.payerName}</p>
													<p className="date">{formatDateTime(item.performedIn)}</p>
												</div>
											</div>
										))
									}
								</div>
							</div> */}
							<br />
							<div className="tableReportContainer">
								<p style={{ color: '#445469', fontSize: 26 }}>Créditos</p>
								{/* <h1 style={{ color: 'white', backgroundColor: '#445469', padding: 8 }}>Débitos</h1> */}
								<table className="table">
									<thead className="tableHeader">
										{/* <tr>
											<th>Débito</th>
											<th></th>
											<th></th>
											<th></th>

										</tr> */}
										<tr>
											<th>Tipo</th>
											<th>Valor</th>
											<th>Pagante</th>
											<th>Data Hora</th>
										</tr>
									</thead>
									<tbody className="tableBody">
										{limitedCredits.map((item, index) => (
											<tr id='linhas' className={index % 2 === 0 ? "lightGreen" : "darkGreen"} key={index}>
												<td>{item.reasonDescription}</td>
												<td>{item.balance.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
												<td>{item.payerName}</td>
												<td>{formatDateTime(item.performedIn)}</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
							<br />
							<div className="tableReportContainer">
								<p style={{ color: '#445469', fontSize: 26 }}>Débitos</p>
								{/* <h1 style={{ color: 'white', backgroundColor: '#445469', padding: 8 }}>Débitos</h1> */}
								<table className="table">
									<thead className="tableHeader">
										{/* <tr>
											<th>Débito</th>
											<th></th>
											<th></th>
											<th></th>

										</tr> */}
										<tr>
											<th>Tipo</th>
											<th>Valor</th>
											<th>Pagante</th>
											<th>Data Hora</th>
										</tr>
									</thead>
									<tbody className="tableBody">
										{limitedDebits.map((item, index) => (
											<tr id='linhas' className={index % 2 === 0 ? "lightGreen" : "darkGreen"} key={index}>
												<td>{item.reasonDescription}</td>
												<td>{item.balance.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
												<td>{item.payerName}</td>
												<td>{formatDateTime(item.performedIn)}</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div >


					{/* <div className='container-divisor'>

						<hr className='divisor-dash' />
					</div>
					<div className="containerCharts">
						<BarChartNycAccount />
						<BarChartBalance />
						<BarChartTransactions />
						<BarChartDefaultRate />
						<BarChartQrCode />
						<BarChartCashFlow />
						<BarChartActiveAccounts />
					</div> */}
				</main > :
				<div className="loading-page-loan">
					<p>Carregando...</p>
					<Loading />
				</div>
			}
		</>
	)
}