import { Children } from 'react';
import './style.css';
import React from 'react';

type Select =
	{
		onChange: (a: any) => void;
		onBlur?: (a: any) => void;
		children: any
		className?: string
	}

export default function Select(props: Select) {
	const { onChange, children, className, onBlur } = props
	return (
		<select onChange={onChange} onBlur={onBlur} className={`${className} select`}>
			{children}
		</select>
	)
}