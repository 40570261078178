import axios from "axios"
import Header from "../../components/header"
import React, { useContext, useState } from "react"
import Input from "../../components/inputs/defaultInput"
import VMasker from "vanilla-masker"
import Button from "../../components/buttons/primaryButton"
import CryptoNyc from "../../utils/crypto-nyc"
import { AuthContext } from "../../contexts/auth-context/auth"
import Logonyc from "../../assets/logoNYC/logo_nycbank_black.svg";
import { toast } from "react-toastify"

const CashOut = () => {
	const [amountValue, setAmountValue] = useState(0)
	const [password, setPassword] = useState('')
	const [maskedValue, setMaskedValue] = useState('');
	const { auth } = useContext(AuthContext)
	const _cryptoNyc = new CryptoNyc();

	const pix = () => {
		console.log(auth)
		const unmasked = maskedValue.replaceAll('.', '').replace(',', '.')
		axios.post('https://nyctbanksservice.azurewebsites.net/api/v2/TBanks/Pix/pixcashout',
			{
				cypher: _cryptoNyc.hash({
					amount: unmasked,
					password: _cryptoNyc.hash({ password })
				})
			},
			{
				headers: {
					Authorization: `Bearer ${auth}`
				}
			}
		).then((res) => {
			switch (res.status) {
				case 200:
					toast.success('Saque realizado com sucesso!', {
						position: toast.POSITION.TOP_RIGHT,
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						rtl: false,
						pauseOnFocusLoss: true,
						draggable: true,
						pauseOnHover: true,
						theme: "light"
					});


					break;
				default:
					toast('Sucesso')
					break;

			}
		})
	}



	return (
		<>
			<Header />

			<div className="new-container" style={{ margin: '8%', display: 'grid', justifyContent: 'center', gap: 12, width: '80%', height: 'fit-content' }}>
				<img src={Logonyc.toString()} alt="logo da NYC Bank" />
				<Input placeholder='Valor' type='text' value={`R$ ${maskedValue}`} onChange={(e) => {
					const value = e.target.value // Remove caracteres não numéricos
					const formattedValue = VMasker.toMoney(value);
					setMaskedValue(formattedValue);
				}} />
				<Input placeholder='Senha' type='password' value={password} onChange={(e) => {
					setPassword(e.target.value)
				}} />
				<Button textButton="Enviar" onClick={() => pix()} />
			</div>
		</>
	)
}

export default CashOut