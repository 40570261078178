import { useState, useEffect, useContext } from "react";
import "./style.css";
import logonyc from "../../../assets/logoNYC/logo_nycbank_black.svg";
import Button from "../../../components/buttons/primaryButton";
import { checkLoans } from "../../../utils/check";
import { formatDate } from "../../../utils/formatDate";
import Select from "../../../components/inputs/selectInput";
import Input from "../../../components/inputs/defaultInput";
import { ToastContainer, toast, Bounce, Slide, Flip, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from "../../../components/header";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { AuthContext } from "../../../contexts/auth-context/auth";
import React from "react";
import { formatDateTime } from "../../../utils/formatDateTime";
import Modal from 'react-modal'
import VMasker from "vanilla-masker";


interface dataProps {
	id: number,
	name: string;
	product: string;
	amountParcel: string;
	isPayed: Boolean;
	parcel: string;
	emv: string
	paymentDay: Date;
	payedAt: Date;
	isReversed: Boolean
	personID: Number
	amountPayed: number
}

export default function QrCodePageLoan() {
	const [data, setData] = useState<any>(null)
	const [dataFiltered, setDataFiltered] = useState<dataProps[]>([])
	const [currentFilters, setCurrentFilters] = useState<{ [key: string]: string }>({});
	const [name, setName] = useState('')
	const [person, setPerson] = useState(0)
	const [controlDate, setControlDate] = useState('')
	const [payedDate, setPayedDate] = useState('')
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [modalDateIsOpen, setModalDateIsOpen] = useState(false)
	const [modalControlDateIsOpen, setModalControlDateIsOpen] = useState(false)
	const [modalPayedIsOpen, setModalPayedIsOpen] = useState(false)
	const [idParcel, setIdParcel] = useState(0)
	const [valueInputDate, setValueInputDate] = useState('')
	const [newAmount, setNewAmount] = useState('')

	const date = new Date()
	const currentDate = date.toISOString()

	const navigate = useNavigate()
	const { auth } = useContext(AuthContext)

	// useEffect(() => {

	// 	if (auth === null || auth === 'undefined' || auth === undefined || auth === '') {
	// 		navigate('/signin')
	// 	}
	// }, [])

	const setReversed = () => {
		axios.put('https://nycreportservice.azurewebsites.net/api/dash/Loan/putloan',
			person, {
			headers: {
				Authorization: `Bearer ${auth}`,
				'Content-Type': 'application/json',
			}
		}).then((res) => {

			// console.log('Estornados', res.data)
			setModalIsOpen(false)

		}
		)
	}

	const copyPixCode = (code: string) => {

		navigator.clipboard.writeText(code)

		toast.info('Código PIX copiado para a área de transferência', {
			position: toast.POSITION.TOP_RIGHT,
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			rtl: false,
			pauseOnFocusLoss: true,
			draggable: true,
			pauseOnHover: true,
			theme: "light"
		});
	}


	const parcels = typeof window !== 'undefined' ? localStorage.getItem('parcels-new-loans') : null
	useEffect(() => {
		const setList = async () => {

			if (parcels !== null) {
				// const req = await checkLoans(cpf)
				// if (req && req.data && req.status == 200) {
				// console.log(req.data)
				setData(JSON.parse(parcels))
				// }
			}
		}
		setList();
	}, [parcels])

	// useEffect(() => {
	// 	setDataFiltered(data)
	// }, [data])

	// const filter = (value: string, filterBy: string) => {
	// 	setCurrentFilters((prevFilters) => ({ ...prevFilters, [filterBy]: value }));
	// };

	// useEffect(() => {
	// 	// Clona os dados originais para evitar mutação direta no estado
	// 	let dadosFiltrados = data;

	// 	// Itera sobre cada filtro no objeto currentFilters
	// 	for (const filterBy in currentFilters) {
	// 		// Extrai o valor do filtro
	// 		const valorFiltro = currentFilters[filterBy];

	// 		// Verifica se um valor de filtro está presente
	// 		if (valorFiltro) {
	// 			// Verifica se o filtro está relacionado a campos de data (paymentDay ou payedAt)
	// 			if (filterBy === "paymentDay") {
	// 				// Analisa o valor do filtro como uma data
	// 				// Verifica se a data analisada possui um ano válido (4 dígitos)
	// 				// Aplica os filtros de data
	// 				dadosFiltrados = dadosFiltrados.filter((data) => {
	// 					const valorItem = data[filterBy as keyof dataProps];

	// 					// Ignora valores nulos
	// 					if (valorItem === null) {
	// 						return false;
	// 					}

	// 					// Formata a data do filtro para comparação


	// 					switch (valorFiltro) {
	// 						case 'jan':
	// 							return valorItem.toString().includes('-01-')
	// 							break;
	// 						case 'fev':
	// 							return valorItem.toString().includes('-02-')
	// 							break;
	// 						case 'mar':
	// 							return valorItem.toString().includes('-03-')
	// 							break;
	// 						case 'abr':
	// 							return valorItem.toString().includes('-04-')
	// 							break;
	// 						case 'mai':
	// 							return valorItem.toString().includes('-05-')
	// 							break;
	// 						case 'jun':
	// 							return valorItem.toString().includes('-06-')
	// 							break;
	// 						case 'jul':
	// 							return valorItem.toString().includes('-07-')
	// 							break;
	// 						case 'ago':
	// 							return valorItem.toString().includes('-08-')
	// 							break;
	// 						case 'set':
	// 							return valorItem.toString().includes('-09-')
	// 							break;
	// 						case 'out':
	// 							return valorItem.toString().includes('-10-')
	// 							break;
	// 						case 'nov':
	// 							return valorItem.toString().includes('-11-')
	// 							break;
	// 						case 'dez':
	// 							return valorItem.toString().includes('-12-')
	// 							break;
	// 						default:
	// 							return valorItem
	// 							break;
	// 					}
	// 				});
	// 			} else {
	// 				// Aplica filtros não relacionados a datas

	// 				// Verifica se o valor do filtro é "default"
	// 				if (valorFiltro === "default") {
	// 					// Redefine os dados filtrados para os dados originais da API
	// 					setDataFiltered(data);
	// 				} else {
	// 					// Aplica filtragem de string insensível a maiúsculas e minúsculas
	// 					dadosFiltrados = dadosFiltrados.filter((data) =>
	// 						data[filterBy as keyof dataProps]
	// 							.toString()
	// 							.toLowerCase()
	// 							.includes(valorFiltro.toLowerCase())
	// 					);
	// 				}
	// 			}
	// 		}
	// 	}

	// 	// Atualiza o estado com os dados filtrados
	// 	setDataFiltered(dadosFiltrados);
	// }, [data, currentFilters]);

	// const areAllItemsReversed = () => {
	// 	// Check if every item in the data array has isReversed set to true
	// 	return data.every(item => item.isReversed);
	// };

	const changePaymentDay = (id: number) => {
		const dateFormat = new Date(controlDate).toISOString()
		axios.delete('https://nyciuguservice.azurewebsites.net/bankslip/cancelbankslip/' + id, {
			headers: {
				Authorization: `Bearer ${auth}`
			}
		}).then(() => {
			axios.post('https://nycloanservice.azurewebsites.net/api/v2/Loan/ChangeDate', {
				parcelId: id,
				newDate: dateFormat
			}, {
				headers: {
					Authorization: `Bearer ${auth}`
				}
			}).then((res) => {
				setModalControlDateIsOpen(false)
				navigate("/new-loans/get-parcels");
			})
		}).catch((e) => {
			axios.post('https://nycloanservice.azurewebsites.net/api/v2/Loan/ChangeDate', {
				parcelId: id,
				newDate: dateFormat
			}, {
				headers: {
					Authorization: `Bearer ${auth}`
				}
			}).then((res) => {
				setModalControlDateIsOpen(false)
				localStorage.setItem("get-parcel-cpf", data.cpf);

				navigate("/new-loans/get-parcels");
			})
		})

	}

	const payedParcel = (id: number) => {
		const valueFormatted = newAmount.replaceAll('.', '').replaceAll(',', '.')
		// const dateFormatted = new Date(payedDate).toISOString()
		axios.post('https://nycloanservice.azurewebsites.net/api/v2/Loan/Payed', {
			parcelId: id,
			amountPayed: valueFormatted
		}, {
			headers: {
				Authorization: `Bearer ${auth}`
			}
		}).then((res) => {
			console.log(res.status)
			localStorage.setItem("get-parcel-cpf", data.cpf);
			navigate("/new-loans/get-parcels");
		})
	}

	const generateBill = (id: number, dueDate: string, par: number, amount: number) => {
		const valueFormatted = newAmount.replaceAll('.', '').replaceAll(',', '.')
		// const dateFormatted = new Date(payedDate).toISOString()
		axios.get('https://nycbank-loanservice.azurewebsites.net/api/Dash/GetInfo/' + data.cpf, {
			headers: {
				Authorization: `Bearer ${auth}`
			}
		}).then((res) => {
			console.log(res)
			axios.post('https://nyciuguservice.azurewebsites.net/BankSlip/Create', {
				"cpf": data.cpf,
				"name": res.data.nome,
				"email": res.data.email,
				"dueDate": dueDate,
				"amount": amount,
				"description": "Pagamento da parcela " + par + " de " + res.data.nome,
				"patcelId": id
			}, {
				headers: {
					Authorization: `Bearer ${auth}`
				}
			}).then((res) => {
				console.log(res)
				window.open(res.data.returnInvoice.url, "_blank", "toolbar=no,scrollbars=yes,resizable=yes");
			})
		})

	}



	const setarData = (e: any) => {
		// console.log(e.target.value)
		setValueInputDate(e.target.value)
		sessionStorage.setItem('newDate', e.target.value)
	}

	return (
		<>
			<Header />
			<div style={{ width: '100%', overflowY: 'auto' }}>

				{/* <div className="qrcodeContainer"> */}
				<div className="imgNyc">
					<img src={logonyc.toString()} alt="logo da NYC Bank" />
				</div>

				<div className="qrcode">
					<ul className="qrcodeInfo">
						{data &&
							data.parcels.map((item: any, index: number) => (
								<li key={index}>
									<div className="qrInfo">
										<p>Parcela: {item.parcel} de {data.instalments}</p>
										<p>Vencimento: {formatDate(item.paymentDay)}</p>
										{item.payedAt ? <p>Data de Pagamento: {formatDate(item.payedAt)}</p> : ''}
										<p className={`paymentstats ${item.isPayed ? 'green' : 'red'}`}>Status: {item.isPayed ? 'Pago' : !item.isPayed && new Date(item.paymentDay).toISOString() >= currentDate ? 'Pendente' : 'Atrasado'}</p>
										<p>Valor: {item.isPayed && item.amount !== null ? item.amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : parseFloat(item.amount).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </p>
									</div>
									{
										!item.isPayed &&
										<div className="Buttons">
											<Button className={`Button`} onClick={() => { setModalControlDateIsOpen(true); setIdParcel(item.id) }} textButton={"Alterar Data"} />
											<Button className="Button" onClick={() => { !item.isPayed && new Date(item.paymentDay).toISOString() < currentDate ? setModalDateIsOpen(true) : generateBill(item.id, item.paymentDay, item.parcel, item.amount) }} textButton={"Gerar boleto"} />
											<Button className="Button" onClick={() => { setModalPayedIsOpen(true); setIdParcel(item.id) }} textButton={"Dar Baixa"} />
										</div>
									}
									<Modal isOpen={modalPayedIsOpen}
										onRequestClose={() => setModalPayedIsOpen(false)}
										className='modalPayed'>

										<h2>Escolha o valor do pagamento</h2>

										<Input placeholder='Valor' type='text' value={`R$ ${newAmount}`} onChange={(e) => {
											const value = e.target.value // Remove caracteres não numéricos
											const formattedValue = VMasker.toMoney(value);
											setNewAmount(formattedValue);
										}} />
										<div className="buttons">
											<Button onClick={() => payedParcel(idParcel)} textButton={'Alterar'} />
										</div>
									</Modal>

									<Modal isOpen={modalControlDateIsOpen}
										onRequestClose={() => setModalControlDateIsOpen(false)}
										className='modalDate'>

										<h2>Escolha a data de vencimento do boleto</h2>
										<Input onBlur={(e) => { setControlDate(e.target.value) }} type="date" />

										<div className="buttons">
											<Button onClick={() => changePaymentDay(idParcel)} textButton={'Alterar'} />
										</div>
									</Modal>

									<Modal isOpen={modalDateIsOpen}
										onRequestClose={() => setModalDateIsOpen(false)}
										className='modalDate'>

										<h2>Escolha a data de vencimento do boleto</h2>
										<Input value={valueInputDate} onInput={setarData} type="date" />

										<div className="buttons">
											<Button onClick={() => {
												navigate(`/loans/pdf-generator/?parcel_ID=${item.id}`);
												sessionStorage.setItem('customSlip', '')
											}} textButton={'Gerar com nova data'} />
										</div>
									</Modal>
									{item.isReversed &&
										<p className="reversed">
											Estornado
										</p>
									}
								</li>
							))
						}
					</ul>
				</div>
				<ToastContainer transition={Slide} />
			</div>
		</>
	)
}