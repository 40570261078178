import CryptoJS from "crypto-js";

class CryptoNyc {
	constructor() {
		this.key = this.parse("0000000102111416");
		this.iv = this.parse("1161920511310000");
	}
	parse(obj) {
		return CryptoJS.enc.Utf8.parse(obj);
	}
	hash(obj) {
		return CryptoJS.AES.encrypt(this.parse(JSON.stringify(obj)), this.key, {
			keySize: 128 / 8,
			iv: this.iv,
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.Pkcs7,
		}).toString();
	}
	get(obj) {
		return CryptoJS.AES.decrypt(obj, this.key, {
			keySize: 128 / 8,
			iv: this.iv,
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.Pkcs7,
		}).toString(CryptoJS.enc.Utf8);
	}
}

export default CryptoNyc;
