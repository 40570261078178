import './style.css'
import Dolar from '../../../assets/icons/dollar-sign.svg'
import Logo from '../../../assets/icons/logo_nycbank_black.svg'
import Calendar from '../../../assets/icons/calendar-icon.svg'
import qrcodeCell from '../../../assets/icons/qrcode-cell.svg'
import qrcodeScan from '../../../assets/icons/qr-code-scan.svg'
import CleanLogo from '../../../assets/icons/clean-logo.png'
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { formatDate } from '../../../utils/formatDate';
import { useLocation } from 'react-router-dom';

import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

import Button from '../../../components/buttons/primaryButton'
import Header from '../../../components/header'
import React from 'react'
import Barcode from 'react-barcode'
import Loading from '../../../components/loading'
import { AuthContext } from '../../../contexts/auth-context/auth'
import { formatDateTime } from '../../../utils/formatDateTime'

interface dataProps {
	id: number,
	amount: number,
	description: string,
	taxes: number,
	discount: number,
	fine: number,
	interest: number,
	paymentDay: Date,
	cpfCnpj: string,
	name: string,
	parcel: number,
	emv: string,
	documentData: Date
}

const PdfGenerator = () => {

	const [data, setData] = useState<dataProps>();
	const [barCodeValue, setBarCodeValue] = useState('')
	const [barCodeNumber, setBarCodeNumber] = useState('')
	const [loading, setLoading] = useState(false)
	const location = useLocation();
	const [newFormat, setNewFormat] = useState('')
	const [customDate, setCustomDate] = useState('')

	const { auth, setAuth } = useContext(AuthContext)
	let date = typeof window !== 'undefined' ? sessionStorage.getItem('newDate') : null;

	const actualDate = new Date()


	const custom = typeof window !== 'undefined' ? sessionStorage.getItem('customSlip') : null;
	useEffect(() => {




		const getData = async () => {
			let queryParams = await new URLSearchParams(location.search);
			let parcelId = queryParams.get('parcel_ID');

			const identifier = typeof window !== 'undefined' ? sessionStorage.getItem('otherDb') : null;

			const url = identifier == "false" ? `https://nyc-homol.azurewebsites.net/api/loan/boleto?id=${parcelId}` : `https://nyc-homol.azurewebsites.net/api/loan/boletoLoanId?id=${parcelId}`;

			await axios.get(url)
				.then((res) => {
					setData(res.data)
					const amountValueS = res.data.amount
					const cpfCnpjS = res.data.cpfCnpj
					const paymentDayS = date ? new Date(date).toISOString() : res.data.paymentDay
					if (res.data) {
						console.log(res.data)
						axios.post('https://nyctbanksservice.azurewebsites.net/api/v2/TBanks/BankSlip/CreateBankslip', {
							date: paymentDayS,
							value: amountValueS,
							cpf: cpfCnpjS,
							cep: '09531190'
						}).then((res) => {
							setBarCodeValue(res.data.barCode)
							setBarCodeNumber(res.data.digitableLine)
							setLoading(true)
						}).catch((e) => {
							console.log(e)
						})
					}
				}
				)
				.catch(e => {
					console.log(e)
					// window.location.reload()
				})
		}



		if (custom == 'custom') {
			setLoading(false)
			const customData = typeof window !== 'undefined' ? sessionStorage.getItem('jsonCustomSlip') : null;
			if (customData) {
				const jsonCustomData = JSON.parse(customData)
				// formatando data na mão pq sei la que caralhos ta acontecendo com o new date acredito que por ser horário zero ele atrase um dia
				const separation = jsonCustomData.dateForm.split('-')
				const dateWitouthTime = separation[2].split('T')
				const dateShowed = `${dateWitouthTime[0]}/${separation[1]}/${separation[0]}`
				setCustomDate(dateShowed)

				axios.get(`https://nycclientinfoservice.azurewebsites.net/api/v2/getpeoplebycpf/${jsonCustomData.cpfForm}`)
					.then((res) => {
						axios.post('https://nyctbanksservice.azurewebsites.net/api/v2/TBanks/BankSlip/CreateBankslip', {
							date: jsonCustomData.dateForm,
							value: jsonCustomData.valueForm,
							cpf: jsonCustomData.cpfForm,
							cep: '09531190'
						}).then((res) => {
							setBarCodeValue(res.data.barCode)
							setBarCodeNumber(res.data.digitableLine)
							setLoading(true)
						})

						if (res.data !== null) {
							setData(
								{
									id: 1,
									amount: parseFloat(jsonCustomData.valueForm),
									description: 'Adiantamento de parcelas',
									taxes: 1.86,
									discount: 0.00,
									fine: 70.66,
									interest: 8.79,
									paymentDay: jsonCustomData.dateForm,
									cpfCnpj: jsonCustomData.cpfForm,
									name: res.data.name,
									parcel: 1,
									emv: '',
									documentData: new Date()
								}
							)
						} else {
							axios.get(`https://nycclientinfoservice.azurewebsites.net/api/v2/getname/${jsonCustomData.cpfForm}`)
								.then((res) => {

									setData(
										{
											id: 1,
											amount: parseFloat(jsonCustomData.valueForm),
											description: 'Adiantamento de parcelas',
											taxes: 1.86,
											discount: 0.00,
											fine: 70.66,
											interest: 8.79,
											paymentDay: jsonCustomData.dateForm,
											cpfCnpj: jsonCustomData.cpfForm,
											name: res.data,
											parcel: 1,
											emv: '',
											documentData: new Date()
										}
									)
								})
						}

					}
					)
			}
		}
		else {
			getData();
		}
		let split = date?.split('-')
		if (split != null) {
			const format = `${split[2]}/${split[1]}/${split[0]}`
			setNewFormat(format)
		}
	}, [])

	const downloadPdf = () => {
		const loan = document.getElementById('ticket-page')

		if (loan != null) {

			html2canvas(loan).then((canvas) => {
				const imgData = canvas.toDataURL('img/jpg')
				const doc = new jsPDF('p', 'mm', 'a4')
				const componentWidth = doc.internal.pageSize.getWidth();
				const componentHeight = doc.internal.pageSize.getHeight();
				doc.addImage(imgData, 'PNG', 0, 0, componentWidth, componentHeight);
				doc.save(`${data?.name} - ${data?.description} - ${data?.cpfCnpj}.pdf`)
			})
		}


	}

	const qr = `https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=${data?.emv}&choe=UTF-8`



	return (
		<>
			<Header />
			{loading ?
				<div className='container'>
					<div id="ticket-page">
						<div className='principal'>
							<aside>
								<div className="main-header">
									<h1>Essa é sua conta</h1>
									<p id="name-person">{data?.name}</p>

									<div className="oblong-balance-container">
										<img src={Dolar.toString()} alt="dollar-sign" />
										<p>Valor</p>
										<p id="qr-code-balance">{data?.amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
									</div>
								</div>
								<div className="description-container">
									<div id="recipient">
										<h1>Beneficiário</h1>
										<p id='recipient-nyc'>NYC Cobranças</p>
										<p>CNPJ 50.311.740/0001-17</p>
									</div>
									<div>
										<h1>Descrição</h1>
										<p id="description">{data?.description}</p>
									</div>
									<div>
										<h1>Antes do vencimento</h1>
										<p id="before-payment">{data?.discount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
										<p>Desconto</p>
									</div>
									<div className="taxes">
										<h1>Após o vencimento</h1>
										<div id="after-payment">
											<div id="taxes-fine"><p><span>{data?.fine.toLocaleString(
												"pt-BR",
												{
													style: "currency",
													currency: "BRL",
												})}<br /></span>Multa</p>
											</div>

											<div id="taxes-fees"><p><span>{`${data?.taxes.toLocaleString(
												"pt-BR",
												{
													style: "currency",
													currency: "BRL",
												})}/dia`}</span><br />Juros</p></div>
											<div id="taxes-month"><p><span>{`${data?.interest}% a.m`}</span><br />Juros Remuneratórios </p></div>
										</div>
									</div>
									<footer className="intermediated">
										<p>Intermediado por: NYC BANK S.A</p>
										<p>CNPJ 42.972.965/0001-96</p>
									</footer>
								</div>
							</aside>
							<aside>
								<div className="main-header">
									<img src={Logo.toString()} alt="NYC BANK logo" />
									<div className="rectangle-due-date">
										<img src={Calendar.toString()} alt="calendar-icon" />
										<p>Vencimento</p>
										<p id="qr-code-due-date">  {
											custom ?
												customDate
												:
												date ?
													newFormat
													: data !== undefined && formatDate(data.paymentDay)


										}
										</p>
									</div>
								</div>
							</aside>

						</div>
						<div className="link-bar">
							<p>A NYC BANK é um banco digital. Abra sua conta grátis em: <a href="">nycbank.com.br</a></p>
						</div>
						<hr />
						<footer>
							<span className="footer-header">
								<img src={CleanLogo} width={70} alt="NYC bank logo clean" />
								|<p>000-1</p>|
								<p>{barCodeNumber}</p>
							</span>

							<table className='tableLoan'>
								<tbody>
									<tr id="tr1">
										<td>
											<p>Local de Pagamento</p>
											<p id="payment-local">Pagável em qualquer aplicativo bancário</p>
										</td>
										<td>
											<p>Vencimento</p>
											<p id="payment-date"><b>{
												custom ?
													customDate
													:
													newFormat ?
														newFormat
														: data !== undefined && formatDate(data.paymentDay)
											}
											</b></p>
										</td>
									</tr>
									<tr id="tr2">
										<td>
											<p>Beneficiário</p>
											<p id="recipient-name">NYC Cobranças</p>
										</td>
										<td>
											<p>CPF/CNPJ do Beneficiário</p>
											<p id="recipient-taxpayer">50.311.740/0001-17</p>
										</td>
										<td>
											<p>Agência/Código do Beneficiário</p>
											<p id="recipient-agency">0001</p>
										</td>
									</tr>
									<tr id="tr3">
										<td>
											<p>Data do Documento</p>
											<p id="doc-date">{formatDate(actualDate)}</p>
										</td>
										<td>
											<p>Nr. do Documento</p>
											<p id="doc-number">0{data?.id}</p>
										</td>
										<td>
											<p>Espécie Doc</p>
											<p id="doc-payment-type">PIX</p>
										</td>
										<td>
											<p>Aceite</p>
											<p id="doc-accept">N</p>
										</td>
										<td>
											<p>Nosso Número</p>
											<p id="doc-our-number">0{data?.id}</p>
										</td>
									</tr>
									<tr id="tr4">
										<td>
											<p>Uso do Banco</p>
											<p id="bank-use"></p>
										</td>
										<td>
											<p>Carteira</p>
											<p id="bank-wallet">01</p>
										</td>
										<td>
											<p>Espécie Moeda</p>
											<p id="bank-coin">R$</p>
										</td>
										<td>
											<p>Quantidade Moeda</p>
											<p id="bank-coin-quantity"></p>
										</td>
										<td>
											<p>(X) Valor</p>
											<p id="bank-value"></p>
										</td>
										<td>
											<p>(=) Valor do Documento</p>
											<p id="bank-value-doc"><b>{data?.amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</b></p>
										</td>
									</tr>
									<tr id="tr5">
										<td>
											<p>Após o vencimento, serão cobrados valores destacados acima.</p>
										</td>
										<td>
											<p>(-) Desconto</p>
											<p id="discount-value"></p>
										</td>
										<td>
											<p>(-) Outras Deduções/Abatimento</p>
											<p id="other-cash-rebate"></p>
										</td>
										<td>
											<p>(+) Mora/Multa/Juros</p>
											<p id="fine-late-payment"></p>
										</td>
										<td>
											<p>(+) Outros Acréscimos</p>
											<p id="other-additions"></p>
										</td>
										<td>
											<p>(=) Valor Cobrado</p>
											<p id="amount charged"></p>
										</td>
									</tr>
									<tr id="tr6">
										<td>
											<p>Pagador</p>
											<p id="name-person-table">{data?.name} - {data?.cpfCnpj}</p>
											<p>Sacador/Avalista</p>
										</td>
									</tr>
								</tbody>

							</table>
							<Barcode value={barCodeValue !== '' ? barCodeValue : '123456789'} displayValue={false} />
							<div className="last-p">
								<p>Autenticação Mecânica - Ficha de Compensação</p>
							</div>
							<hr />
						</footer>
					</div>
					<Button onClick={downloadPdf} textButton="Download" />
				</div> :
				<div className='loading-page'>
					<p>Gerando seu boleto....</p>
					<Loading />
				</div>
			}

		</>
	)
}

export default PdfGenerator