import React, { Consumer, useContext, useEffect, useRef, useState } from "react";
import Header from "../../components/header";
import axios from "axios";
import { useNavigate } from "react-router";
import { AuthContext } from "../../contexts/auth-context/auth";
import './style.css'
import { Link } from "react-router-dom";
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from "../../components/loading";
import Button from "../../components/buttons/primaryButton";
import ButtonIcon from "../../components/buttons/iconButton";
import barCode from "../../assets/icons/bar-code.svg";
import doc from "../../assets/icons/document-svgrepo-com-nyc.svg";
import { checkLoans } from "../../utils/check";
import { toDimension } from "chart.js/dist/helpers/helpers.core";
import copy from 'copy-to-clipboard';
import DataTable from "datatables.net-dt";
// import 'datatables.net-responsive-dt';

interface Customer {
	cpf: string;
	id: number;
	role: string;
	status: string;
	signed: boolean;
	name: string;
	createdAt: string;
	signatureDate: string | null;
}

interface CustomerProposal {
	customer: any;
	customerID: number;
	controlId: string;
	proposalId: string | null;
	situation: string | null;
	id: number;
	createdAt: string;
	updatedAt: string | null;
	deletedAt: string | null;
}

export default function RegisteredNewPerson() {
	const navigate = useNavigate();

	const { auth, setAuth } = useContext(AuthContext);
	useEffect(() => {

		if (auth === null || auth === 'undefined' || auth === undefined || auth === '') {
			navigate('/signin')
		}
	})

	const [data, setData] = useState<Customer[]>([]);
	const [link, setLink] = useState("");

	const [loading, setLoading] = useState(true);
	const tables = useRef(false);

	useEffect(() => {
		console.log(auth)
		axios.get('https://nycbank-loanservice.azurewebsites.net/api/Dash/GetAll', {
			headers: {
				Authorization: `Bearer ${auth}`
			}
		}).then((res) => {
			console.log("api/Dash/GetAll");
			console.log(res.data);
			console.log("api/Dash/GetAll");

			// setData(res.data);
			let a: Customer[] = [];
			res.data.forEach((_data: Customer) => {
				switch (_data.role) {
					case 'needcreatephone':
						_data.status = 'Aguardando inicio do cadastro';
						break;
					case 'needvalidatephone':
						_data.status = 'Aguardando finalização do cadastro';
						break;
					case 'needpersonalinfo':
						_data.status = 'Aguardando finalização do cadastro';
						break;
					case 'needuploaddocument':
						_data.status = 'Aguardando upload do documento';
						break;
					case 'waitingsignature':
						_data.status = 'Aguardando assinatura do contrato';
						break;
					case 'needemail':
						_data.status = 'Aguardando finalização do cadastro';
						break;
					case 'needaddress':
						_data.status = 'Aguardando finalização do cadastro';
						break;
					case 'needbankdata':
						_data.status = 'Aguardando finalização do cadastro';
						break;
					case 'waitingvalidation':
						_data.status = 'Aguardando emissão do empréstimo';
						break;
					case 'validated':
						_data.status = 'Contrato assinado com sucesso';
						break;
				}
				a.push(_data);
			});
			setData(a);
			setLoading(false);
			setTimeout(() => {
				if (!tables.current) {
					console.log('entrei')
					let table = new DataTable('#myTable', {
						search: false,
						initComplete: function () {
							//@ts-ignore
							this.api()
								.columns()
								.every(function () {
									//@ts-ignore
									let column = this;
									let title = column.footer().textContent;

									// Create input element
									let input = document.createElement('input');
									input.placeholder = title;
									column.footer().replaceChildren(input);

									// Event listener for user input
									input.addEventListener('keyup', () => {
										//@ts-ignore
										if (column.search() !== this.value) {
											column.search(input.value).draw();
										}
									});
								});
						}
					});
					tables.current = true;
				}
			}, 1000);
		});
		// setLoading(false);

	}, []);

	const finishLoan = (cpf: string) => {
		localStorage.setItem("finish-loan-cpf", cpf);
		navigate("/new-loans/finish-loan")
	}

	const getParcels = (cpf: string) => {
		localStorage.setItem("get-parcel-cpf", cpf);
		navigate("/new-loans/get-parcels")
	}

	const cpfReport = (cpf: string) => {
		localStorage.setItem("cpf-report", cpf);
		navigate("/new-loans/report-cpf")
	}

	const copyPaste = (cpf: string) => {
		axios.get('https://nycloancartosservice.azurewebsites.net/api/v2/Link/getLink?cpf=' + cpf, {
			headers: {
				Authorization: `Bearer ${auth}`
			}
		}).then((res) => {
			copy(res.data)
			toast.success('Link Copiado!', {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				rtl: false,
				pauseOnFocusLoss: true,
				draggable: true,
				pauseOnHover: true,
				theme: "light"
			});
		}).catch(() => {
			toast.error('Link ainda não disponível! Tente novamente em instantes ...', {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				rtl: false,
				pauseOnFocusLoss: true,
				draggable: true,
				pauseOnHover: true,
				theme: "light"
			});
		})
	}

	return (
		<>
			<link rel="stylesheet" href="//cdn.datatables.net/2.0.8/css/dataTables.dataTables.min.css" />
			{/* <script src="//cdn.datatables.net/2.0.8/js/dataTables.min.js"></script> */}
			<Header />
			{!loading ?
				<div style={{ width: '100%', overflowY: 'auto' }}>
					<input type="text" id="myInput" style={{ visibility: "hidden" }} />
					<nav className="navContainer">
						{/* <Link to="/loans/register-cpf">Cadastrar Cliente</Link> */}
						<Button onClick={() => navigate("/new-loans/register-loan")} textButton="Permitir Novo Empréstimo" />
						<Button onClick={() => navigate("/new-loans/get-parcels")} textButton="Consultar Boleto" />

					</nav>
					<div className="tableContainer">
						<table className="table" id="myTable" data-paging="false" data-search="false">
							<thead className="tableHeader">
								<tr>
									<th style={{ textAlign: "center" }}>CPF</th>
									<th style={{ textAlign: "center" }}>Nome</th>
									<th style={{ textAlign: "center" }}>Status de Proposta</th>
									<th style={{ textAlign: "center" }}>Data da Assinatura</th>
									<th style={{ textAlign: "center" }}>Ações</th>
								</tr>
							</thead>
							<tfoot>
								<tr>
									<th style={{ textAlign: "center" }}>CPF</th>
									<th style={{ textAlign: "center" }}>Nome</th>
									<th style={{ textAlign: "center" }}>Status de Proposta</th>
									<th style={{ textAlign: "center" }}>Data da Assinatura</th>
									<th style={{ textAlign: "center" }}>Ações</th>
								</tr>
							</tfoot>
							<tbody className="tableBody">
								{data.length == 0 ? <>
									<tr id='linhas' className='lightGreen'>
										<td colSpan={3} style={{ textAlign: "center" }}>Não possui cadastros</td>
									</tr>
								</> : <></>}
								{data.map((item, index) => (
									<tr key={index}>
										<td onClick={() => cpfReport(item.cpf)} className="link" style={{ color: 'var(--nyc-green-400)', textDecoration: 'underline' }}>{item.cpf}</td>
										<td>{item.name ? item.name : "S/N"}</td>
										<td>{item.status}</td>
										<td style={{ textAlign: "center" }}>{item.signatureDate ? new Date(item.signatureDate).toLocaleDateString() : "N/A"}</td>
										{item.role == 'waitingvalidation' ? <td className="link" style={{ textAlign: "center", color: 'var(--nyc-green-400)', textDecoration: 'underline' }} onClick={() => finishLoan(item.cpf)}>Gerar Contrato CCB</td> : (item.role == 'validated' && item.signed ? <td className="link" style={{ textAlign: "center", color: 'var(--nyc-green-400)', textDecoration: 'underline' }} onClick={() => getParcels(item.cpf)}>Consultar Boletos</td> : (item.role == 'validated' ? <td className="link" style={{ textAlign: "center", color: 'var(--nyc-green-400)', textDecoration: 'underline' }} onClick={() => copyPaste(item.cpf)}>Copiar Link</td> : <td style={{ textAlign: "center" }}>N/A</td>))}
									</tr>

								))}
							</tbody>
						</table>
					</div>
					<ToastContainer transition={Slide} />
				</div>
				:
				<div className="loading-page-loan">
					<p>Carregando...</p>
					<Loading />
				</div>
			}
		</>
	);
}
