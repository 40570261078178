import './style.css'
import React from 'react';

type primaryButtonProps =
	{
		onClick?: (a: any) => void;
		textButton: string | number;
		className?: string;
		hidden?: boolean
		disabled?: boolean
		type?: 'button' | 'reset'
	}

export default function Button(props: primaryButtonProps) {
	const { onClick, textButton, className, hidden, disabled, type } = props;
	return (
		<button onClick={onClick} type={type} disabled={disabled} hidden={hidden} className={`${className} primaryButton`}>
			{textButton}
		</button>
	)
}