// CPFInput.tsx
import React from 'react';
import InputMask from 'react-input-mask';
import './style.css'

interface CPFInputProps {
	value?: any;
	onChange: (a: any) => void;
	placeholder?: string
	type?: string
	className?: string
	mask: string
	maskChar: any
	onBlur?: (a: any) => void;
}

const MaskInput: React.FC<CPFInputProps> = ({ value, onChange, placeholder, mask, maskChar, onBlur }) => {
	return (
		<InputMask
			className='input'
			mask={mask}
			maskChar={maskChar}
			value={value}
			placeholder={placeholder}
			onChange={onChange}
			onBlur={onBlur}
		/>
	);
};

export default MaskInput;
