import axios from "axios";
import React, { useContext, useEffect, useState } from "react"
import { AuthContext } from "../../../contexts/auth-context/auth";
import './style.css'
import Header from "../../../components/header";
import Button from "../../../components/buttons/primaryButton";
import { Navigate, useNavigate } from "react-router";

interface Prospects {
	id: number,
	contactTime: string,
	telephoneFix: string,
	haveProperty: Boolean,
	haveVehicle: Boolean,
	isRetiree: Boolean,
	benefit: number,
	status: string,
	plate: string,
	model: string,
	brand: string,
	year: string,
	state: null,
	city: null,
	neighbourhood: null,
	address: null,
	complement: null,
	number: null,
	bank: string,
	properties: Properties,
	vehicles: Vehicles
	customerFinancialInfo: Financial
	branch: string,
	account: string,
	tpAccount: string,
	pixKey: string,
	grossIncome: string
}

interface Financial {
	account: string,
	bank: string,
	bankName: string,
	branch: string,
	grossIncome: number,
	pixKey: string,
	tpAccount: string
}

interface Properties {
	address: string,
	neighbourhood: string,
	city: string,
	state: string,
	complement: string,
	number: string
}

interface Vehicles {
	brand: string
	model: string
	plate: string
	year: string
}

interface Serasa {
	propensaoAPagar: number
	score: number
}

const PersonProspect = () => {
	const id = typeof window !== 'undefined' ? sessionStorage.getItem('idPerson') : null;
	const name = typeof window !== 'undefined' ? sessionStorage.getItem('Name') : null;
	const navigate = useNavigate();

	const [dataUnique, setDataUnique] = useState<Prospects>()
	const [vehicles, setVehicles] = useState<Vehicles[]>([])
	const [properties, setProperties] = useState<Properties[]>([])
	const [financial, setFinancial] = useState<Financial>()
	const [serasa, setSerasa] = useState<Serasa>()
	const [residenceTicket, setResidenceTicket] = useState('')
	const [extract, setExtract] = useState('')

	const { auth, setAuth } = useContext(AuthContext)


	useEffect(() => {
		axios.get(`https://nycprospectservice.azurewebsites.net/api/v2/ProspectService/PostUsuarios/getbyid/${id}`, {
			headers: {
				Authorization: `Bearer ${auth}`
			}
		}).then((res) => {
			// console.log(res.data)
			setDataUnique(res.data)
			setVehicles(res.data.vehicles)
			setProperties(res.data.properties)
			setFinancial(res.data.customerFinancialInfo)

		}).catch((e: any) => {
			console.log(e.status)
		})

		axios.get(`https://nycserasaservice.azurewebsites.net/api/v1/consult-serasa/getreport/${id}`, {
			headers: {
				Authorization: `Bearer ${auth}`
			}
		}).then((res) => {
			console.log(res.data)
			setSerasa(res.data.consultaResult)
		}).catch((e) => {
			console.log(e)
		})

		axios.get((`https://nycprospectservice.azurewebsites.net/api/v2/Azure/GetPicFromAzure/residence/${id}`), {
			headers: {
				Authorization: `Bearer ${auth}`
			}
		}).then((res: any) => {
			// console.log('Residencia', res.data)
			setResidenceTicket(`data:image/png;base64,${res.data}`)
		})

		axios.get((`https://nycprospectservice.azurewebsites.net/api/v2/Azure/GetPicFromAzure/extract/${id}`), {
			headers: {
				Authorization: `Bearer ${auth}`
			}
		}).then((res) => {
			// console.log('Extrato', res.data)
			setExtract(res.data)
		})

	}, [])

	return (
		<>
			<Header />
			<div className="aprrovedContainer">
				<div className="container-border">

					<h1>
						{name} - {dataUnique?.status}
					</h1>
					<p className="header">

						<p><h3>Melhor horário para ligar:</h3>
							{dataUnique?.contactTime}
						</p>
						<p><h3>Telefone fixo:</h3>
							{dataUnique && dataUnique?.telephoneFix ? dataUnique?.telephoneFix : 'Não possui'}
						</p>
					</p>
					<div className="approved-grid">
						<hr />
						<div className="divisor">
							<h3>Imóveis</h3>
							{dataUnique?.haveProperty ?

								<div className="gird">

									{properties.map((item, index) => (
										<div key={index}>
											<b>&nbsp;Endereço {index + 1}: </b><br />
											<small>&nbsp;&bull;&nbsp;<b>Rua:</b> {item.address}</small><br />
											<small>&nbsp;&bull;&nbsp;<b>Bairro:</b> {item.neighbourhood}</small><br />
											<small>&nbsp;&bull;&nbsp;<b>Número:</b> {item.number}</small><br />
											<small>&nbsp;&bull;&nbsp;<b>Cidade:</b> {item.city}</small><br />
											<small>&nbsp;&bull;&nbsp;<b>Estado:</b>  {item.state}</small>
										</div>
									))}
								</div>

								: 'Não possui imóveis'
							}
						</div>
						<hr />
						<div className="divisor">
							<p><h3>Veículos</h3>
								{dataUnique?.haveVehicle ?

									<div className="gird">
										{vehicles.map((item, index) => (
											<div key={index}>
												<b>&nbsp;Veículos: {index + 1}: </b><br />
												<small>&nbsp;&bull;&nbsp;<b>Marca:</b> {item.brand}</small><br />
												<small>&nbsp;&bull;&nbsp;<b>Modelo:</b> {item.model}</small><br />
												<small>&nbsp;&bull;&nbsp;<b>Ano:</b> {item.year}</small><br />
												<small>&nbsp;&bull;&nbsp;<b>Placa:</b>  {item.plate}</small>
											</div>
										))
										}
									</div>
									: 'Não possui veículos'}
								<br />

							</p>
						</div>
						<hr />
						<p>
							<h3>Informações Finaceiras</h3>
							{dataUnique?.isRetiree ? <small>&nbsp;Aposentado <br /> <b>&nbsp;&bull;&nbsp;Benefício:</b> {dataUnique?.benefit.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</small> : ''}
							<br />

							<small>&nbsp;&bull;&nbsp;<b>Banco:</b> {financial?.bankName}</small><br />
							<small>&nbsp;&bull;&nbsp;<b>Tipo de conta:</b> {financial && financial?.tpAccount === 'CC' ? 'Corrente' : 'Poupança'}</small><br />
							<small>&nbsp;&bull;&nbsp;<b>Agência:</b> {financial?.branch}</small><br />
							<small>&nbsp;&bull;&nbsp;<b>Número da Conta:</b> {financial?.account}</small><br />
							<small>&nbsp;&bull;&nbsp;<b>Renda bruta:</b> {financial?.grossIncome.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</small><br />
							<small>&nbsp;&bull;&nbsp;<b>Chave Pix:</b> {financial?.pixKey}</small><br />
							<br />
							<h3>Serasa</h3>
							<small>&nbsp;&bull;&nbsp;<b>Score:</b> {serasa?.score}</small><br />
							<small>&nbsp;&bull;&nbsp;<b>Propensão a pagar:</b> {serasa?.propensaoAPagar.toFixed(2)}%</small>
						</p>
						<hr />
						<p>
							<h3>Comprovantes</h3>
							<br />
							<div className="container-tickets">

								<div className="moscaDemais">
									<b>Comprovante de Residência</b>
									<div className="cardImg">

										{residenceTicket ?
											<img src={residenceTicket} /> :
											<p className="empty">Não há comprovantes enviados para exibição</p>
										}

									</div>
								</div>
								<div className="moscaDemais">
									<b>Extrato</b>
									<div className="cardImg">
										{extract ?
											<img src={`data:image/png;base64,${extract}`} /> :
											<p className="empty">Não há comprovantes enviados para exibição</p>
										}
									</div>
								</div>
							</div>
						</p>
					</div>
				</div>
				<Button onClick={() => { navigate('/loans/register-loan') }} textButton='Gerar Proposta' />
			</div >
		</>
	)
}
export default PersonProspect