import axios from "axios"

export const checkLoans = async (cpf: string) => {
	try {
		const req = await axios.post('https://nyc-homol.azurewebsites.net/api/Loan/getLoanByPerson', {
			cpf: cpf
		})
		return { data: req.data, status: req.status }
	} 	catch (e: any) {
		if (axios.isAxiosError(e)) {
		  // If it's an Axios error, e.response will have the details
		  return { status: e.response?.status || 500, error: e.response?.data || "Unknown error" };
		} else {
		  // If it's not an Axios error, handle it accordingly
		  return { status: 500, error: "Unknown error" };
		}
	  }

}