import Header from "../../../components/header";
import axios from "axios";
import './style.css'
import Input from "../../../components/inputs/defaultInput";
import Logonyc from '../../../assets/logoNYC/logo_nycbank_black.svg'
import { useContext, useEffect, useState } from "react";
import MaskInput from "../../../components/inputs/maskedInput";
import Select from "../../../components/inputs/selectInput";
import Button from "../../../components/buttons/primaryButton";
import CryptoNyc from '../../../utils/decrypto-nyc'

import { ToastContainer, toast, Bounce, Slide, Flip, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../contexts/auth-context/auth";
import Loading from "../../../components/loading";
import { formatValue } from 'react-currency-input-field';

export default function FinishLoan() {
	const [date, setDate] = useState('');
	const [cpf, setCPF] = useState('')
	const [valor, setValor] = useState('')
	const [parcelas, setParcelas] = useState('')
	const [tac, setTac] = useState('')
	const [interest, setInterest] = useState('')
	const [loading, setLoading] = useState(true)
	const [simulated, setSimulated] = useState(false)
	const [simulatedData, setSimulatedData] = useState(null)

	const cpfPerson = typeof window !== 'undefined' ? sessionStorage.getItem('cpfLoan') : null;

	const currentDate = new Date();
	const formatDate = new Date(date);
	const creditDate = currentDate.toLocaleDateString()

	useEffect(() => {
		if (cpfPerson) {
			setCPF(cpfPerson)
		}
	})

	const navigate = useNavigate()
	const { auth, setAuth } = useContext(AuthContext)


	const generateLoan = () => {
		setLoading(false)
		const newCurrentDate = currentDate.toISOString()
		const newDate = formatDate.toISOString()
		// console.log(creditDate)
		const ano = currentDate.getFullYear();
		const mes = (currentDate.getMonth() + 1).toString().padStart(2, '0');
		const dia = currentDate.getDate().toString().padStart(2, '0');
		const dataFormatada = `${ano}-${mes}-${dia}`;
		const cpfFormat = cpf.replaceAll('-', '').replaceAll('.', '')

		if (newCurrentDate < newDate) {
			const position = date.split('-')
			axios.post('https://nycloancartosservice.azurewebsites.net/api/v2/Proposal/generate-proposal', {
				"creditDate": dataFormatada,
				"firstDueDate": `${position[0]}-${position[1]}-${position[2]}`,
				"cpf": localStorage.getItem("finish-loan-cpf"),
				"amount": valor,
				"numberOfInstalments": parcelas,
				"tac": tac,
				"interestRate": interest
			}, {
				headers: {
					Authorization: `Bearer ${auth}`
				}
			}).then((res) => {
				// console.log(res)
				axios.post('https://nycloancartosservice.azurewebsites.net/api/v2/Proposal/register-proposal',
					{ cpf: localStorage.getItem("finish-loan-cpf") }
					, {
						headers: {
							Authorization: `Bearer ${auth}`,
							'Content-Type': 'application/json'
						}
					}).then((res) => {
						setLoading(true)
						toast.success('Proposta cadastrada com sucesso', {
							position: toast.POSITION.TOP_RIGHT,
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							rtl: false,
							pauseOnFocusLoss: true,
							draggable: true,
							pauseOnHover: true,
							theme: "light",
						});
						setTimeout(() => {
							navigate("/new-loans")
						}, 5000);
					}).catch((e) => {
						setLoading(true)
						toast.error('Algo deu errado, tente novamente', {
							position: toast.POSITION.TOP_RIGHT,
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							rtl: false,
							pauseOnFocusLoss: true,
							draggable: true,
							pauseOnHover: true,
							theme: "light",
						});
					})

			}).catch((e) => {
				setLoading(true)
				setTimeout(() => {
					toast.error('Algo deu errado, tente novamente', {
						position: toast.POSITION.TOP_RIGHT,
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						rtl: false,
						pauseOnFocusLoss: true,
						draggable: true,
						pauseOnHover: true,
						theme: "light",
					});
				}, 1000);

			})
		}

	}

	const simulateLoan = () => {
		setLoading(false);
		setSimulated(false);
		const newCurrentDate = currentDate.toISOString()
		const newDate = formatDate.toISOString()
		// console.log(creditDate)
		const ano = currentDate.getFullYear();
		const mes = (currentDate.getMonth() + 1).toString().padStart(2, '0');
		const dia = currentDate.getDate().toString().padStart(2, '0');
		const dataFormatada = `${ano}-${mes}-${dia}`;
		if (newCurrentDate < newDate) {
			const position = date.split('-')
			axios.post('https://nycloancartosservice.azurewebsites.net/api/v2/Proposal/simulate-proposal', {
				"creditDate": dataFormatada,
				"firstDueDate": `${position[0]}-${position[1]}-${position[2]}`,
				"cpf": localStorage.getItem("finish-loan-cpf"),
				"amount": valor,
				"numberOfInstalments": parcelas,
				"tac": tac,
				"interestRate": interest
			}, {
				headers: {
					Authorization: `Bearer ${auth}`
				}
			}).then((res) => {
				setLoading(true);
				setSimulated(true);
				let processed = res.data.proposal.processed;
				processed.amount = formatValue({value: processed.amount.toString(), groupSeparator: '.',decimalSeparator: ',',prefix: 'R$ ',decimalScale: 2});
				processed.sequenceOfInstallments[0].pmt = formatValue({value: processed.sequenceOfInstallments[0].pmt.toString(), groupSeparator: '.',decimalSeparator: ',',prefix: 'R$ ',decimalScale: 2});
				processed.taxIOFTotal = formatValue({value: processed.taxIOFTotal.toString(), groupSeparator: '.',decimalSeparator: ',',prefix: 'R$ ',decimalScale: 2});
				processed.feeTotal = formatValue({value: processed.feeTotal.toString(), groupSeparator: '.',decimalSeparator: ',',prefix: 'R$ ',decimalScale: 2});
				processed.amountTotal = formatValue({value: processed.amountTotal.toString(), groupSeparator: '.',decimalSeparator: ',',prefix: 'R$ ',decimalScale: 2});
				processed.installmentTotal = formatValue({value: processed.installmentTotal.toString(), groupSeparator: '.',decimalSeparator: ',',prefix: 'R$ ',decimalScale: 2});
				setSimulatedData(processed)
			});
		}
	}

	return (
		<>
			<Header />

			{loading ?
				<div className="container">
					<img src={Logonyc.toString()} className="logo" alt="logo da NYC Bank" />

					<div className="row">
						<div className="col-50">
							<form>
								<div className="form-grid">
									<h2>Escolha a data da sua primeira parcela</h2>
									<Input type="date" onChange={(e) => setDate(e.target.value)} value={date} placeholder="" />
									<h2>Valor do Emprestimo</h2>
									<Input type="number" onChange={(e) => setValor(e.target.value)} value={valor} placeholder="Valor"/>
									<h2>Numero de parcelas</h2>
									<Input type="number" onChange={(e) => setParcelas(e.target.value)} value={parcelas} placeholder="Parcelas"/>
									<h2>Valor da TAC</h2>
									<Input type="number" onChange={(e) => setTac(e.target.value)} value={tac} placeholder="TAC"/>
									<h2>Juros %</h2>
									<Input type="number" onChange={(e) => setInterest(e.target.value)} value={interest} placeholder="Juros"/>
								</div>
								<div className="button-box">
									<Button type="button" onClick={simulateLoan} textButton={'Simular'} />
								</div>
							</form>
							<ToastContainer transition={Slide} /></div>
						<div className="col-50">
							{!simulated ? <>
							<div className="simulation-area">
								<h2>Faça uma simulação ao lado</h2>
							</div>
							</>: <>
							<div className="simulation-area">
								<div>
									<h2>Valor do Empréstimo</h2>
									{/* @ts-ignore */}
									<h3>{simulatedData?.amount}
								</h3>
									<div>&nbsp;</div>
									<div>&nbsp;</div>
									<h2>Parcelas</h2>
									{/* @ts-ignore */}
									<h3>{simulatedData?.sequenceOfInstallments.length} x {simulatedData?.sequenceOfInstallments[0]?.pmt}</h3>
									<div>&nbsp;</div>
									<div>&nbsp;</div>
									<h2>Valor IOF</h2>
									{/* @ts-ignore */}
									<h3>{simulatedData?.taxIOFTotal}</h3>
									<div>&nbsp;</div>
									<div>&nbsp;</div>
									<h2>Valor TAC</h2>
									{/* @ts-ignore */}
									<h3>{simulatedData?.feeTotal}</h3>
									<div>&nbsp;</div>
									<div>&nbsp;</div>
									<h2>Valor Total do Empréstimo</h2>
									{/* @ts-ignore */}
									<h3>{simulatedData?.amountTotal}</h3>
									<div>&nbsp;</div>
									<div>&nbsp;</div>
									<h2>Valor Total do Contrato</h2>
									{/* @ts-ignore */}
									<h3>{simulatedData?.installmentTotal}</h3>
								</div>
								<div>
								<div className="button-box">
									<Button type="button" onClick={generateLoan} textButton={'Contratar'} />
								</div>
								</div>
							</div>

							</>}
							{/*  */}
							
						</div>
					</div>
				</div >
				:
				<div className="loading-page-loan">
					<p>Carregando...</p>
					<Loading />
				</div>
			}
		</>
	)
}