import React from 'react';
import './style.css'

type iconButtonProps =
	{
		onClick: (a: any) => void;
		className?: string;
		hidden?: boolean
		disabled?: boolean
		icon: string
		alt: string
	}

export default function Button(props: iconButtonProps) {
	const { onClick, className, hidden, disabled, icon, alt } = props;
	return (
		<button onClick={onClick} disabled={disabled} hidden={hidden} className={`${className} iconButton`}>
			<img src={icon} alt={alt} width={20} height={20} />
		</button>
	)
}