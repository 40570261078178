import Header from "../../components/header";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import MaskInput from "../../components/inputs/maskedInput";
import Button from "../../components/buttons/primaryButton";
import Logonyc from "../../assets/logoNYC/logo_nycbank_black.svg";
import { useNavigate, Link } from "react-router-dom";
import './style.css'
import { content } from "html2canvas/dist/types/css/property-descriptors/content";
import saveAs from "file-saver";
import { AuthContext } from "../../contexts/auth-context/auth";
import React from "react";


export default function Agreements() {
	const [cpf, setCPF] = useState("")

	const navigate = useNavigate()
	const { auth, setAuth } = useContext(AuthContext)

	useEffect(() => {
		if (auth === null || auth === 'undefined' || auth === undefined || auth === '') {
			navigate('/signin')
		}
	}, [])

	const qs = require('qs');
	let data = qs.stringify({
		'grant_type': 'client_credentials'
	});

	useEffect(() => {
		axios.post('https://auth.flowfinance.com.br/oauth2/token',
			data
			,
			{
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					'Authorization': 'Basic MmhobjlsaXNtcjFkZDIyNHY3N2ExNDI5dm86OW5oYWxodG02cnU0NW1xaDZhN2Q0OWFwM2JzczRxbjBhdDF1aGR0aXU1cjZzOHZhYnM3',
					'Cookie': 'XSRF-TOKEN=a9bfb169-6eb4-4a0e-84e9-21e34440057b'
				}
			})
			.then((res: any) => {
				// console.log(res.data.access_token)
				sessionStorage.setItem('newToken', res.data.access_token)
			})
			.catch((error: any) => {
				console.log(error);
			});
	})

	const cpfFunc = () => {
		const token = typeof window !== 'undefined' ? sessionStorage.getItem('newToken') : null;
		const format = cpf.replaceAll(".", "").replace("-", "")
		axios.get(`https://platform.flowfinance.com.br/banking/originator/persons?taxpayer_id=${format}`, {
			headers: {
				'authorization': token
			}
		}).then((res: any) => {
			// console.log(res.data)
			sessionStorage.setItem('id', res.data.content[0].id)
			sessionStorage.setItem('fullName', res.data.content[0].full_name)
			navigate('/agreements/list')
		})
	}


	return (
		<>
			<Header />

			<div className="SearchContainer">
				<img src={Logonyc.toString()} alt="logo da NYC Bank" />
				<div className="Search">
					<MaskInput mask="999.999.999-99" maskChar={null} placeholder="CPF" type="text" value={cpf} onChange={(e) => setCPF(e.target.value)} />
					<Button className="Button" onClick={cpfFunc} textButton={"Enviar"} />
				</div>
			</div>
		</>
	)
}