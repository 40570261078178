import { useContext, useEffect, useState } from "react";
import Logonyc from "../../assets/logoNYC/logo_nycbank_black.svg";
import "./style.css";
import Input from "../../components/inputs/defaultInput";
import Button from "../../components/buttons/primaryButton";
import axios from "axios";
import MaskInput from "../../components/inputs/maskedInput";
import { useNavigate, Link } from "react-router-dom";
import { checkLoans } from "../../utils/check";
import Header from "../../components/header";
import { ToastContainer, toast, Bounce, Slide, Flip, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from "../../contexts/auth-context/auth";
import React from "react";
import Select from "../../components/inputs/selectInput";

interface ListPersonProps {
	id: number
	person: PersonProps
}

interface PersonProps {
	cpf: string,
}

export const UpdateStatus = () => {
	const [person, setPerson] = useState<ListPersonProps[]>([])
	const [cpf, setCPF] = useState("")
	const [role, setRole] = useState("")
	const format = cpf.replaceAll(".", "").replace("-", "");
	const navigate = useNavigate()
	const { auth, setAuth } = useContext(AuthContext)

	useEffect(() => {

		if (auth === null || auth === 'undefined' || auth === undefined || auth === '') {
			navigate('/signin')
		}
	}, [])

	const updateRole = () => {
		const format = cpf.replaceAll('.', '').replaceAll('-', '')
		axios.get('https://nycclientinfoservice.azurewebsites.net/api/v2/getallUsers', {
			headers: {
				Authorization: `Bearer ${auth}`
			}
		}).then((res) => {


			const personTarget = res.data.find((item: any) => item.person.cpf == format)
			const newId = personTarget?.id

			axios.put('https://createaccountservice.azurewebsites.net/api/v2/CreateAccount/updaterole',
				{
					"userId": newId,
					"newRole": role
				}, {
				headers: {
					Authorization: `Bearer ${auth}`
				}
			}).then((res) => {
				toast.success('Sucesso', {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					rtl: false,
					pauseOnFocusLoss: true,
					draggable: true,
					pauseOnHover: true,
					theme: "light"
				});
			}).catch((e) => {
				switch (e.response.status) {
					case 403:
						toast.error('Proibido', {
							position: toast.POSITION.TOP_RIGHT,
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							rtl: false,
							pauseOnFocusLoss: true,
							draggable: true,
							pauseOnHover: true,
							theme: "light"
						});
						break;
					case 400:
						toast.error('CPF não encontrado, verifique se o número está correto', {
							position: toast.POSITION.TOP_RIGHT,
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							rtl: false,
							pauseOnFocusLoss: true,
							draggable: true,
							pauseOnHover: true,
							theme: "light"
						});
						break;
					default:
						break;
				}
			})


		}
		)

	}



	return (
		<>
			<Header />
			<div className="SearchContainer">
				<img src={Logonyc.toString()} alt="logo da NYC Bank" />
				<div className="Search">
					<MaskInput mask="999.999.999-99" maskChar={null} placeholder="CPF" type="text" value={cpf} onChange={(e) => setCPF(e.target.value)} />
					<Select onChange={(e) => {
						setRole(e.target.value);
					}
					}>
						<option value="accountconfirmed">Account Confirmed</option>
						<option value="awaitaccount">Awaiting Account</option>
						<option value="needcreatekey">Need to Create Key</option>
						<option value="needcreateemail">Need to Create Email</option>
						<option value="needconfirmtoken">Need to Confirm Token</option>
						<option value="usercreated">User Created</option>
						<option value="accountverify">Account Verify</option>
						<option value="returnpix">Return Pix</option>
						<option value="accountcreating">Account Creating</option>
						<option value="passwordconfirmed">Password Confirmed</option>
						<option value="cep">CEP</option>
						<option value="sendemail">Send Email</option>
						<option value="needuploaddocument">Need to Upload Document</option>
						<option value="accountverifyerror">Account Verify Error</option>
						<option value="recoverconfirmed">Recover Confirmed</option>
						<option value="nickname">Nickname</option>
						<option value="sendsms">Send SMS</option>
						<option value="password">Password</option>
						<option value="validsms">Valid SMS</option>
						<option value="confirmIdentity">Confirm Identity</option>
					</Select>

					<Button className="Button" onClick={() => updateRole()} textButton={"Alterar"} />
				</div>
				{/* <Link className="AccButton" to="/loans/register-cpf">Cadastrar novo CPF</Link> */}
			</div>
			<ToastContainer transition={Slide} />
		</>
	)
}
export default UpdateStatus