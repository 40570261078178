import { useContext, useEffect, useState } from "react";
import Logonyc from "../../../assets/logoNYC/logo_nycbank_black.svg";
import "./style.css";
import Input from "../../../components/inputs/defaultInput";
import Button from "../../../components/buttons/primaryButton";
import axios from "axios";
import MaskInput from "../../../components/inputs/maskedInput";
import { useNavigate, Link } from "react-router-dom";
import { checkLoans } from "../../../utils/check";
import Header from "../../../components/header";
import { ToastContainer, toast, Bounce, Slide, Flip, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from "../../../contexts/auth-context/auth";
import React from "react";

export const LoanSearch = () => {
	const [cpf, setCPF] = useState("")
	const navigate = useNavigate()
	const { auth, setAuth } = useContext(AuthContext)

	useEffect(() => {

		if (auth === null || auth === 'undefined' || auth === undefined || auth === '') {
			navigate('/signin')
		}
	}, [])

	const format = cpf.replaceAll(".", "").replace("-", "");




	const setCheckCpf = async () => {
		const req = await checkLoans(format)
		sessionStorage.setItem('cpf', format)
		switch (req?.status) {
			case 200:
				sessionStorage.setItem('cpf', format)
				navigate('/loans/qrcode-list')
				break;
			case 404:
				toast.error('CPF não possui empréstimos', {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					rtl: false,
					pauseOnFocusLoss: true,
					draggable: true,
					pauseOnHover: true,
					theme: "light"
				});
				break;
			default:
				toast.error('Aconteceu um erro inesperado, tente novamente mais tarde', {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					rtl: false,
					pauseOnFocusLoss: true,
					draggable: true,
					pauseOnHover: true,
					theme: "light"
				});
				break;

		}
	}


	return (
		<>
			<Header />
			<div className="SearchContainer">
				<img src={Logonyc.toString()} alt="logo da NYC Bank" />
				<div className="Search">
					<MaskInput mask="999.999.999-99" maskChar={null} placeholder="CPF" type="text" value={cpf} onChange={(e) => setCPF(e.target.value)} />
					<Button className="Button" onClick={() => setCheckCpf()} textButton={"Consultar"} />
				</div>
				{/* <Link className="AccButton" to="/loans/register-cpf">Cadastrar novo CPF</Link> */}
			</div>
			<ToastContainer transition={Slide} />
		</>
	)
}
export default LoanSearch