import React, { useContext, useEffect, useState } from "react"
import { AuthContext } from "../../../contexts/auth-context/auth"
import { useNavigate } from "react-router";
import axios from "axios"
import Header from "../../../components/header"
import Logonyc from '../../../assets/logoNYC/logo_nycbank_black.svg'
import './style.css'
import { formatDate } from "../../../utils/formatDate"
import { ToastContainer, toast, Bounce, Slide, Flip, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { banks } from "../../../mock/banksMock";
import Button from "../../../components/buttons/primaryButton";
import Input from "../../../components/inputs/defaultInput";
import InputRadio from "../../../components/inputs/InputRadio";

interface NotaryResponseProps {
	occurrenceDate: string
	amount: number
	officeNumber: string
	city: string
	federalUnity: string
}

interface PefinResponseProps {
	occurrenceDate: string
	amount: number
	creditorName: string
}
interface RefinResponseProps {
	occurrenceDate: string
	amount: number
	creditorName: string
}

interface Address {
	addressLine: string;
	district: string;
	zipCode: string;
	country: string;
	city: string;
	state: string;
}

interface Phone {
	regionCode: number;
	areaCode: number;
	phoneNumber: number;
}

interface ConsumerData {
	documentNumber: string;
	consumerName: string;
	motherName: string;
	birthDate: string;
	statusRegistration: string;
	statusDate: string;
	score: number;
	phoneNumber: string;
	email: string;
	bankCode: string;
	bankName: string;
	agency: string;
	accountNumber: string;
	accountType: string;
	bankIspb: string;
	cep: String;
	complement: String;
	addressNumber: String;
	address: String;

}
interface Score {
	score: number
}
type BankProps = {
	code: string;
	bankName: string;
	ispb: string;
};

const ReportCPF = () => {
	const navigate = useNavigate();

	const { auth, setAuth } = useContext(AuthContext)
	const [imgCerb, setImgCerb] = useState('')
	const [imgCNH, setImgCNH] = useState('')
	const [imgOne, setImgOne] = useState('')
	const [imgTwo, setImgTwo] = useState('')
	const [notarys, setNotarys] = useState<NotaryResponseProps[]>([])
	const [pefin, setPefin] = useState<PefinResponseProps[]>([])
	const [refin, setRefin] = useState<RefinResponseProps[]>([])
	const [registration, setRegistration] = useState<ConsumerData>()
	const [score, setScore] = useState<Score>()
	const [inputFocused, setInputFocused] = useState(false);
	const [bankISPB, setBankISPB] = useState("");
	const [bankCode, setBankCode] = useState("");
	const [bank, setBank] = useState("");
	const [agency, setAgency] = useState("");
	const [account, setAccount] = useState("");
	const [accountType, setAccountType] = useState("");
	const [digit, setDigit] = useState("");
	const [filteredListBank, setFilteredListBank] = useState<BankProps[] | []>(
		[]
	);

	const id = typeof window !== 'undefined' ? localStorage.getItem('cpf-report') : null;

	useEffect(() => {
		axios.get(`https://nycbank-loanservice.azurewebsites.net/api/Dash/GetFileSelfie/${id}`, {
			headers: {
				Authorization: `Bearer ${auth}`
			}
		}).then((res: any) => {
			// console.log(res)
			setImgCerb(res.data)
		})
		axios.get(`https://nycbank-loanservice.azurewebsites.net/api/Dash/GetFileDocuments/${id}`, {
			headers: {
				Authorization: `Bearer ${auth}`
			}
		}).then((res: any) => {
			// console.log(res)
			if (res.data.length == 1) {
				setImgCNH(res.data)
			} else {
				setImgOne(res.data[0])
				setImgTwo(res.data[1])
			}
		})
		axios.get(`https://nycserasaservice.azurewebsites.net/api/v1/consult-serasa/GenerateReportByCpf/${id}`, {
			headers: {
				Authorization: `Bearer ${auth}`
			}
		}).then((res) => {
			axios.get(`https://nycbank-loanservice.azurewebsites.net/api/Dash/GetInfo/${id}`, {
				headers: {
					Authorization: `Bearer ${auth}`
				}
			}).then((res2: any) => {
				axios.get(`https://nycbank-loanservice.azurewebsites.net/api/Dash/GetBanks/${id}`, {
					headers: {
						Authorization: `Bearer ${auth}`
					}
				}).then((res3: any) => {
					console.log("res")
					console.log(res)
					console.log("res2")
					console.log(res2)
					console.log("res3")
					console.log(res3)




					let reg: ConsumerData = {
						consumerName: res2.data.nome,
						birthDate: res2.data.birthDate,
						address: res2.data.address,
						addressNumber: res2.data.addressNumber,
						complement: res2.data.complement,
						cep: res2.data.cep,
						motherName: '',
						statusRegistration: '',
						score: 0,
						documentNumber: "" + id,
						statusDate: '',
						phoneNumber: res2.data.phone,
						email: res2.data.email,
						bankCode: res3.data.codeBank,
						bankName: res3.data.ispb,
						bankIspb: res3.data.ispb,
						agency: res3.data.agency,
						accountNumber: res3.data.accountNumber,
						accountType: res3.data.accountType,
					}

					banks.forEach((a) => {
						if (a.ispb == parseInt(reg.bankName).toString()) {
							reg.bankName = a.bankName;
						}
					})
					setBank(reg.bankName);
					setBankISPB(reg.bankIspb);
					setBankCode(reg.bankCode);
					setAgency(reg.agency);
					setAccount(reg.accountNumber);
					setAccountType(reg.accountType);

					setRegistration(reg)
				})
			})
		}).catch((e) => {
			navigate(`/signin`)
		})


	}, [])

	const updateBank = () => {
		axios.put('https://nycbank-loanservice.azurewebsites.net/api/Dash/UpdateBankData/' + id,
			{
				"codeBank": bankCode,
				"accountNumber": account,
				"ispb": bankISPB,
				"agency": agency,
				"accountType": accountType
			},
			{
				headers: {
					Authorization: `Bearer ${auth}`
				}
			}).then(() => {
				toast.success('Atualizado com sucesso!', {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					rtl: false,
					pauseOnFocusLoss: true,
					draggable: true,
					pauseOnHover: true,
					theme: "light"
				});
			})
	}

	const formatStringDate = (date: string) => {
		const split = date.split('-')


		return `${split[2]}/${split[1]}/${split[0]}`
	}

	const choiceBankName = (e: React.ChangeEvent<HTMLInputElement>) => {
		let filterBank = banks.filter((item) =>
			item.bankName
				.toLocaleLowerCase()
				.includes(e.target.value.toLocaleLowerCase())
		);
		setFilteredListBank(filterBank);

		setBank(e.target.value);
	};

	const chooseBank = (e: any) => {
		const bankTyped = banks.filter((item) =>
			item.bankName
				.toLocaleLowerCase()
				.includes(e.target.innerText.toLowerCase())
		);

		setBank(bankTyped[0].bankName);
		setBankISPB(bankTyped[0].ispb);
		setBankCode(bankTyped[0].code);
		setInputFocused(false);
	};


	return (
		<>
			<Header />
			{/* <div className="container"> */}
			<div className="container container-a">
				<b className="titleSerasa">Dados na NYC Bank e Serasa</b>
				<div style={{ display: "flex" }}>
					<div className="container-serasa">
						{registration ?
							<>
								<small className="infos"><b>CPF:</b>{registration?.documentNumber && registration?.documentNumber}</small><br />
								<small className="infos"><b>Nome:</b>{registration?.consumerName && registration?.consumerName}</small><br />
								<small className="infos"><b>Telefone:</b>{registration?.phoneNumber && registration?.phoneNumber}</small><br />
								<small className="infos"><b>Email:</b>{registration?.email && registration?.email}</small><br />
								<small className="infos"><b>Data de nascimento:</b>{registration?.birthDate && formatStringDate(registration?.birthDate)}</small><br />
								<small className="infos"><b>CEP:</b>{registration?.cep ? registration?.cep : 'Não incluso no cadastro'}</small><br />
								<small className="infos"><b>Endereço:</b>{registration?.address}, {registration?.addressNumber}</small><br />
								<small className="infos"><b>Complemento:</b>{registration?.complement}</small><br />

							</>
							:
							<small className="infos">Ainda não há dados disponíveis</small>
						}
					</div>
					<div className="container-serasa">
						{registration ?
							<>
								<small className="infos"><b>Banco:</b>
									<Input
										type="text"
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
											choiceBankName(e);
										}}
										onFocus={() => setInputFocused(true)}
										value={bank}
										placeholder="Qual o banco?" />
									{filteredListBank.length > 0 && inputFocused && (
										<ul className="select-bank-ul">
											{filteredListBank.map((bank, index) => (
												<li
													className="select-bank-li"
													key={index}
													onClick={(e: any) => chooseBank(e)}
												>
													{bank.bankName}
												</li>
											))}
										</ul>
									)}</small><br />
								<small className="infos"><b>Agencia:</b>
									<Input
										onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
											setAgency(e.target.value)
										}

										placeholder="ex.: 001"
										value={agency}
										type="number"
									/>
								</small><br />
								<small className="infos"><b>Conta:</b>
									<Input
										onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
											setAccount(e.target.value)
										}
										placeholder="0000"
										value={account}
										type="number"
									/>
								</small><br />
								<small className="infos"><b>Tipo Conta:</b>
									<div className="bank-account-type">
										<InputRadio
											title="Corrente"
											name="account-type"
											id="current"
											checked={accountType === "Corrente"}
											onChange={() => setAccountType("Corrente")}
										/>
										<InputRadio
											title="Poupança"
											name="account-type"
											id="savings"
											checked={accountType === "Poupança"}
											onChange={() => setAccountType("Poupança")}
										/>
									</div>
								</small>
								<Button type="button" onClick={updateBank} textButton={'Atualizar'} />

							</>
							:
							<small className="infos">Ainda não há dados disponíveis</small>
						}
					</div>
				</div>
				<div className="container-reports">
					<div className="container-tickets-report">

						{/* <div className="moscaDemais">
							<b>Selfie</b>
							<div className="cardImg">

								{imgCerb ?
									<img src={`data:image/png;base64,${imgCerb}`} />
									:
									<p className="empty">Não há comprovantes enviados para exibição</p>
								}

							</div>
						</div> */}
						{/* <div className="moscaDemais">
							<b>Documentos</b>
							<div className={imgCNH !== '' && imgOne == '' ? 'cardImg' : 'cardImgCom'}>

								{imgCNH != '' ?
									<img src={`data:image/png;base64,${imgCNH}`} />
									:
									imgOne !== '' && imgTwo !== '' && imgTwo !== undefined && imgTwo !== undefined ?
										<>
											<img src={`data:image/png;base64,${imgOne}`} />
											<img src={`data:image/png;base64,${imgTwo}`} />
										</>
										:
										<p className="empty">Não há comprovantes enviados para exibição</p>
								}

							</div>
						</div> */}
					</div>
				</div>
				<ToastContainer transition={Slide} />
			</div>
			{/* </div> */}

		</>
	)
}

export default ReportCPF