'use client'
import React, { useContext, useEffect, useState } from 'react';
import { Bar, Doughnut } from 'react-chartjs-2';
import Button from '../../../../components/buttons/primaryButton';
import './style.css'

import {
	Chart as ChartJs,
	BarElement,
	CategoryScale,
	LinearScale,
	Tooltip,
	Legend,
	Title,
	ArcElement,
	ChartOptions
} from 'chart.js';
import Input from '../../../../components/inputs/defaultInput';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../../contexts/auth-context/auth';
import Loading from '../../../../components/loading';


ChartJs.register(
	BarElement,
	CategoryScale,
	LinearScale,
	Tooltip,
	Legend,
	Title,
	ArcElement
)

interface StatsProps {
	aprovados: number,
	naoAprovados: number,
	porcentagemAprovados: number,
	porcentagemNaoAprovados: number,
	totalContracts: number
}

interface TotalProps {
	pagamentosComParcelaPaga: number,
	pagamentosEmDia: number,
	pagamentosSemPagamento: number,
	porcentagemPagamentosComParcelaPaga: number,
	porcentagemPagamentosEmDia: number,
	porcentagemPagamentosSemPagamento: number
}
interface ThirdProps {
	pagamentosComParcelaPaga: number,
	pagamentosEmDia: number,
	pagamentosSemPagamento: number,
	porcentagemPagamentosComParcelaPaga: number,
	porcentagemPagamentosEmDia: number,
	porcentagemPagamentosSemPagamento: number
}

const StatsThree = () => {
	const [stats, setStats] = useState<StatsProps>()
	const [total, setTotal] = useState<TotalProps>()
	const [third, setThird] = useState<ThirdProps>()

	const navigate = useNavigate()
	const { auth, setAuth } = useContext(AuthContext)
	const [loading, setLoading] = useState(true)

	useEffect(() => {

		if (auth === null || auth === 'undefined' || auth === undefined || auth === '') {
			navigate('/signin')
		}
	}, [])
	const right = 'right'

	useEffect(() => {
		setLoading(true)
		// Calculate the sum when apiDataFiltered changes

		axios.get('https://nycreportservice.azurewebsites.net/api/dash/Loan/ObterEstatisticas',
			{
				headers: {
					Authorization: `Bearer ${auth}`
				}
			}).then((res) => {
				// setDelayed(res.data.loan)
				// console.log(res)
				setStats(res.data)
				setLoading(false)
				// setLoading(false)
				// setAuth(res.data.token)
			})

		axios.get('https://nycreportservice.azurewebsites.net/api/dash/Loan/GetStatsPayment',
			{
				headers: {
					Authorization: `Bearer ${auth}`
				}
			}).then((res: any) => {
				// setDelayed(res.data.loan)
				setTotal(res.data)
				// setLoading(false)
				// setAuth(res.data.token)
			})


	}, []);
	//data

	// const percentagePendent = (totalPendentParcel / apiDataFiltered.length) * 100;
	// const percentagePayed = (totalPayedParcel / apiDataFiltered.length) * 100;
	// const percentageLate = (totalLateParcel / apiDataFiltered.length) * 100;

	const data = {
		labels: [
			`Em dia: ${total?.porcentagemPagamentosEmDia && total?.porcentagemPagamentosEmDia.toFixed(2)}%`,
			`1 Pagamento: ${total?.porcentagemPagamentosComParcelaPaga && total?.porcentagemPagamentosComParcelaPaga.toFixed(2)}%`,
			`Sem Pagamentos: ${total?.porcentagemPagamentosSemPagamento && total?.porcentagemPagamentosSemPagamento.toFixed(2)}%`,
		],
		datasets: [
			{
				label: '',
				data: [total?.pagamentosEmDia, total?.pagamentosComParcelaPaga, total?.pagamentosSemPagamento],
				backgroundColor: ['#07bb02', '#ffaa00', '#b20505'],
				cutout: '60%',
				rotation: -20,
				spacing: 3
			}
		]
	}

	const options: ChartOptions<'doughnut'> = {
		elements: {
			arc: {
				borderColor: ['#007402', '#c99300', '#790505'],
				borderWidth: 4,
				// offset: 24
			},
		},
		plugins: {
			title: {
				display: true,
				text: '',
			},
			legend: {
				display: true,
				position: "right",
			}
		}
	}

	const data2 = {
		labels: [
			`Sem restrição: ${stats?.porcentagemNaoAprovados && stats?.porcentagemNaoAprovados.toFixed(2)}%`,
			`Com restrição: ${stats?.porcentagemAprovados && stats?.porcentagemAprovados.toFixed(2)}%`,
		],
		datasets: [
			{
				label: '',
				data: [stats?.porcentagemNaoAprovados, stats?.porcentagemAprovados],
				backgroundColor: ['#ffaa00', '#07bb02'],
				cutout: '60%',
				rotation: -20,
				spacing: 2,
				offset: 8
			}
		]
	}

	const options2: ChartOptions<'doughnut'> = {
		elements: {
			arc: {
				borderColor: ['#c99300', '#007402'],
				borderWidth: 4,
				// offset: 24
			},
		},
		plugins: {
			title: {
				display: true,
				text: '',
			},
			legend: {
				display: true,
				position: "right",
			}
		}
	}

	//return
	return (
		<>
			{!loading ?
				<div style={{ display: 'flex', gap: 16, justifySelf: 'flex-end' }}>

					<Doughnut data={data} options={options} ></Doughnut>
					<Doughnut data={data2} options={options2} ></Doughnut>

				</div >
				:
				<div className="loading-page-nyc-ac">
					<p>Carregando...</p>
					<Loading />
				</div>
			}
		</>
	)
}

export default StatsThree



