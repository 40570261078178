import axios from "axios";
import CryptoNyc from '../utils/crypto-nyc'

export const SignIn = async (cpf: string, userPassword: string) => {

	const _cryptoNyc = new CryptoNyc();

	const format = cpf.replaceAll(".", "").replace("-", "");

	try {
		const authResponse = await axios.post('https://nycdashloginservice.azurewebsites.net/api/dash/Dashlogin/authenticate',
			{
				cypher: _cryptoNyc.hash({
					cpf: format
				})
			}
		)
		const token = authResponse.data.token;

		if (authResponse.status === 200) {

			const signInReponse = await axios.post('https://nycdashloginservice.azurewebsites.net/api/dash/Dashlogin/login',
				{
					cypher: _cryptoNyc.hash({
						password: userPassword
					})
				},
				{
					headers: {
						Authorization: `Bearer ${token}`
					}
				}
			)

			return { flag: authResponse.data, data: signInReponse.data, status: signInReponse.status, authToken: token }
		}
	}
	catch (e: any) {
		if (axios.isAxiosError(e)) {
			// If it's an Axios error, e.response will have the details
			return { status: e.response?.status || 500, error: e.response?.data || "Unknown error" };
		} else {
			// If it's not an Axios error, handle it accordingly
			return { status: 500, error: "Unknown error" };
		}
	}



}